// routes.js (or wherever you define them)
// Notice we've added `/:lang(en|he)?` to every route's `path`.

import Forgot from "../pages/auth/forgot";
import Login from "../pages/auth/login";
import ResetPassword from "../pages/auth/reset";
import Signup from "../pages/auth/signup";
import Verify from "../pages/auth/verify";
import Contact from "../pages/contact";
import Home from "../pages/home";
import SingleItem from "../pages/item";
import Items from "../pages/items";
import Seller from "../pages/seller";
import Sellers from "../pages/sellers";
import AboutUs from "../pages/AboutUs";
import AddItemsToCollectionPage from "../pages/addItemsToCollectionPage";
import AdminAddItemsToCollectionPage from "../pages/admin/AdminAddItemsToColletionPage";
import AdminAllMessagesPage from "../pages/admin/AdminAllMessagesPage";
import AdminAuctionsPage from "../pages/admin/AdminAuctionsPage";
import AdminCategoriesPage from "../pages/admin/AdminCategoriesPage";
import AdminCollectionsPage from "../pages/admin/AdminCollectionsPage";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminDeletedItemsPage from "../pages/admin/AdminDeletedItemsPage";
import AdminItemsPage from "../pages/admin/AdminItemsPage";
import AdminMessagesPage from "../pages/admin/AdminMessagePage";
import AdminSellers from "../pages/admin/AdminSellers";
import AdminUserHistory from "../pages/admin/AdminUserHistory";
import BlogPostList from "../pages/admin/BlogPostList";
import AuctionGuidelines from "../pages/AuctionGuidelines";
import AuctionItem from "../pages/AuctionItem";
import AuctionsPage from "../pages/AuctionsPage";
import BlogPosts from "../pages/BlogPosts";
import AccountVisiblity from "../pages/client/accountVisibility";
import EditAuction from "../pages/client/Auction/EditAuction";
import EditProfile from "../pages/client/edit-profile";
import FeaturedItems from "../pages/client/featured";
import HiddenCollections from "../pages/client/hiddenCollections";
import HiddenItems from "../pages/client/hiddenItems";
import Hiddens from "../pages/client/hiddens";
import EditItem from "../pages/client/Item/EditItem";
import NewItem from "../pages/client/Item/new";
import MessagesPage from "../pages/client/message_board";
import PasswordChange from "../pages/client/password";
import PrivacyPolicyPage from "../pages/client/PrivacyPolicyPage";
import CollectionPage from "../pages/collectionPage";
import CollectionsPage from "../pages/CollectionsPage";
import CreateCollectionPage from "../pages/CreateCollectionPage";
import Disclaimer from "../pages/Disclaimer";
import Favorites from "../pages/Favorites";
import SignupConfirmation from "../pages/SignupConfirmation";
import singlePost from "../pages/singlePost";
import UserAuctionPage from "../pages/UserAuctionPage";
import UserItemsPage from "../pages/UserItemsPage";
import UserDetailPage from "../pages/UserDetailsPage";
import BasketPage from "../pages/BasketPage";
import AllBasketsPage from "../pages/AllBasketsPage";
import AllOrdersPage from "../pages/AllOrdersPage";
import SellerBasketPage from "../pages/SellerBasketPage";
import UnsubscribeFromNewsletterPage from "../pages/auth/unsubscribeFromNewsletter";
import SingleItemRoute from "./SingleItemProps";

// -------------- PUBLIC ROUTES --------------
export const ROUTES = [
  // Home: match "/" or "/en" or "/he" exactly
  {
    path: "/:lang(en|he)?",

    component: Home,
  },
  {
    path: "/:lang(en|he)?/items",
    component: Items,
  },

  {
    path: "/:lang(en|he)?/auction/:id",
    component: AuctionItem,
  },
  {
    path: "/:lang(en|he)?/auctions",
    component: AuctionsPage,
  },
  {
    path: "/:lang(en|he)?/sellers",
    component: Sellers,
  },
  {
    path: "/:lang(en|he)?/seller/:id",
    component: Seller,
  },
  {
    path: "/:lang(en|he)?/signup-confirmation",
    component: SignupConfirmation,
  },
  {
    path: "/:lang(en|he)?/disclaimer",
    component: Disclaimer,
  },
  {
    path: "/:lang(en|he)?/contact-us",
    component: Contact,
  },
  {
    path: "/:lang(en|he)?/collection/:id",
    component: CollectionPage,
  },
  {
    path: "/:lang(en|he)?/about-us",
    component: AboutUs,
  },
  {
    path: "/:lang(en|he)?/auction-guidelines",
    component: AuctionGuidelines,
  },
  {
    path: "/:lang(en|he)?/seller/:id/auctions",
    component: UserAuctionPage,
  },
  {
    path: "/:lang(en|he)?/user-items/:id",
    component: UserItemsPage,
  },
  {
    path: "/:lang(en|he)?/collection/all/:id",
    component: CollectionsPage,
  },
  {
    path: "/:lang(en|he)?/favorites",
    component: Favorites,
  },
  {
    path: "/:lang(en|he)?/blog",
    component: BlogPosts,
  },
  {
    path: "/:lang(en|he)?/blog/:id",
    component: singlePost,
  },
  {
    path: "/:lang(en|he)?/privacy-policy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/:lang(en|he)?/item/:id",
    component: SingleItem,
  },
];

// -------------- PROTECTED ROUTES --------------
export const PROTECTED_ROUTES = [
  {
    path: "/:lang(en|he)?/account-visibility/edit",
    component: AccountVisiblity,
  },
  {
    path: "/:lang(en|he)?/basket/:sellerId",
    component: BasketPage,
  },
  {
    path: "/:lang(en|he)?/baskets",
    component: AllBasketsPage,
  },
  {
    path: "/:lang(en|he)?/unsubscribe",
    component: UnsubscribeFromNewsletterPage,
  },
  {
    path: "/:lang(en|he)?/password/edit",
    component: PasswordChange,
  },

  {
    path: "/:lang(en|he)?/profile/edit",
    component: EditProfile,
  },
  {
    path: "/:lang(en|he)?/orders/received",
    component: AllOrdersPage,
  },
  {
    path: "/:lang(en|he)?/orders/received/:id",
    component: SellerBasketPage,
  },
  {
    path: "/:lang(en|he)?/auction/buyer/:id",
    component: UserDetailPage,
  },
  {
    path: "/:lang(en|he)?/auction/seller/:id",
    component: UserDetailPage,
  },
  {
    path: "/:lang(en|he)?/featured/edit",
    component: FeaturedItems,
  },
  {
    path: "/:lang(en|he)?/hidden/all",
    component: Hiddens,
  },
  {
    path: "/:lang(en|he)?/hidden/collections",
    component: HiddenCollections,
  },
  {
    path: "/:lang(en|he)?/hidden/items",
    component: HiddenItems,
  },
  {
    path: "/:lang(en|he)?/collection/manage/:id",
    component: AddItemsToCollectionPage,
  },
  {
    path: "/:lang(en|he)?/item/single/new",
    component: NewItem,
  },
  {
    path: "/:lang(en|he)?/auction/single/new",
    component: NewItem,
  },
  {
    path: "/:lang(en|he)?/item/manage/:id",
    component: EditItem,
  },
  {
    path: "/:lang(en|he)?/auction/manage/:id",
    component: EditAuction,
  },
  {
    path: "/:lang(en|he)?/:id/messages",
    component: MessagesPage,
  },
  {
    path: "/:lang(en|he)?/collection/new",
    component: CreateCollectionPage,
  },
];

// -------------- ADMIN ROUTES --------------
export const ADMIN_ROUTES = [
  {
    path: "/:lang(en|he)?/admin/users",
    component: AdminSellers,
  },
  {
    path: "/:lang(en|he)?/admin/all-messages",
    component: AdminAllMessagesPage,
  },
  {
    path: "/:lang(en|he)?/admin/password/edit",
    component: PasswordChange,
  },
  {
    path: "/:lang(en|he)?/admin/blog",
    component: BlogPostList,
  },
  {
    path: "/:lang(en|he)?/admin/categories",
    component: AdminCategoriesPage,
  },
  {
    path: "/:lang(en|he)?/admin/collections",
    component: AdminCollectionsPage,
  },
  {
    path: "/:lang(en|he)?/admin/items",
    component: AdminItemsPage,
  },
  {
    path: "/:lang(en|he)?/admin/auctions",
    component: AdminAuctionsPage,
  },
  {
    path: "/:lang(en|he)?/admin/deleted-items/:id",
    component: AdminDeletedItemsPage,
  },
  {
    path: "/:lang(en|he)?/admin/auction/manage/:id",
    component: EditAuction,
  },
  {
    path: "/:lang(en|he)?/admin/item/manage/:id",
    component: EditItem,
  },
  {
    path: "/:lang(en|he)?/admin/collection/manage/:id",
    component: AdminAddItemsToCollectionPage,
  },
  {
    path: "/:lang(en|he)?/admin/user/messages/:id",
    component: AdminMessagesPage,
  },
  {
    path: "/:lang(en|he)?/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/:lang(en|he)?/admin/user/history/:id",
    component: AdminUserHistory,
  },
];

// -------------- AUTH ROUTES --------------
export const AUTH_ROUTES = [
  {
    path: "/:lang(en|he)?/auth/login",
    component: Login,
  },
  {
    path: "/:lang(en|he)?/auth/signup",
    component: Signup,
  },
  {
    path: "/:lang(en|he)?/auth/forgot-password",
    component: Forgot,
  },
  {
    path: "/:lang(en|he)?/auth/reset-password/:token",
    component: ResetPassword,
  },
  {
    path: "/:lang(en|he)?/auth/verify/:token",
    component: Verify,
  },
];
