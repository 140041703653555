import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaShareAlt } from "react-icons/fa";
import Modal from "react-modal";
import { Link, useHistory, useParams } from "react-router-dom";
import Api from "../../api/api";
import avatar from "../../assets/avatar.jpg";
import contactIcon from "../../assets/profile/contact.png";
import phoneIcon from "../../assets/profile/phone.png";
import truckIcon from "../../assets/profile/truck.png";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import { useAuth } from "../../context/auth";

import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import topRatedBadge from "../../assets/top_rated_badge.png";
import { countries } from "./../../utils/constant";
import BasketsAndOrders from "./BasketsAndOrders";
import LocalizedLink from "../../utils/LocalizedLink";

interface IProps {
  isCollector: boolean;
  user: any;
  itemCount?: number;
  isSameAsUser?: boolean;
  isNotSameAsUser?: boolean;
  isMessagingAllowed?: boolean;
  showMessage?: boolean;
  isDeleteModalOpen?: boolean;
  auctionCount: number;
  itemCountWithHidden?: number;
  collectionCount?: number;
  basketCount?: number;
  receivedOrdersCount?: number;
}

const Detail: React.FC<IProps> = ({
  isCollector = false,
  user,
  itemCount,
  auctionCount = 0,
  isSameAsUser = false,
  isNotSameAsUser = false,
  isMessagingAllowed = true,
  showMessage = false,
  itemCountWithHidden = 0,
  collectionCount = 0,
  isDeleteModalOpen = false,
  basketCount = 0,
  receivedOrdersCount = 0,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { user: isUser } = useAuth();
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null);
  const getCountryName = (countryCode: string) => {
    const temp = countries?.find(c => c.value === countryCode);
    return temp ? temp.label : "Unknown";
  };
  const handleSendMessageClick = () => {
    localStorage.setItem("redirect", `/seller/${id}`);
    if (!isUser) history.push("/auth/login");
    setIsMessageModalOpen(true);
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const params = { receiverId: id, text: message };

    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!user)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div className="bg-white rounded-2xl p-4 md:p-6">
      {isMobile && (
        <div className="relative flex justify-end">
          <button
            onClick={async () => {
              const shareData = {
                title: `Check out ${user?.fname} ${user?.lname} on Numisnest`,
                text: `View ${user?.fname} ${user?.lname}'s profile on Numisnest.`,
                url: `${window.location.origin}/seller/${id}`,
              };

              try {
                if (navigator.share) {
                  await navigator.share(shareData);
                  toast.success("Profile shared successfully!");
                } else {
                  await navigator.clipboard.writeText(shareData.url);
                  toast.success("Profile link copied to clipboard!");
                }
              } catch (error) {
                console.error("Error sharing:", error);
                toast.error("Unable to share this profile.");
              }
            }}
            className={`flex items-center justify-center rounded-full p-1 bg-gray-300 text-white shadow-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200`}
            style={{
              width: "27px",
              height: "27px",
              position: "absolute",
              top: "-12px", // Adjust as needed to ensure no overlap
              right: "-12px",
              zIndex: 10,
            }}
            aria-label="Share this item"
          >
            <FaShareAlt
              size={14}
              className="w-4 h-4"
            />
          </button>
        </div>
      )}
      <div className="flex items-center justify-between gap-4 flex-wrap mb-6">
        <div className="flex gap-2 md:gap-3">
          <img
            src={user?.avatar || avatar}
            alt="seller avatar"
            className="size-[85px] object-cover md:size-[150px]"
          />

          <div className="flex flex-col justify-between min-w-fit">
            <div
              className="relative"
              onMouseEnter={() => setHoveredCountry(user?.country)}
              onMouseLeave={() => setHoveredCountry(null)}
            >
              <img
                className="w-6 md:w-[50px] rounded"
                src={`https://flagcdn.com/64x48/${user?.country?.toLowerCase()}.png`}
                alt={`${user?.country} flag`}
              />
              {hoveredCountry === user?.country && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                  {getCountryName(user?.country)}
                </div>
              )}
            </div>
            {itemCount != null && itemCount > 0 && (
              <span className="hidden md:block text-xs md:text-sm opacity-80">
                {itemCount} {t("items")}
              </span>
            )}
          </div>

          <div className="self-center md:pl-2">
            <div className="flex items-center">
              <p className="text-sm md:text-3xl font-bold mb-1">
                {user?.fname + " " + user?.lname}
              </p>
              {user?.topRated && (
                <div
                  className="relative ltr:ml-3 rtl:mr-3"
                  onMouseEnter={() => setHoveredTopRated(true)}
                  onMouseLeave={() => setHoveredTopRated(null)}
                >
                  <img
                    className="w-[15px] sm:max-w-[15px] md:min-w-[25px]"
                    src={topRatedBadge}
                    alt={`Top Rated Seller`}
                  />

                  {/* Tooltip */}
                  {hoveredTopRated && (
                    <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                      {"Top Rated Seller"}
                    </div>
                  )}
                </div>
              )}
            </div>

            <span className="text-sm md:text-xl">{user?.about}</span>
          </div>
        </div>
        {itemCount != null && itemCount > 0 && (
          <span className="hidden sm:block md:hidden text-xs md:text-sm opacity-80 mt-[-1rem] mb-[-2rem]">
            {itemCount} {t("items")}
          </span>
        )}
        {showMessage && isSameAsUser && (
          <div
            className="flex items-center bg-yellow-400 text-white text-sm font-bold px-4 py-3 mb-6"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18.33c-.92 0-1.66-.75-1.66-1.66s.75-1.66 1.66-1.66 1.66.75 1.66 1.66-.74 1.66-1.66 1.66zM10 14.5c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v8.5c0 .55-.45 1-1 1z" />
            </svg>
            <p>
              {t(
                "Your user and items will appear publicly after adding 3 items and after approval by our team",
              )}
            </p>
          </div>
        )}

        {isNotSameAsUser && isMessagingAllowed && (
          <Button
            onClick={handleSendMessageClick}
            className="!w-fit"
          >
            {t("message_to")}
          </Button>
        )}
        {isSameAsUser && (
          <div className="relative ">
            <LocalizedLink
              to={`/${id}/messages`}
              className="rounded-lg bg-primary hover:bg-primary-dark transition-colors text-[#ffffff] hidden md:block text-base text-center px-4 py-2"
            >
              {t("My Messages")}
            </LocalizedLink>
            {user?.unreadConversationsCount > 0 && !isMobile && (
              <span className="absolute top-[-5px] left-[-5px] md:top-[-11px] left-[-11px] bg-red-500 text-white text-xs md:text-lg font-bold rounded-full h-5 w-5 md:h-7 md:w-7 flex items-center justify-center">
                {user.unreadConversationsCount}
              </span>
            )}
            <BasketsAndOrders
              basketCount={user?.unviewedBasketsCount || 0}
              ordersCount={user?.unviewedOrdersCount || 0}
              itemCount={itemCount || 0}
            />
          </div>
        )}
      </div>

      <div className="flex gap-4">
        <div className="min-w-[28px] md:min-w-[40px]"></div>
        <p className="font-medium max-md:text-sm">
          {t("Member since")}{" "}
          {new Date(user?.createdAt).toLocaleDateString("en-GB")}
        </p>
      </div>
      <div className="h-[2px] w-[130px] bg-[#69696999] my-2.5 ml-[44px] md:ml-[56px]"></div>
      <div className="flex gap-4 items-center max-md:text-sm">
        <img
          src={contactIcon}
          className="size-7 md:size-[40px] "
          alt="contact"
        />
        <div
          dir="auto"
          className="whitespace-pre-wrap line-clamp-8 break-words max-w-[80vw]"
        >
          {user?.description || "No description"}
        </div>
      </div>
      {!isCollector && (
        <>
          <div className="h-[2px] w-[130px] bg-[#69696999] my-2.5 ml-[44px] md:ml-[56px]"></div>
          <div className="flex gap-4 items-center max-md:text-sm">
            <img
              src={truckIcon}
              className="size-7 md:size-[40px]"
              alt="truck"
            />
            <p
              dir="auto"
              className="whitespace-pre-wrap line-clamp-5 break-words max-w-[80vw]"
            >
              {user?.deliveryOption || t("No delivery info")}
            </p>
          </div>
          <div className="h-[2px] w-[130px] bg-[#69696999] my-2.5 ml-[44px] md:ml-[56px]"></div>
          <div className="flex gap-4 max-md:text-sm">
            <img
              src={phoneIcon}
              className="size-7 md:size-[40px]"
              alt="phone"
            />
            <p
              dir="auto"
              className="whitespace-pre-wrap line-clamp-5 break-words max-w-[80vw]"
            >
              {" "}
              {user?.contact || t("No contact info")}
            </p>
          </div>

          {((itemCount ?? 0) > 6 ||
            (collectionCount ?? 0) > 0 ||
            (auctionCount ?? 0) > 0) && (
            <div className="flex justify-end gap-4 mt-4">
              {(itemCount ?? 0) > 6 && (
                <LocalizedLink
                  to={`/user-items/${id}`}
                  className="text-primary hover:underline text-md"
                >
                  {t("Items")}
                </LocalizedLink>
              )}

              {(collectionCount ?? 0) > 0 && (
                <LocalizedLink
                  to={`/collection/all/${id}`}
                  className="text-primary hover:underline text-md"
                >
                  {t("Collections")}
                </LocalizedLink>
              )}

              {(auctionCount ?? 0) > 0 && (
                <LocalizedLink
                  to={`/seller/${id}/auctions`}
                  className="text-primary hover:underline text-md"
                >
                  {t("Auctions")}
                </LocalizedLink>
              )}
            </div>
          )}
        </>
      )}
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2>Send a Message to {user?.fname + " " + user?.lname}</h2>
        <textarea
          value={message}
          dir="auto"
          onChange={e => setMessage(e.target.value)}
          placeholder={t("Type your message here...")}
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsMessageModalOpen(false)}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Detail;
