import React from "react";
import { ArrowProps } from "react-multi-carousel";

export const CustomLeftArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="
        absolute left-2 top-1/2 -translate-y-1/2
        z-10 flex items-center justify-center
        w-9 h-9 bg-white rounded-full shadow
        hover:bg-gray-100 active:bg-gray-200
      "
      aria-label="Previous Slide"
    >
      <svg
        className="w-4 h-4 text-gray-700"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        viewBox="0 0 24 24"
      >
        <path
          d="M15 19l-7-7 7-7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export const CustomRightArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="
        absolute right-2 top-1/2 -translate-y-1/2
        z-10 flex items-center justify-center
        w-9 h-9 bg-white rounded-full shadow
        hover:bg-gray-100 active:bg-gray-200
      "
      aria-label="Next Slide"
    >
      <svg
        className="w-4 h-4 text-gray-700"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        viewBox="0 0 24 24"
      >
        <path
          d="M9 5l7 7-7 7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
