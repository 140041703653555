import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import circle1 from "../assets/auth/circle1.svg";
import circle2 from "../assets/auth/circle2.svg";
import circle3 from "../assets/auth/circle3.svg";
import logo from "../assets/logo.avif";
import { IAuthLayoutProps } from "../types/auth";
import LocalizedLink from "../utils/LocalizedLink";

const Auth: React.FC<IAuthLayoutProps> = ({
  title,
  submitHandle,
  children,
  showLogo = true,
  width = "max-w-[500px]",
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div className="flex min-h-screen relative">
      <img
        src={circle1}
        className="absolute left-0 bottom-0 size-[150px] max-md:hidden"
        alt="circle"
      />

      <img
        src={circle2}
        className="absolute top-0 right-0 size-[150px] max-md:hidden"
        alt="circle"
      />

      <div
        className={`grid w-full place-items-center  min-h-[100%] !p-2 ${showLogo ? "" : "mt-[-3rem]"}`}
      >
        <div className={`w-full !mx-auto ${width}`}>
          <LocalizedLink
            to="/"
            className="grid place-items-center mb-0 w-full mx-auto max-w-[500px]"
          >
            {!isMobile && (
              <img
                className="w-full "
                src={logo}
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                alt="Numisnest Logo"
              />
            )}
          </LocalizedLink>
          <div>
            <form
              onSubmit={submitHandle}
              style={{
                borderTopLeftRadius: isMobile ? "10px" : "0",
                borderTopRightRadius: isMobile ? "10px" : "0",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              className="w-full  bg-white px-2 md:px-6 py-2 md:py-5 "
            >
              {title && (
                <h1
                  className={`mb-1 text-center text-2xl md:text-3xl font-medium  ${showLogo ? "" : "mt-[-2rem]"}`}
                >
                  {t(title)}
                </h1>
              )}
              {location.pathname === "/auth/login" && (
                <p className="text-sm text-center mb-2">
                  {t("Enter your details to sign in")}
                </p>
              )}
              {children}
            </form>

            <img
              src={circle3}
              className="w-full h-[100px] mt-7"
              alt="circle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
