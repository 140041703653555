import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CiSettings } from "react-icons/ci";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import Modal from "react-modal";
import { Link, useHistory, useLocation } from "react-router-dom";
import Api from "../../api/api";
import Button from "../../common/Button";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Input from "../../common/Input";
import { useAuth } from "../../context/auth";
import { REACT_APP_KEY_NAME } from "../../utils/constant";
import { links } from "../seller";
import LocalizedLink from "../../utils/LocalizedLink";

const PasswordChange: React.FC = () => {
  const location = useLocation();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const history = useHistory();
  const { user } = useAuth();
  const [isShow, setIsShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const { t } = useTranslation();
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };
  const toggleShowPassword = (field: string) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  function validatePassword(password: any) {
    const validations = {
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      isValid: true,
    };
    validations.isValid = Object.values(validations).every(Boolean);
    return validations;
  }
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data.newPassword !== data.confirmPassword) {
      return toast.error("Confirm Password doesn't match");
    }

    setIsDisable(true);
    try {
      await Api.changePassword({
        ...data,
        ...user,
      });
      setIsDisable(false);
      history.push(`/seller/${user?._id}`);
    } catch (error) {
      console.log(error);
      setIsDisable(false);
    } finally {
    }
  };
  const handleDeleteAccount = async () => {
    try {
      const res = await Api.deleteUser(user?._id);
      if (res.status === 200) {
        toast.success("Account deleted successfully");
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        history.push("/");
        window.location.reload();
        history.push("/");
      }
    } catch (error) {
      console.error("Error deleting account", error);
      alert("Failed to delete the account");
    }
  };
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="w-full flex gap-4 md:gap-6 flex-wrap">
              <div className="w-[192px] relative">
                <button
                  onClick={() => setIsShow(prev => !prev)}
                  className="text-sm w-full rounded-lg justify-center bg-primary text-white flex items-center gap-2 py-3 px-5"
                >
                  <CiSettings
                    size={20}
                    fill="#fff"
                  />
                  Account
                </button>
                {isShow && (
                  <div className="rounded-xl bg-white mt-2 py-2 absolute left-0 right-0 top-11 z-50">
                    {links.map((itm, idx) => (
                      <div
                        key={idx}
                        className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100"
                      >
                        <itm.icon
                          size={16}
                          fill="#4979d1"
                        />
                        {/* For Delete Account, open the modal */}
                        {itm.title === "Delete account" ? (
                          <button
                            className="text-sm text-primary hover:underline"
                            onClick={() => {
                              setIsDeleteModalOpen(true), setIsShow(false);
                            }} // Open the delete modal
                          >
                            {itm.title}
                          </button>
                        ) : (
                          <LocalizedLink
                            to={itm.link}
                            className="text-sm text-primary hover:underline"
                          >
                            {itm.title}
                          </LocalizedLink>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-full md:w-[calc(100%_-_217px)]">
                <div className="bg-white rounded-2xl p-4 md:p-6">
                  <div className="flex flex-wrap-reverse gap-4 items-center">
                    <div className="md:pl-8 md:flex-1 max-md:w-full">
                      <h3 className="text-xl font-semibold mb-3">
                        Password Requirement
                      </h3>
                      <p className="mb-1.5">{t("Password must contain:")}</p>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).length ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>{t("atleast 6 characters")}</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).uppercase ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>{t("atleast 1 uppercase letter (A-Z)")}</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).lowercase ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>{t("atleast 1 lowercase letter (a-z)")}</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).number ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>{t("atleast 1 number (0-9)")}</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).symbol ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>{t("atleast 1 symbol (e.g @)")}</p>
                      </div>
                    </div>
                    <div className="md:flex-1 max-md:w-full">
                      <form
                        onSubmit={handleSubmit}
                        className="w-full bg-white px-4 md:px-6 py-8 md:py-10 rounded-xl md:rounded-2xl"
                      >
                        <div className="relative mb-4">
                          <Input
                            type={
                              showPassword.oldPassword ? "text" : "password"
                            }
                            title="Old Password*"
                            name="oldPassword"
                            value={data?.oldPassword}
                            onChange={handleInputChange}
                            placeholder={t("Enter Old Password")}
                            required
                          />
                          <button
                            type="button"
                            onClick={() => toggleShowPassword("oldPassword")}
                            className="absolute top-[65%] right-3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                          >
                            {showPassword.oldPassword ? (
                              <FaEyeSlash
                                size={20}
                                fill="var(--primary)"
                              />
                            ) : (
                              <FaEye
                                size={20}
                                fill="var(--primary)"
                              />
                            )}
                          </button>
                        </div>

                        <div className="relative mb-4">
                          <Input
                            type={
                              showPassword.oldPassword ? "text" : "password"
                            }
                            title="New Password*"
                            name="newPassword"
                            value={data?.newPassword}
                            onChange={handleInputChange}
                            placeholder={t("Enter New Password")}
                            required
                          />
                          <button
                            type="button"
                            onClick={() => toggleShowPassword("oldPassword")}
                            className="absolute top-[65%] right-3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                          >
                            {showPassword.oldPassword ? (
                              <FaEyeSlash
                                size={20}
                                fill="var(--primary)"
                              />
                            ) : (
                              <FaEye
                                size={20}
                                fill="var(--primary)"
                              />
                            )}
                          </button>
                        </div>

                        <div className="relative mb-4">
                          <Input
                            type={
                              showPassword.oldPassword ? "text" : "password"
                            }
                            title="Confirm Password*"
                            name="confirmPassword"
                            value={data?.confirmPassword}
                            onChange={handleInputChange}
                            placeholder={t("Enter Confirm Password")}
                            required
                          />
                          <button
                            type="button"
                            onClick={() => toggleShowPassword("oldPassword")}
                            className="absolute top-[65%] right-3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                          >
                            {showPassword.oldPassword ? (
                              <FaEyeSlash
                                size={20}
                                fill="var(--primary)"
                              />
                            ) : (
                              <FaEye
                                size={20}
                                fill="var(--primary)"
                              />
                            )}
                          </button>
                        </div>
                        <br />
                        <Button
                          type="submit"
                          disabled={
                            isDisable ||
                            !validatePassword(data.newPassword).isValid
                          }
                        >
                          Change Password
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Account"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">
          {t("Confirm Delete Account")}
        </h2>
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteAccount} // Call delete function
            className="px-4 py-2 bg-red-500 text-white"
          >
            Yes, Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PasswordChange;
