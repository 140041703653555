import React from "react";
import { components, InputProps } from "react-select";

const ReadOnlyInput = (props: InputProps<any>) => (
  <components.Input
    {...props}
    readOnly
  />
);

export default ReadOnlyInput;
