import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { FaSpinner } from "react-icons/fa";
import { RiAuctionFill } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Api from "../api/api";
import avatar from "../assets/avatar.jpg";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AdminHeader from "../components/Admin/AdminHeader";
import BuyerGroupTiles from "../components/BuyerGroupTiles";
import AuctionItem from "../components/Items/AuctionItem";
import Filters from "../components/Items/Filters";
import { useAuth } from "../context/auth";
import { useCurrency } from "../context/currency";
import useDirection from "../hooks/useDirection";
import { IUser } from "../types/auth";
import { ISelectOption } from "../types/common";
import { countries, europeanCountries } from "../utils/constant";
import i18n from "../utils/i18n";
import toast from "react-hot-toast";
import PaymentDetails from "../components/PaymentDetails";
import { is } from "date-fns/locale";
import LocalizedLink from "../utils/LocalizedLink";
const UserAuctionPage = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loading, setLoading] = useState(false);
  const { user, isAdmin } = useAuth();
  const [owner, setOwner] = useState<IUser | undefined>();
  const [displayType, setDisplayType] = useState<"live" | "sold" | "unsold">(
    "live",
  );
  const [urlParamsLoaded, setUrlParamsLoaded] = useState(false);
  const history = useHistory();
  const [userData, setUserData] = useState<any | undefined>();
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const { id: userId } = useParams<any>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const { userCurrency, convert } = useCurrency();
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const perPage = 72;
  const isMyAuctions = userId === user?._id;
  const pageCount = Math.ceil(filteredItems.length / perPage);
  const currentPage = Math.floor(itemOffset / perPage);

  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedLocations, setSelectedLocations] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  const [locationOptions, setLocationOptions] = useState<
    ISelectOption[] | any[]
  >(["Everywhere"]);
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [search, setSearch] = useState("");
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );

  const { t } = useTranslation();
  const direction = useDirection();
  const [groupByBuyer, setGroupByBuyer] = useState(false);
  const [paymentOption, setPaymentOption] = useState(user?.paymentOption || "");

  useEffect(() => {
    if (isMyAuctions) {
      let filtered = [];
      if (displayType === "live") {
        filtered = items.filter(item => item.remainingTime > 0);
      } else if (displayType === "sold") {
        filtered = items.filter(item => item.isSold);
      } else if (displayType === "unsold") {
        filtered = items.filter(
          item => !item.isSold && item.remainingTime <= 0,
        );
      }

      setFilteredItems(filtered);
    }
  }, [isMyAuctions, displayType, items]);

  const soldItems = useMemo(() => {
    if (isMyAuctions && displayType === "sold") {
      return items.filter(item => item.isSold && item.winner);
    }
    return [];
  }, [isMyAuctions, displayType, items]);

  const buyersGroupedItems = useMemo(() => {
    // Exit early if we’re not grouping or we’re not on “sold” items, etc.
    if (!groupByBuyer || !isMyAuctions || displayType !== "sold") return [];

    // 1) Group sold items by buyer
    const grouped: Record<string, { buyer: any; allItems: any[] }> = {};
    soldItems.forEach(item => {
      const buyerId = item?.winner?._id;
      if (buyerId) {
        if (!grouped[buyerId]) {
          grouped[buyerId] = { buyer: item.winner, allItems: [] };
        }
        grouped[buyerId].allItems.push(item);
      }
    });

    // 2) Transform into buyerGroups with "unpaid items only" and "paidAll" flag
    const result: any[] = Object.entries(grouped).map(([buyerId, data]) => {
      // Separate paid vs. unpaid
      const unpaidItems = data.allItems.filter(it => !it.paidOn);
      const paidItems = data.allItems.filter(it => !!it.paidOn);

      // “paidAll” means the user has zero unpaid items
      const paidAll = unpaidItems.length === 0;

      // Compute total only for unpaid items
      const totalWinnings = unpaidItems.reduce(
        (acc, itm) => acc + convert(itm.price, itm.currency, userCurrency),
        0,
      );

      // For sorting among "all-paid" buyers, find the most recent paidOn
      let maxPaidOn = null;
      if (paidItems.length > 0) {
        maxPaidOn = paidItems.reduce((max, item) => {
          const paidOnDate = dayjs(item.paidOn);
          return paidOnDate.isAfter(max) ? paidOnDate : max;
        }, dayjs(0));
      }

      return {
        buyer: data.buyer,
        // only show unpaid items in the tile
        buyerItems: unpaidItems,
        totalWinnings,
        currency: userCurrency,
        paidAll,
        maxPaidOn,
      };
    });

    // 3) Sort:
    //  - Unpaid first, fully paid last
    //  - Among “allPaid”, sort by maxPaidOn desc
    result.sort((a, b) => {
      // If A not paid all and B paid all => A first
      if (!a.paidAll && b.paidAll) return -1;
      // If A paid all and B not => B first
      if (a.paidAll && !b.paidAll) return 1;

      // If both are "allPaid", compare maxPaidOn in descending order
      if (a.paidAll && b.paidAll) {
        if (!a.maxPaidOn || !b.maxPaidOn) return 0; // If missing, treat as tie
        return b.maxPaidOn.valueOf() - a.maxPaidOn.valueOf();
      }

      // Otherwise, both are unpaid/partial-paid => no special ordering
      return 0;
    });

    return result;
  }, [
    groupByBuyer,
    soldItems,
    convert,
    userCurrency,
    displayType,
    isMyAuctions,
  ]);

  const [restoreScrollPosition, setRestoreScrollPosition] = useState<
    number | null
  >(null);
  const encodeOptions = (options: ISelectOption[]) =>
    options.map(o => `${o.value}:${encodeURIComponent(o.label)}`).join(",");

  const decodeOptions = (optionString: string) =>
    optionString.split(",").map(pair => {
      const [value, label] = pair.split(":");
      return { value, label: decodeURIComponent(label) };
    });
  const handleComponentClick = () => {
    const scrollPosition = window.scrollY;
    const currentPageLocal = Math.floor(itemOffset / perPage);
    sessionStorage.setItem("itemsScrollPosition", scrollPosition.toString());
    sessionStorage.setItem("itemsCurrentPage", currentPageLocal.toString());
    sessionStorage.setItem("currentDisplay", displayType);
  };
  const getUserCurrency = useCallback(async () => {
    if (user?.lastChosenCurrency) {
      setCurrency(user.lastChosenCurrency);
    } else {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const locationData = await response.json();
        const countryCode = locationData.country_code;
        if (countryCode === "IL") {
          setCurrency("ILS");
        } else if (europeanCountries.includes(countryCode)) {
          setCurrency("EUR");
        } else {
          setCurrency("USD");
        }
      } catch (error) {
        setCurrency("USD");
      }
    }
  }, [user?.lastChosenCurrency]);
  useEffect(() => {
    getUserCurrency();
  }, [getUserCurrency]);
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };

  const getUser = useCallback(async (id: string) => {
    try {
      setLoadingUserData(true);
      const res = await Api.getUser(id);
      if (res.status === 200) {
        setUserData(res?.data?.data);

        setPaymentOption(res?.data?.data?.paymentOption);
        setOwner(res?.data?.data);
        setLoadingUserData(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleItemUpdate = async () => {
    await getUserAuctions();
  };
  const handleCurrencyChange = (newCurrency: string) => {
    setCurrency(newCurrency);
    setCurrencyChanged(true);
  };
  const populateLocationOptions = useCallback(() => {
    const uniqueCountries = Array.from(
      new Set(items.map(item => item.uid?.country as string)),
    ).filter(Boolean);
    const countryOptions: ISelectOption[] = countries
      .filter(country => uniqueCountries.includes(country.value))
      .map(country => ({
        value: country.value,
        label: country.label,
      }));
    countryOptions.unshift({ value: "", label: "Everywhere" });
    setLocationOptions(countryOptions);
  }, [items]);
  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: !isPinned });
      await getUserAuctions();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 500);
    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    const fetchUserAndItems = async () => {
      await getUser(userId);
      await getUserAuctions();
    };
    fetchUserAndItems();
  }, [userId]);

  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  const handleItemDelete = async (itemId: string) => {
    setItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };
  const handleSavePaymentDetails = async (paymentDetails: string) => {
    try {
      const res = await Api.updateMyProfile({ paymentOption: paymentDetails });
      if (res.status === 200) {
        toast.success("Payment details updated successfully!");

        setPaymentOption(paymentDetails);
      }
    } catch (error) {
      console.error("Failed to save payment details:", error);
    }
  };
  async function getUserAuctions() {
    try {
      const res: any = await Api.getUserAuctions({ id: userId });

      if (res.status === 200) {
        const sortedItems = res?.data?.data?.sort((a: any, b: any) => {
          const aRemaining = a?.remainingTime;
          const bRemaining = b?.remainingTime;

          // Case 1: Both items have remainingTime > 0
          if (aRemaining > 0 && bRemaining > 0) {
            return aRemaining - bRemaining;
          }

          // Case 2: Only 'a' has remainingTime > 0
          if (aRemaining > 0 && bRemaining <= 0) {
            return -1; // 'a' comes before 'b'
          }

          // Case 3: Only 'b' has remainingTime > 0
          if (aRemaining <= 0 && bRemaining > 0) {
            return 1; // 'b' comes before 'a'
          }

          // Case 4: Both items have remainingTime <= 0
          // Sort by 'created' date in ascending order
          const aCreated = new Date(a?.created);
          const bCreated = new Date(b?.created);
          return aCreated.getTime() - bCreated.getTime();
        });

        setItems(sortedItems);
      }
    } catch (error) {
      console.error("Failed to fetch user auctions", error);
    }
  }

  useEffect(() => {
    getExchangeRates();
  }, []);

  useEffect(() => {
    populateLocationOptions();
  }, [items, populateLocationOptions]);

  // NEW: Read filters from URL parameters on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Read categories from URL
    const categoriesParam = queryParams.get("categories");
    if (categoriesParam) {
      const selectedCategoriesFromURL = decodeOptions(categoriesParam);
      setSelectedCategories(selectedCategoriesFromURL);
    }
    // Read countries from URL
    const countriesParam = queryParams.get("countries");
    if (countriesParam) {
      const selectedCountriesFromURL = decodeOptions(countriesParam);
      setSelectedCountries(selectedCountriesFromURL);
    }

    // Read years from URL
    const yearMinParam = queryParams.get("yearMin");
    const yearMaxParam = queryParams.get("yearMax");
    if (yearMinParam || yearMaxParam) {
      setYears({
        min: yearMinParam || "",
        max: yearMaxParam || "",
      });
    }

    // Read prices from URL
    const priceMinParam = queryParams.get("priceMin");
    const priceMaxParam = queryParams.get("priceMax");
    if (priceMinParam || priceMaxParam) {
      setPrices({
        min: priceMinParam || "",
        max: priceMaxParam || "",
      });
    }
    setUrlParamsLoaded(true);
  }, []);

  // NEW: Update URL parameters when filters change
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Remove existing filter parameters

    queryParams.delete("yearMin");
    queryParams.delete("yearMax");
    queryParams.delete("priceMin");
    queryParams.delete("priceMax");

    // Remove existing filter parameters
    queryParams.delete("categories");
    // Remove existing countries parameter
    queryParams.delete("countries");

    // Add countries as value-label pairs
    if (selectedCountries.length > 0) {
      queryParams.set("countries", encodeOptions(selectedCountries));
    }
    // Add categories as label-value pairs
    if (selectedCategories.length > 0) {
      queryParams.set("categories", encodeOptions(selectedCategories));
    }

    if (years.min) {
      queryParams.set("yearMin", years.min.toString());
    }
    if (years.max) {
      queryParams.set("yearMax", years.max.toString());
    }

    if (prices.min) {
      queryParams.set("priceMin", prices.min.toString());
    }
    if (prices.max) {
      queryParams.set("priceMax", prices.max.toString());
    }

    // Preserve other query parameters like 'q' and 'seller'
    const qParam = q.get("q");
    if (qParam) {
      queryParams.set("q", qParam);
    }
    const sellerParam = q.get("seller");
    if (sellerParam) {
      queryParams.set("seller", sellerParam);
    }

    // Update the URL without reloading the page
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [selectedCategories, selectedCountries, years, prices]);
  const handleGroupByBuyerChange = (checked: boolean) => {
    setGroupByBuyer(checked);

    sessionStorage.setItem("groupByBuyer", String(checked));
  };
  // 1) Remove "sessionStorage.removeItem('groupByBuyer');"
  //    from the block that depends on `storedScrollPosition` and `storedCurrentPage`.

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem("itemsScrollPosition");
    const storedCurrentPage = sessionStorage.getItem("itemsCurrentPage");

    // Notice we don’t read `groupByBuyer` here
    if (storedScrollPosition !== null && storedCurrentPage !== null) {
      setItemOffset(parseInt(storedCurrentPage, 10) * perPage);
      setRestoreScrollPosition(parseInt(storedScrollPosition, 10));

      sessionStorage.removeItem("itemsScrollPosition");
      sessionStorage.removeItem("itemsCurrentPage");
      // sessionStorage.removeItem("groupByBuyer"); // <--- REMOVED
    }
  }, []);

  // 2) Create a separate effect to restore groupByBuyer if it exists
  useEffect(() => {
    const storedGroupByBuyer = sessionStorage.getItem("groupByBuyer");
    const storedDisplay = sessionStorage.getItem("currentDisplay");
    if (storedDisplay) {
      setDisplayType(storedDisplay as "live" | "sold");

      if (storedGroupByBuyer !== null) {
        setGroupByBuyer(storedGroupByBuyer === "true");
      }
    }
    sessionStorage.removeItem("groupByBuyer");
    sessionStorage.removeItem("currentDisplay");
    // Up to you whether you remove it from sessionStorage or
    // preserve it for future visits. If you'd like it cleared once read:
  }, []);
  useEffect(() => {
    if (displayType === "live" && groupByBuyer) {
      setGroupByBuyer(false);
      sessionStorage.removeItem("groupByBuyer");
    }
  }, [displayType, groupByBuyer]);
  useEffect(() => {
    if (restoreScrollPosition !== null && filteredItems.length > 0) {
      window.scrollTo(0, restoreScrollPosition);
      setRestoreScrollPosition(null);
    }
  }, [restoreScrollPosition, filteredItems]);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: i18n.language === "he" ? "he" : "en", // Use your language detection
        }}
      >
        {/* Dynamic Title: Hebrew + English fallback */}
        <title>
          {(() => {
            const baseTitleHe =
              "נומיסנסט - מכירות פומביות למטבעות, שטרות ואוספים";
            const baseTitleEn =
              "Numisnest - Auctions for Coins, Banknotes & Collectibles";

            // Example logic to display user auctions
            if (isMyAuctions) {
              return i18n.language === "he"
                ? `המכרזים שלי | ${baseTitleHe}`
                : `My Auctions | ${baseTitleEn}`;
            } else if (owner && owner.fname) {
              return i18n.language === "he"
                ? `המכרזים של ${owner.fname} | ${baseTitleHe}`
                : `${owner.fname}'s Auctions | ${baseTitleEn}`;
            } else {
              // Fallback
              return i18n.language === "he" ? baseTitleHe : baseTitleEn;
            }
          })()}
        </title>
        {/* Meta Description */}
        <meta
          name="description"
          content={(() => {
            if (isMyAuctions) {
              return i18n.language === "he"
                ? "נהלו את המכרזים שלכם בנומיסנסט - אתר ישראלי למכירות פומביות של מטבעות, שטרות ואוספים. התחילו מכירה כבר היום!"
                : "Manage your auctions on Numisnest, the Israeli platform for coin, banknote, and collectibles auctions. Start an auction today!";
            } else if (owner && owner.fname) {
              return i18n.language === "he"
                ? `צפו במכרזים של ${owner.fname} בנומיסנסט, בית מכירות פומביות אונליין למטבעות, שטרות ואוספים בישראל.`
                : `Browse ${owner.fname}'s auctions on Numisnest, Israel's premier online auction site for coins, banknotes, and collectibles.`;
            } else {
              // Fallback
              return i18n.language === "he"
                ? "גלה מכירות פומביות של מטבעות, שטרות ואוספים בנומיסנסט, האתר המוביל בישראל."
                : "Discover coin, banknote, and collectibles auctions on Numisnest, Israel's leading online auction platform.";
            }
          })()}
        />
        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content={(() => {
            // Mirror the same logic as <title>
            const baseTitleHe =
              "נומיסנסט - מכירות פומביות למטבעות, שטרות ואוספים";
            const baseTitleEn =
              "Numisnest - Auctions for Coins, Banknotes & Collectibles";

            if (isMyAuctions) {
              return i18n.language === "he"
                ? `המכרזים שלי | ${baseTitleHe}`
                : `My Auctions | ${baseTitleEn}`;
            } else if (owner && owner.fname) {
              return i18n.language === "he"
                ? `המכרזים של ${owner.fname} | ${baseTitleHe}`
                : `${owner.fname}'s Auctions | ${baseTitleEn}`;
            } else {
              return i18n.language === "he" ? baseTitleHe : baseTitleEn;
            }
          })()}
        />
        <meta
          property="og:description"
          content={(() => {
            if (isMyAuctions) {
              return i18n.language === "he"
                ? "נהלו את המכרזים שלכם בנומיסנסט - אתר ישראלי למכירות פומביות של מטבעות, שטרות ואוספים. התחילו מכירה כבר היום!"
                : "Manage your auctions on Numisnest, the Israeli platform for coin, banknote, and collectibles auctions. Start an auction today!";
            } else if (owner && owner.fname) {
              return i18n.language === "he"
                ? `צפו במכרזים של ${owner.fname} בנומיסנסט, בית מכירות פומביות אונליין למטבעות, שטרות ואוספים בישראל.`
                : `Browse ${owner.fname}'s auctions on Numisnest, Israel's premier online auction site for coins, banknotes, and collectibles.`;
            } else {
              return i18n.language === "he"
                ? "גלה מכירות פומביות של מטבעות, שטרות ואוספים בנומיסנסט, האתר המוביל בישראל."
                : "Discover coin, banknote, and collectibles auctions on Numisnest, Israel's leading online auction platform.";
            }
          })()}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/user-auctions/${userId}`}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo2.png`} // Replace with your actual image path
        />
        <meta
          property="og:locale"
          content={i18n.language === "he" ? "he_IL" : "en_US"}
        />
        <meta
          property="og:locale:alternate"
          content={i18n.language === "he" ? "en_US" : "he_IL"}
        />
        {/* Twitter Card */}
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content={(() => {
            // Mirror logic from og:title
            const baseTitleHe =
              "נומיסנסט - מכירות פומביות למטבעות, שטרות ואוספים";
            const baseTitleEn =
              "Numisnest - Auctions for Coins, Banknotes & Collectibles";

            if (isMyAuctions) {
              return i18n.language === "he"
                ? `המכרזים שלי | ${baseTitleHe}`
                : `My Auctions | ${baseTitleEn}`;
            } else if (owner && owner.fname) {
              return i18n.language === "he"
                ? `המכרזים של ${owner.fname} | ${baseTitleHe}`
                : `${owner.fname}'s Auctions | ${baseTitleEn}`;
            } else {
              return i18n.language === "he" ? baseTitleHe : baseTitleEn;
            }
          })()}
        />
        <meta
          name="twitter:description"
          content={(() => {
            // Mirror logic from og:description
            if (isMyAuctions) {
              return i18n.language === "he"
                ? "נהלו את המכרזים שלכם בנומיסנסט - אתר ישראלי למכירות פומביות של מטבעות, שטרות ואוספים. התחילו מכירה כבר היום!"
                : "Manage your auctions on Numisnest, the Israeli platform for coin, banknote, and collectibles auctions. Start an auction today!";
            } else if (owner && owner.fname) {
              return i18n.language === "he"
                ? `צפו במכרזים של ${owner.fname} בנומיסנסט, בית מכירות פומביות אונליין למטבעות, שטרות ואוספים בישראל.`
                : `Browse ${owner.fname}'s auctions on Numisnest, Israel's premier online auction site for coins, banknotes, and collectibles.`;
            } else {
              return i18n.language === "he"
                ? "גלה מכירות פומביות של מטבעות, שטרות ואוספים בנומיסנסט, האתר המוביל בישראל."
                : "Discover coin, banknote, and collectibles auctions on Numisnest, Israel's leading online auction platform.";
            }
          })()}
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/logo2.png`}
        />
        {/* Additional SEO Enhancements */}
        <meta
          name="keywords"
          content={
            i18n.language === "he"
              ? "נומיסנסט, מכירות פומביות, מטבעות, שטרות, אספנות, מכירות אונליין, המכרזים שלי, מכירות משתמש"
              : "Numisnest, Auctions, Coins, Banknotes, Collectibles, Online Auctions, User Auctions, Seller Listings"
          }
        />
        <meta
          name="author"
          content="Numisnest - נומיסנסט"
        />
        <link
          rel="canonical"
          href={`${window.location.origin}/user-auctions/${userId}`}
        />
        {/* Robots */}
        <meta
          name="robots"
          content="index, follow"
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name:
              i18n.language === "he"
                ? `המכרזים של ${owner?.fname || "משתמש"} בנומיסנסט`
                : `${owner?.fname || "User"}'s Auctions on Numisnest`,
            description:
              i18n.language === "he"
                ? 'צפו ונהלו מכירות פומביות של מטבעות, שטרות ואוספים. חלקם מתחילים מ-1 ש"ח.'
                : "Browse and manage auctions for coins, banknotes, and collectibles. Some start from $1.",
            url: `${window.location.origin}/user-auctions/${userId}`,
            publisher: {
              "@type": "Organization",
              name: "Numisnest",
              logo: {
                "@type": "ImageObject",
                url: `${window.location.origin}/assets/logo2.png`,
              },
            },
            inLanguage: i18n.language === "he" ? "he-IL" : "en-US",
            image: `${window.location.origin}/assets/logo2.png`,
            potentialAction: {
              "@type": "SearchAction",
              target: `${window.location.origin}/user-auctions/${userId}?q={search_term}`,
              "query-input": "required name=search_term",
            },
          })}
        </script>
      </Helmet>

      {isAdmin() ? <AdminHeader /> : <Header />}
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1260px] px-1 w-full">
            {loadingUserData ? (
              // Render loading spinner or placeholder
              <div className="flex justify-center items-center py-10"></div>
            ) : (
              <div className="px-1">
                {isMyAuctions && isMobile && (
                  <LocalizedLink
                    to="/auction/single/new"
                    className="lg:mt-[-2rem] hover:bg-primary-dark transition-colors md:mt-0 md:ml-auto mb-[1rem] cursor-pointer sm:max-h-[35px] md:max-h-[60px] sm:px-2 sm:py-1 rounded-lg border border-primary align-right bg-primary text-white text-base text-center md:px-4 md:py-1"
                  >
                    {t("New Auction")}
                  </LocalizedLink>
                )}
                <div
                  className={`flex ${isMobile || isMyAuctions ? "flex-col" : ""} md:flex-row ${isMobile ? "justify-center" : "justify-between"} ${isMobile ? "items-center" : "items-start"} mb-4`}
                >
                  {/* Auctioned Items Heading */}

                  <div className={`${isMobile ? "text-center" : "text-left"}`}>
                    <p className="text-xl max-md:mt-3 md:text-2xl font-bold">
                      {isMyAuctions ? (
                        <>
                          {t("My Auctions")}{" "}
                          <RiAuctionFill
                            fill="black"
                            className="inline-block ml-[0.1rem] mb-[0.1rem] text-primary"
                          />
                        </>
                      ) : (
                        <p>
                          {i18n.language === "he"
                            ? `הפריטים שעומדים למכירה של ${userData?.fname}`
                            : `${userData?.fname}'s Auctioned Items`}
                        </p>
                      )}
                    </p>

                    {isMyAuctions && (
                      <>
                        <div className="flex gap-1 mt-0">
                          <button
                            className={`text-lg ml-0.5 mr-0 ${displayType === "live" ? "font-semibold" : ""}`}
                            onClick={() => setDisplayType("live")}
                          >
                            {t("Live Items")}
                          </button>
                          {" | "}
                          <button
                            className={`text-lg ml-0 mr-1 ${displayType === "sold" ? "font-semibold" : ""}`}
                            onClick={() => setDisplayType("sold")}
                          >
                            {t("Sold Items")}
                          </button>
                          {" | "}
                          <button
                            className={`text-lg ml-0 mr-1 ${displayType === "unsold" ? "font-semibold" : ""}`}
                            onClick={() => setDisplayType("unsold")}
                          >
                            {t("Unsold Items")}
                          </button>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="flex items-center justify-end gap-6 mt-2">
                    {isMyAuctions &&
                      user?.isAuctioneer &&
                      (!paymentOption || paymentOption === "") && (
                        <div className="mt-2 md:mt-0">
                          <PaymentDetails
                            paymentOption={user.paymentOption || null}
                            onSave={handleSavePaymentDetails}
                          />
                        </div>
                      )}
                    {displayType === "sold" && (
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="groupByBuyer"
                          checked={groupByBuyer}
                          onChange={e =>
                            handleGroupByBuyerChange(e.target.checked)
                          }
                          className="form-checkbox h-5 w-5 text-primary"
                        />
                        <label
                          htmlFor="groupByBuyer"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("Group Items by Buyer")}
                        </label>
                      </div>
                    )}
                  </div>

                  {!isMobile && !isMyAuctions && (
                    <LocalizedLink
                      to={`/seller/${userData?._id}`}
                      className={`block text-center ${isMobile ? "mt-1" : ""}`}
                    >
                      <div className="relative flex items-center gap-4 sm:max-w-[250px] md:max-w-[300px]">
                        <div className="bg-white p-3 md:p-4 rounded-2xl border border-black w-full">
                          <div className="flex gap-3">
                            <img
                              src={userData?.avatar || avatar}
                              alt="User Avatar"
                              className="sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover"
                            />
                            <div className="flex flex-col justify-center flex-grow">
                              <p className="text-lg md:text-xl font-semibold">
                                {userData?.fname} {userData?.lname}
                              </p>
                              <p className="text-sm md:text-sm md:text-left mt-1 line-clamp-2">
                                {userData?.about}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </LocalizedLink>
                  )}
                  {isMobile && !isMyAuctions && (
                    <LocalizedLink
                      to={`/seller/${userData?._id}`}
                      className="text-blue-500 text-lg font-semibold md:mb-[-1.8rem]"
                    >
                      {` Go to ${userData?.fname}'s Profile >>`}
                    </LocalizedLink>
                  )}
                </div>

                <div className="hidden">
                  <Filters
                    selectedCurrency={currency}
                    setFilteredItems={setFilteredItems}
                    items={items}
                    usedCategories={[]}
                    setLoading={setLoading}
                    isLoading={loading}
                    searchInProgress={searchInProgress}
                    setSearchInProgress={setSearchInProgress}
                    filteredItems={filteredItems}
                    search={search}
                    isSellerItems={true}
                    setSearch={setSearch}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    years={years}
                    setYears={setYears}
                    prices={prices}
                    setPrices={setPrices}
                    setItemsOffset={setItemOffset}
                    urlParamsLoaded={urlParamsLoaded}
                    exchangeRates={exchangeRates}
                    convertedPrices={items.map(i => ({
                      id: i._id,
                      convertedPrice: convert(i.price, i.currency, currency),
                      currency: i.selectedCurrency || currency,
                    }))}
                    setCurrencyChanged={setCurrencyChanged}
                    currencyChanged={currencyChanged}
                  />
                </div>
              </div>
            )}
            {loading || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : groupByBuyer && displayType === "sold" && isMyAuctions ? (
              <BuyerGroupTiles
                onComponentClick={handleComponentClick}
                buyerGroups={buyersGroupedItems}
              />
            ) : (
              <div className="grid gap-2 md:gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
                {filteredItems
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <AuctionItem
                      key={item?._id || idx}
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      isCountry={item.isCountry}
                      isSeller={!item?.hasBid && item?.uid?._id === user?._id}
                      name={item.name}
                      userOriginalBid={{
                        amount: item?.originalBidAmount || null,
                        currency: item?.originalCurrency || null,
                        bidStatus: item?.bidStatus || null,
                      }}
                      endAt={item.endAt}
                      isShowWinner={
                        item?.isSold && item?.uid?._id === user?._id
                      }
                      fromSellerPage={true}
                      hasBid={item.hasBid}
                      uid={item?.uid?._id}
                      isEnded={item.isEnded}
                      user_data={item.uid}
                      description={item.description}
                      remainingTime={item.remainingTime}
                      country={item.countries}
                      photos={item.photos}
                      isSold={item.isSold}
                      category={item.categories}
                      currency={item.currency}
                      isSellerPage={true}
                      price={convert(item.price, item.currency, item.currency)}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.updatedAt}
                      onPinToggle={handlePinToggle}
                      setCurrencyChanged={setCurrencyChanged}
                      currencyChanged={currencyChanged}
                      onItemClick={handleComponentClick}
                      bidStatus={item?.bidStatus}
                      onDelete={handleItemDelete}
                      onUpdate={handleItemUpdate}
                      winner={item?.winner}
                    />
                  ))}
              </div>
            )}
          </div>
          <br />
          {!groupByBuyer && (
            <div dir="ltr">
              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                forcePage={currentPage}
                pageCount={pageCount}
                previousLabel={"<"}
                nextLabel={">"}
                renderOnZeroPageCount={null}
                containerClassName="flex flex-wrap justify-center mt-4"
                pageClassName="mx-2"
                pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                previousClassName="mx-2"
                previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                nextClassName="mx-2"
                nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                breakClassName="mx-2"
                breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                activeClassName="bg-slate-200 rounded-full "
                activeLinkClassName="font-semibold bg-slate-200"
              />
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default UserAuctionPage;
