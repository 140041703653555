import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { CustomLeftArrow, CustomRightArrow } from "../../common/CustomArrows";
import AuctionItem from "./AuctionItem"; // Example item component
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../api/api";
import { useIsHebrew } from "./../../hooks/useIsHebrew";
import { is } from "date-fns/locale";
import { MdCancel } from "react-icons/md";
import { useAuth } from "../../context/auth";
import SeeAllButton from "../../common/SeeAllButton";

interface HotAuctionsProps {
  items?: any[];
}

const HotAuctions: React.FC<HotAuctionsProps> = () => {
  const [hotAuctions, setHotAuctions] = useState<any[]>([]);
  const { t } = useTranslation();
  const isHebrew = useIsHebrew();
  const { user, authLoading } = useAuth();
  const [tooltipVisible, setTooltipVisible] = useState(
    () => sessionStorage.getItem("tooltipVisible") !== "false",
  );
  // Carousel breakpoints  async function getHotAuctions() {
  const getHotAuctions = async () => {
    const res: any = await Api.getHomePageAuction(null);

    if (res.status == 200) {
      const results = res.data.data;

      setHotAuctions(results);
    }
  };
  useEffect(() => {
    getHotAuctions();
  }, []);

  const responsive = {
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
    },
    largeDesktop: {
      breakpoint: { max: 4000, min: 1440 },
      items: 4,
    },
  };

  if (!isMobile) {
    return (
      <div className="max-w-[1560px]  mx-auto min-h-[80vh] px-4 py-8">
        <div className="flex max-md:mt-2 items-center gap-2 mb-6">
          <span className="text-2xl md:text-3xl relative font-bold text-primary whitespace-">
            {t("Auctions")}

            {!user && !authLoading && tooltipVisible && (
              <div
                style={{
                  width:
                    window.screen.width > 1080 && window.screen.width < 1400
                      ? "140px !important"
                      : "",
                }}
                className="absolute top-full md:min-w-[140px] bg-orange-600 
                                   xl:min-w-[190px] 2xl:min-w-[200px] ltr:left-[70%] rtl:left-[58%] font-semibold
                                    transform -translate-x-1/2 mt-2 text-white 
                                     border-orange-600 shadow-lg p-2 border rounded-lg 
                                     md:text-sm lg:text-sm text-primary z-50"
              >
                {t(
                  `Turn your collectibles into cash! Auction your items easily—no premium required!`,
                )}
                <button
                  onClick={() => setTooltipVisible(false)}
                  className="absolute top-0 ltr:right-0 rtl:left-0 mt-1 ltr:mr-1 rtl:ml-1 text-red-600"
                >
                  <MdCancel style={{ fill: "white" }} />
                </button>
                <div className="tooltip-tail-orange"></div>{" "}
              </div>
            )}
          </span>
          <p className="w-[2px] h-7 bg-black"></p>
          <SeeAllButton
            label={t("See all")}
            to="/auctions"
          />
        </div>
        <div className="grid grid-cols-5 gap-4">
          {hotAuctions.map(item => (
            <div key={item._id || item.id}>
              <AuctionItem
                key={item?._id}
                isPinned={item.pinned}
                isFeatured={item.pinned}
                userOriginalBid={{
                  amount: item?.originalBidAmount || null,
                  currency: item?.originalCurrency || null,
                  bidStatus: item?.bidStatus || null,
                }}
                isCountry={item.isCountry}
                isSeller={false}
                name={item.name}
                hasBid={item.hasBid}
                endAt={item.endAt}
                uid={item?.uid?._id}
                description={item.description}
                country={item.countries}
                photos={item.photos}
                category={item.categories}
                currency={item.currency}
                price={item.price}
                year={item?.year}
                hidden={item?.hidden}
                id={item?._id}
                createdAt={item?.createdAt}
                updatedAt={item?.updatedAt}
                bidStatus={item.bidStatus}
                isSold={item.isSold}
                remainingTime={item.remainingTime}
                user_data={item.uid}
                // onAuctionEnd={refreshItems}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="max-w-[1560px] mx-auto max-md:min-h-[60vh] px-1 py-1 mt-1">
      <div className="flex max-md:mt-2 items-center gap-2 mb-2">
        <span className="text-2xl md:text-3xl font-bold text-primary whitespace-">
          {t("Auctions")}
        </span>
        <p className="w-[2px] h-7 bg-black"></p>
        <SeeAllButton
          label={t("See all")}
          to="/auctions"
        />
      </div>
      <Carousel
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={3000}
        showDots
        // Provide the custom arrows here
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        // Additional props:
        ssr={true}
        swipeable
        draggable
        keyBoardControl
        containerClass="pb-6 dir-rtl"
      >
        {hotAuctions.map(item => (
          <div
            key={item._id}
            className="px-2"
          >
            <div
              dir={isHebrew ? "rtl" : "ltr"}
              className="bg-white rounded-md shadow-sm hover:shadow-md transition-shadow"
            >
              <AuctionItem
                key={item?._id}
                isPinned={item.pinned}
                isFeatured={item.pinned}
                userOriginalBid={{
                  amount: item?.originalBidAmount || null,
                  currency: item?.originalCurrency || null,
                  bidStatus: item?.bidStatus || null,
                }}
                isCountry={item.isCountry}
                isSeller={false}
                name={item.name}
                hasBid={item.hasBid}
                endAt={item.endAt}
                uid={item?.uid?._id}
                description={item.description}
                country={item.countries}
                photos={item.photos}
                category={item.categories}
                currency={item.currency}
                price={item.price}
                year={item?.year}
                hidden={item?.hidden}
                id={item?._id}
                createdAt={item?.createdAt}
                updatedAt={item?.updatedAt}
                bidStatus={item.bidStatus}
                isSold={item.isSold}
                remainingTime={item.remainingTime}
                user_data={item.uid}
                isCarousel={true}
                // onAuctionEnd={refreshItems}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HotAuctions;
