// src/hooks/useBasket.ts
import { useState, useEffect } from "react";
import Api from "../api/api";

/**
 * Matches the shape from your backend's Basket schema:
 *  basket.items = [
 *    { item: { _id, name, price, currency, photos }, paid, deletedBySeller, ... }
 *  ]
 */
export interface IBasketSubItem {
  item: {
    _id: string;
    name: string;
    price: number;
    currency: string;
    photos?: Array<{ url: string }>;
  };
  paid?: boolean;
  paidOn?: string | null;
  deletedBySeller?: boolean;
  addedAt?: string;
}

export interface IBasket {
  _id: string;
  items: IBasketSubItem[];
  buyer: string;
  seller: string | null;
  sentAt?: string | null;
  updatedAt?: string | null;
}

export function useBasket(sellerId?: string) {
  const [basket, setBasket] = useState<IBasket | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sending, setSending] = useState(false);
  const [removingItemId, setRemovingItemId] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBasket() {
      try {
        setLoading(true);
        const { data } = await Api.getBasket({ seller: sellerId });

        if (data?.status === 200) {
          setBasket(data.data);
        } else {
          setError(data?.message || "Failed to fetch basket");
        }
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    }

    fetchBasket();
  }, [sellerId]);

  async function removeItem(itemId: string) {
    setRemovingItemId(itemId);
    try {
      const { data } = await Api.removeItemFromBasket(itemId, {
        seller: sellerId,
      });
      if (data?.status === 200) {
        setBasket(data.data);
      } else {
        setError(data?.message || "Error removing item");
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setRemovingItemId(null);
    }
  }

  async function sendBasket() {
    if (!basket || basket.items.length === 0) {
      setError("Your basket is empty");
      return;
    }
    setSending(true);
    try {
      const { data } = await Api.sendBasketToSeller({ seller: sellerId });
      if (data?.status === 200) {
        setBasket(data.data);
      } else {
        setError(data?.message || "Failed to send basket");
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setSending(false);
    }
  }

  return {
    basket,
    loading,
    error,
    sending,
    removingItemId,
    removeItem,
    sendBasket,
  };
}
