import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const AuctionsPageHelmet = ({ view }) => {
  const { i18n } = useTranslation();
  const language = i18n.language; // "he" or "en"
  const isHebrew = language === "he";

  // Helper function to get localized strings based on view and language
  const getTitle = () => {
    if (isHebrew) {
      switch (view) {
        case "all":
          return 'כל המכרזים - נומיסנסט | מכירות פומביות אונליין החל מ-1 ש"ח | קנו ומכרו מטבעות, שטרות ואוספים בישראל';
        case "myBids":
          return "המכירות הפומביות שלי - נומיסנסט | המכירות בהם הגשתי הצעות";
        case "purchases":
          return "הרכישות שלי - נומיסנסט | הפריטים שרכשתי במכירות פומביות";
        case "history":
          return "היסטוריית מכירות - נומיסנסט | כל המכירות הפומביות שהשתתפתי בהן";
        default:
          return "נומיסנסט - בית למכרזים, מטבעות, שטרות ואוספים בישראל";
      }
    } else {
      switch (view) {
        case "all":
          return "All Auctions - Numisnest | Online Auctions Starting from 1 ILS | Buy & Sell Coins, Banknotes & Collectibles in Israel";
        case "myBids":
          return "My Bids - Numisnest | Auctions I've Bid On";
        case "purchases":
          return "My Purchases - Numisnest | Items I've Bought in Auctions";
        case "history":
          return "Sales History - Numisnest | All Auctions I've Participated In";
        default:
          return "Numisnest - Home for Auctions, Coins, Banknotes & Collectibles in Israel";
      }
    }
  };

  const getDescription = () => {
    if (isHebrew) {
      switch (view) {
        case "all":
          return 'גלו את כל המכירות הפומביות הפעילות בנומיסנסט. מכירות פומביות אונליין החל מ-1 ש"ח למטבעות, שטרות ואוספים בישראל. הצטרפו לפלטפורמת המכירות הפומביות המובילה היום!';
        case "myBids":
          return "עקבו אחר המכירות הפומביות שבהן הגשת הצעות. נומיסנסט מאפשר לכם לנהל ולצפות בהצעות שלכם במכירות פומביות בישראל.";
        case "purchases":
          return "צפו בכל הרכישות שלכם במכירות פומביות בנומיסנסט. ניהול קל של הפריטים שרכשתם והיסטוריית הרכישות שלכם.";
        case "history":
          return "בדקו את היסטוריית המכירות הפומביות שלכם בנומיסנסט. גלו את כל המכירות בהן השתתפתם בעבר.";
        default:
          return "נומיסנסט - האתר המוביל בישראל למכרזים, מטבעות, שטרות ואוספים. הצטרפו למכירות פומביות אונליין וקנו פריטים נדירים במחירים משתלמים.";
      }
    } else {
      switch (view) {
        case "all":
          return "Discover all active auctions on Numisnest. Online auctions starting from 1 ILS for coins, banknotes & collectibles in Israel. Join today's leading auction platform!";
        case "myBids":
          return "Track the auctions you've bid on. Numisnest allows you to manage and view your bids in auctions in Israel.";
        case "purchases":
          return "View all your purchases in auctions on Numisnest. Easily manage the items you've bought and your purchase history.";
        case "history":
          return "Check your auction sales history on Numisnest. Discover all the auctions you've participated in the past.";
        default:
          return "Numisnest - Israel's leading site for auctions, coins, banknotes & collectibles. Join online auctions and buy rare items at great prices.";
      }
    }
  };

  const getKeywords = () => {
    if (isHebrew) {
      switch (view) {
        case "all":
          return 'נומיסנסט, מכירות פומביות, מכירות פומביות אונליין, מכירות החל מ-1 ש"ח, קנו מטבעות, מכרו שטרות, אוספים, מכירות פומביות בישראל, מכירות מטבעות, מכירות שטרות, מכירות אוספים';
        case "myBids":
          return "המכירות הפומביות שלי, הצעות במכרזים, נומיסנסט, מכירות פומביות בישראל, הצעות במכירות פומביות";
        case "purchases":
          return "הרכישות שלי, רכישות במכרזים, נומיסנסט, מכירות פומביות בישראל, רכישות במכירות פומביות";
        case "history":
          return "היסטוריית מכירות פומביות, מכירות פומביות בעבר, נומיסנסט, מכירות פומביות בישראל";
        default:
          return 'נומיסנסט, מכירות פומביות, מטבעות, שטרות, אוספים, מכירות פומביות אונליין, מכירות החל מ-1 ש"ח, קנו ומכרו מטבעות בישראל';
      }
    } else {
      switch (view) {
        case "all":
          return "Numisnest, auctions, online auctions, auctions starting from 1 ILS, buy coins, sell banknotes, collectibles, auctions in Israel, coin auctions, banknote auctions, collectibles auctions";
        case "myBids":
          return "My bids, auction bids, Numisnest, auctions in Israel, bids in auctions";
        case "purchases":
          return "My purchases, purchases in auctions, Numisnest, auctions in Israel, purchases in auctions";
        case "history":
          return "Auction sales history, past auctions, Numisnest, auctions in Israel";
        default:
          return "Numisnest, auctions, coins, banknotes, collectibles, online auctions, auctions starting from 1 ILS, buy & sell coins in Israel";
      }
    }
  };

  const getAuthor = () => {
    if (isHebrew) {
      switch (view) {
        case "all":
          return "נומיסנסט - כל המכרזים";
        case "myBids":
          return "נומיסנסט - המכירות הפומביות שלי";
        case "purchases":
          return "נומיסנסט - הרכישות שלי";
        case "history":
          return "נומיסנסט - היסטוריית מכירות";
        default:
          return "נומיסנסט - בית למכרזים, מטבעות, שטרות ואוספים בישראל";
      }
    } else {
      switch (view) {
        case "all":
          return "Numisnest - All Auctions";
        case "myBids":
          return "Numisnest - My Bids";
        case "purchases":
          return "Numisnest - My Purchases";
        case "history":
          return "Numisnest - Sales History";
        default:
          return "Numisnest - Home for Auctions, Coins, Banknotes & Collectibles in Israel";
      }
    }
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: isHebrew ? "he" : "en",
      }}
    >
      {/* =====================================
           Dynamic Page Title
      ===================================== */}
      <title>{getTitle()}</title>

      {/* =====================================
           Meta Description
      ===================================== */}
      <meta
        name="description"
        content={getDescription()}
      />

      {/* =====================================
           Open Graph (OG) Tags for Social Sharing
      ===================================== */}
      <meta
        property="og:title"
        content={getTitle()}
      />
      <meta
        property="og:description"
        content={getDescription()}
      />
      <meta
        property="og:image"
        content={
          "https://numisnest.com/logo2.png" // Assuming same image for all views; adjust if needed
        }
      />
      <meta
        property="og:url"
        content={window.location.href}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:locale"
        content={isHebrew ? "he_IL" : "en_US"}
      />
      <meta
        property="og:locale:alternate"
        content={isHebrew ? "en_US" : "he_IL"}
      />

      {/* =====================================
           Twitter Card Tags
      ===================================== */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content={getTitle()}
      />
      <meta
        name="twitter:description"
        content={getDescription()}
      />
      <meta
        name="twitter:image"
        content={`${window.location.origin}/logo2.png`}
      />

      {/* =====================================
           Additional SEO Enhancements
      ===================================== */}
      <meta
        name="keywords"
        content={getKeywords()}
      />
      <meta
        name="author"
        content={getAuthor()}
      />
      <link
        rel="canonical"
        href={window.location.href}
      />

      {/* =====================================
           Robots Meta Tag
      ===================================== */}
      <meta
        name="robots"
        content="index, follow"
      />

      {/* =====================================
           Structured Data (JSON-LD) for Auctions
      ===================================== */}
      {view === "all" && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: isHebrew
              ? "נומיסנסט - כל המכרזים"
              : "Numisnest - All Auctions",
            url: "https://numisnest.com",
            potentialAction: {
              "@type": "SearchAction",
              target: "https://numisnest.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
            description: isHebrew
              ? 'גלו את כל המכירות הפומביות הפעילות בנומיסנסט. מכירות פומביות אונליין החל מ-1 ש"ח למטבעות, שטרות ואוספים בישראל. הצטרפו לפלטפורמת המכירות הפומביות המובילה היום!'
              : "Discover all active auctions on Numisnest. Online auctions starting from 1 ILS for coins, banknotes & collectibles in Israel. Join today's leading auction platform!",
            image: "https://numisnest.com/assets/og-default.jpg",
            breadcrumb: {
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: isHebrew ? "עמוד הבית" : "Home",
                  item: "https://numisnest.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: isHebrew ? "כל המכרזים" : "All Auctions",
                  item: "https://numisnest.com/auctions",
                },
              ],
            },
          })}
        </script>
      )}

      {/* Add similar structured data for other views if necessary */}
    </Helmet>
  );
};

export default AuctionsPageHelmet;
