// src/pages/HiddenItems.tsx
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
import Api from "../../api/api";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Item from "../../components/Items/Item";
import { useAuth } from "../../context/auth";
import { useCurrency } from "../../context/currency";

const HiddenItems = () => {
  const [hiddenItems, setHiddenItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { userCurrency, convert } = useCurrency();
  useEffect(() => {
    fetchHiddenItems();
  }, [user]);

  const fetchHiddenItems = async () => {
    setLoading(true);
    try {
      const itemsRes = await Api.getUserItem({ id: user?._id });
      const hiddenItemsData = itemsRes?.data?.data?.items?.filter(
        (item: any) => item.hidden,
      );
      setHiddenItems(hiddenItemsData);
    } catch (error) {
      console.error("Failed to fetch hidden items:", error);
    } finally {
      setLoading(false);
    }
  };
  const { t } = useTranslation();
  const handleItemUpdate = async () => {
    await fetchHiddenItems();
  };

  const handleItemDelete = async (itemId: string) => {
    setHiddenItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-10">
        <FaSpinner className="animate-spin text-3xl text-primary" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <p className="text-center text-2xl md:text-3xl font-bold mb-5 text-[#D03531]">
              {t("Hidden Items")}
            </p>
            <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
              <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {hiddenItems.map((item, idx) => (
                  <Item
                    key={item._id || idx}
                    isSeller={true}
                    name={item.name}
                    uid={item.uid._id}
                    user_data={item.uid}
                    description={item.description}
                    country={item.country}
                    photos={item.photos}
                    category={item.categories}
                    currency={userCurrency}
                    price={convert(item.price, item.currency, userCurrency)}
                    year={item?.year}
                    hidden={item?.hidden}
                    id={item?._id}
                    createdAt={item?.createdAt}
                    updatedAt={item?.updatedAt}
                    onDelete={handleItemDelete}
                    onUpdate={handleItemUpdate}
                  />
                ))}
              </div>
              {hiddenItems.length === 0 && (
                <p className="text-center">{t("No hidden items found.")}</p>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default HiddenItems;
