// ScrollToTopNoAnimation.tsx
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTopNoAnimation() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // This fires *before* the browser repaints on route change,
    // so the user does not see a "scroll" animation at all.
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
