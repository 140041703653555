export const handlePrice = (priceTag: number) => {
  if (priceTag >= 100) {
    // No decimal places for prices 1000 and above
    return priceTag.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (priceTag >= 10) {
    // One decimal place for prices between 100 and 999.99
    return Number(priceTag.toFixed(1).toLocaleString());
  } else {
    // Two decimal places for prices below 100
    return Number(priceTag.toFixed(2).toLocaleString());
  }
};
