import React from "react";
import { Link } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { FaShoppingBasket } from "react-icons/fa";
import LocalizedLink from "../../utils/LocalizedLink";
// Example props: pass basketCount, receivedCount, etc.
interface BasketsAndOrdersProps {
  basketCount?: number;
  ordersCount?: number;
  itemCount?: number;
}

const BasketsAndOrders: React.FC<BasketsAndOrdersProps> = ({
  basketCount = 0,
  ordersCount = 0,
  itemCount = 0,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex md:flex-col  max-md:mt-[0.7rem] md:mt-[1.5rem] items-end gap-2">
      {/* "My baskets" button */}
      <LocalizedLink
        to="/baskets"
        className="flex items-center relative gap-2 border  border-primary text-primary px-1 py-1 rounded-md"
      >
        <FaShoppingBasket
          fill="var(--primary)"
          className="text-primary"
        />
        <span className="text-primary font-semibold">{t("My baskets")}</span>
        {/* Show count bubble if there's more than 0 */}
        {basketCount > 0 && (
          <span className="bg-primary absolute top-[-40%] ltr:left-[-9%] rtl:right-[-9%] text-white text-xs font-semibold rounded-full px-2 py-1">
            {basketCount}
          </span>
        )}
      </LocalizedLink>

      {/* "Received orders" button */}
      {itemCount > 2 && (
        <LocalizedLink
          to="/orders/received"
          className="flex items-center gap-2 relative border md:mt-[1.5rem] border-primary text-primary px-1 py-1 rounded-md"
        >
          <FaClipboardList
            fill="var(--primary)"
            className="text-primary"
          />
          <span className="text-primary font-semibold">
            {t("Received orders")}
          </span>
          {ordersCount > 0 && (
            <span className="bg-primary absolute top-[-30%] ltr:left-[-9%] rtl:right-[-9%] text-white text-xs font-semibold rounded-full px-2 py-1">
              {ordersCount}
            </span>
          )}
        </LocalizedLink>
      )}
    </div>
  );
};

export default BasketsAndOrders;
