// src/pages/BasketPage.tsx
import React, { useEffect } from "react";
import { FaSpinner, FaPaperPlane } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useBasket } from "../hooks/useBasket";
import BasketProfile from "../components/Basket/BasketProfile";
import BasketItems from "../components/Basket/BasketItems";
import BasketTotals from "../components/Basket/BasketTotals";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Button from "../common/Button";
import { useAuth } from "../context/auth";
import { useTranslation } from "react-i18next";
import { IUser } from "../types/auth";
import { toUserProfile } from "../utils/toUserProfile";
import Api from "../api/api";

import BackButton from "../common/BackButton";
import LocalizedLink from "../utils/LocalizedLink";

// Helper to check if a variable is an IUser object
const isIUser = (val: any): val is IUser =>
  !!val && typeof val === "object" && "_id" in val;

const BasketPage: React.FC = () => {
  const { user, authLoading } = useAuth(); // 1) we have authLoading now
  const { t } = useTranslation();
  const { sellerId } = useParams<{ sellerId: string }>();

  const {
    basket,
    loading,
    error,
    sending,
    removingItemId,
    removeItem,
    sendBasket,
  } = useBasket(sellerId);

  useEffect(() => {
    if (basket && basket._id) {
      // Mark the entire basket as viewed
      Api.markBasketViewed(basket._id);
    }
  }, [basket]);
  const unpaidItemsCount = basket?.items.filter(
    bi => !bi.paid && !bi.deletedBySeller,
  ).length;
  // 2) If still loading auth, show spinner
  if (authLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-3xl text-primary" />
      </div>
    );
  }

  // 3) If user is null after loading, show "not authenticated"
  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl text-gray-600">{t("User not authenticated")}</p>
      </div>
    );
  }

  // Show errors, if any
  if (error) {
    toast.error(error);
  }

  // Show basket loading
  if (loading) {
    return (
      <div>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <FaSpinner className="animate-spin text-3xl text-primary" />
        </div>
        <Footer />
      </div>
    );
  }

  if (!basket) {
    return (
      <div>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <p className="text-xl text-gray-600">{t("Basket not found")}</p>
        </div>
        <Footer />
      </div>
    );
  }

  const { items, sentAt, buyer, seller, updatedAt } = basket;

  // Decide which profile to show:
  // If the current user is the seller, show the buyer's profile, else show the seller's profile
  let showProfile: IUser | null = null;
  let hidePaymentOption = false;

  if (isIUser(seller) && user._id === seller._id) {
    // The current user is the seller => show the buyer's profile
    if (isIUser(buyer)) {
      showProfile = buyer;
    }
    // We'll hide payment details from the "seller" side
    hidePaymentOption = true;
  } else {
    // The current user is presumably the buyer => show the seller's profile
    if (isIUser(seller)) {
      showProfile = seller;
    }
  }

  function handleRemoveItem(itemId: string) {
    removeItem(itemId);
  }

  function handleSendBasket() {
    if (items.length === 0) {
      toast.error(t("Your basket is empty"));
      return;
    }
    sendBasket();
  }

  return (
    <>
      <Header />
      <main>
        <div className="max-w-[1260px] mx-auto px-4 py-6 bg-white mt-5 rounded-lg shadow">
          <BackButton
            label={"Back to baskets"}
            to="/baskets"
          />
          <div className="flex flex-wrap items-center justify-between border-b pb-4 mb-6 gap-4">
            {showProfile ? (
              <>
                <BasketProfile
                  // If you want to hide payment details, pass a prop or transform
                  profile={{
                    ...toUserProfile(showProfile),
                    ...(hidePaymentOption ? { paymentOption: "" } : {}),
                  }}
                />
                <div>
                  <LocalizedLink to={`/seller/${showProfile._id}`}>
                    <Button className="!w-auto px-4 py-2 whitespace-nowrap">
                      {t("Visit profile")}
                    </Button>
                  </LocalizedLink>
                </div>
              </>
            ) : (
              // If we can't determine the opposite user, show fallback
              <p className="text-red-500">{t("Opposite user not found")}</p>
            )}
          </div>

          <div className="flex flex-wrap items-center justify-between mb-6">
            <div>
              <h2 className="text-xl font-semibold mb-2">
                {t("Basket total")}:
              </h2>
              <BasketTotals items={items} />
            </div>
            <div className="flex gap-4 mt-4 md:mt-0">
              {sentAt ? (
                <p className="text-green-600 text-lg">
                  {t("Basket updated on")}:{" "}
                  {new Date(basket.updatedAt || sentAt).toLocaleString("en-GB")}
                </p>
              ) : (
                <Button
                  onClick={handleSendBasket}
                  disabled={sending}
                  className="!bg-orange-500 !hover:bg-orange-600 active:bg-orange-700 !text-white !px-3 !py-2 !text-sm flex items-center gap-2 !whitespace-nowrap"
                >
                  {sending ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    <FaPaperPlane />
                  )}
                  {t("Send basket")}
                </Button>
              )}
            </div>
          </div>

          {items.length > 0 && (
            <section className="mb-8">
              <div className="flex flex-col gap-2 mb-2">
                <h2 className="text-xl font-bold">
                  {t("Basket items")} ({unpaidItemsCount})
                </h2>
              </div>
              <BasketItems
                items={items}
                removingItemId={removingItemId}
                onRemove={handleRemoveItem}
              />
            </section>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BasketPage;
