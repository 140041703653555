import React from "react";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import LocalizedLink from "../utils/LocalizedLink";

interface SeeAllButtonProps {
  label?: string;
  to: string;
}

const SeeAllButton: React.FC<SeeAllButtonProps> = ({
  label = "See all",
  to,
}) => {
  return (
    <LocalizedLink
      to={to}
      className="
      inline-flex items-center justify-center
      font-bold
      px-3 py-2
      rounded-full
      bg-grey
      text-primary
      border border-silver
      transition-transform duration-200
      hover:scale-105
      active:scale-95
      shadow-md
    "
    >
      <span className="mr-1 text-primary">{label}</span>
      <HiArrowNarrowRight
        className="w-5 h-5   rtl:rotate-180"
        fill={"var(--primary)"}
      />
    </LocalizedLink>
  );
};

export default SeeAllButton;
