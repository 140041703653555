import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { FaSpinner } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";
import Api from "../api/api";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Filters from "../components/Items/Filters";
import Item from "../components/Items/Item";
import { useAuth } from "../context/auth";
import { useCurrency } from "../context/currency";
import { useLocationContext } from "../context/location";
import i18n from "../utils/i18n";
import { IUser } from "../types/auth";
import { ISelectOption } from "../types/common";
import { countries, europeanCountries } from "../utils/constant";
const Items = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const [fallbackItems, setFallbackItems] = useState<any>([]);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [userData, setUserData] = useState<IUser | undefined>();
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const { userCurrency, convert } = useCurrency();
  const { t } = useTranslation();
  const [urlParamsLoaded, setUrlParamsLoaded] = useState(false);
  const history = useHistory();
  const [showPage, setShowPage] = useState(false);
  const perPage = 72;
  const pageCount = Math.ceil(filteredItems.length / perPage);
  const currentPage = Math.floor(itemOffset / perPage);

  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const { selectedLocation: selectedLocations } = useLocationContext();
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  const [locationOptions, setLocationOptions] = useState<
    ISelectOption[] | any[]
  >(["Everywhere"]);
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [search, setSearch] = useState("");
  const [usedCategories, setUsedCategories] = useState<any>([]);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const [restoreScrollPosition, setRestoreScrollPosition] = useState<
    number | null
  >(null);

  const getUserCurrency = useCallback(async () => {
    if (user?.lastChosenCurrency) {
      setCurrency(user.lastChosenCurrency);
    } else {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const locationData = await response.json();
        const countryCode = locationData.country_code;
        if (countryCode === "IL") {
          setCurrency("ILS");
        } else if (europeanCountries.includes(countryCode)) {
          setCurrency("EUR");
        } else {
          setCurrency("USD");
        }
      } catch (error) {
        setCurrency("USD");
      }
    }
  }, [user?.lastChosenCurrency]);
  useEffect(() => {
    getUserCurrency();
  }, [getUserCurrency]);
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    -setItemOffset(newOffset);
    +safeSetItemOffset(newOffset);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getUser = useCallback(async (id: string) => {
    try {
      const res = await Api.getUser(id);
      if (res.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const encodeOptions = (options: ISelectOption[]) =>
    options.map(o => `${o.value}:${encodeURIComponent(o.label)}`).join(",");

  const decodeOptions = (optionString: string) =>
    optionString.split(",").map(pair => {
      const [value, label] = pair.split(":");
      return { value, label: decodeURIComponent(label) };
    });

  const populateLocationOptions = useCallback(() => {
    const uniqueCountries = Array.from(
      new Set(items.map(item => item.uid?.country as string)),
    ).filter(Boolean);
    const countryOptions: ISelectOption[] = countries
      .filter(country => uniqueCountries.includes(country.value))
      .map(country => ({
        value: country.value,
        label: country.label,
      }));
    countryOptions.unshift({ value: "", label: "Everywhere" });
    setLocationOptions(countryOptions);
  }, [items]);
  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: !isPinned });
      await getAllItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 500);
    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    if (!searchInProgress) {
      getAllItems();
    }
  }, [user]);
  const handleItemClick = () => {
    const scrollPosition = window.scrollY;
    const currentPageLocal = Math.floor(itemOffset / perPage);
    sessionStorage.setItem("itemsScrollPosition", scrollPosition.toString());
    sessionStorage.setItem("itemsCurrentPage", currentPageLocal.toString());
  };
  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  async function getAllItems() {
    const res: any = await Api.getAllItem(null);
    if (res.status == 200) {
      const finalItems = res.data.data.items;

      setUsedCategories(res.data.data.usedCategories);
      setItems(finalItems);
      setFallbackItems(finalItems);
    }
  }
  // Inside Items.tsx
  function safeSetItemOffset(newOffset: number) {
    // If the user is logged in and we currently have a nonzero offset,
    // skip or clamp if we see an attempt to go back to 0.
    if (user && newOffset === 0 && itemOffset !== 0) {
      console.log(
        "Blocked offset=0 because user is logged in and was at a higher page",
      );
      return;
    }

    // Otherwise, proceed normally
    setItemOffset(newOffset);
  }

  useEffect(() => {
    getExchangeRates();
  }, []);

  useEffect(() => {
    populateLocationOptions();
  }, [items, populateLocationOptions]);
  // NEW: Read filters from URL parameters on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Read categories from URL
    const categoriesParam = queryParams.get("categories");
    if (categoriesParam) {
      const selectedCategoriesFromURL = decodeOptions(categoriesParam);
      setSelectedCategories(selectedCategoriesFromURL);
    }
    // Read countries from URL
    const countriesParam = queryParams.get("countries");
    if (countriesParam) {
      const selectedCountriesFromURL = decodeOptions(countriesParam);
      setSelectedCountries(selectedCountriesFromURL);
    }

    // Read years from URL
    const yearMinParam = queryParams.get("yearMin");
    const yearMaxParam = queryParams.get("yearMax");
    if (yearMinParam || yearMaxParam) {
      setYears({
        min: yearMinParam || "",
        max: yearMaxParam || "",
      });
    }

    // Read prices from URL
    const priceMinParam = queryParams.get("priceMin");
    const priceMaxParam = queryParams.get("priceMax");
    if (priceMinParam || priceMaxParam) {
      setPrices({
        min: priceMinParam || "",
        max: priceMaxParam || "",
      });
    }
    setUrlParamsLoaded(true);
  }, []);

  // NEW: Update URL parameters when filters change
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Remove existing filter parameters

    queryParams.delete("yearMin");
    queryParams.delete("yearMax");
    queryParams.delete("priceMin");
    queryParams.delete("priceMax");

    // Remove existing filter parameters
    queryParams.delete("categories");
    // Remove existing countries parameter
    queryParams.delete("countries");

    // Add countries as value-label pairs
    if (selectedCountries.length > 0) {
      queryParams.set("countries", encodeOptions(selectedCountries));
    }
    // Add categories as label-value pairs
    if (selectedCategories.length > 0) {
      queryParams.set("categories", encodeOptions(selectedCategories));
    }

    if (years.min) {
      queryParams.set("yearMin", years.min.toString());
    }
    if (years.max) {
      queryParams.set("yearMax", years.max.toString());
    }

    if (prices.min) {
      queryParams.set("priceMin", prices.min.toString());
    }
    if (prices.max) {
      queryParams.set("priceMax", prices.max.toString());
    }

    // Preserve other query parameters like 'q' and 'seller'
    const qParam = q.get("q");
    if (qParam) {
      queryParams.set("q", qParam);
    }
    const sellerParam = q.get("seller");
    if (sellerParam) {
      queryParams.set("seller", sellerParam);
    }

    // Update the URL without reloading the page
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [selectedCategories, selectedCountries, years, prices]);

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem("itemsScrollPosition");
    const storedCurrentPage = sessionStorage.getItem("itemsCurrentPage");
    if (storedScrollPosition !== null && storedCurrentPage !== null) {
      const scrollPosition = parseInt(storedScrollPosition, 10);
      const currentPageLocal = parseInt(storedCurrentPage, 10);
      const offsetFromStorage = currentPageLocal * perPage;

      -setItemOffset(offsetFromStorage);
      +safeSetItemOffset(offsetFromStorage);

      setRestoreScrollPosition(scrollPosition);
      sessionStorage.removeItem("itemsScrollPosition");
      sessionStorage.removeItem("itemsCurrentPage");
    }
  }, []);

  useEffect(() => {
    if (restoreScrollPosition !== null && filteredItems.length > 0) {
      window.scrollTo(0, restoreScrollPosition);
      setRestoreScrollPosition(null);
    }
  }, [restoreScrollPosition, filteredItems]);
  useEffect(() => {
    if (!searchInProgress) {
      setLoading(true);
      let updatedItems = items;
      if (selectedLocations.length > 0) {
        updatedItems = fallbackItems.filter(item =>
          selectedLocations.includes(item.uid?.country),
        );
      } else {
        updatedItems = fallbackItems;
      }
      setItems(updatedItems);
      //setFilteredItems(updatedItems);
      setLoading(false);
    }
  }, [selectedLocations]);
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: i18n.language === "he" ? "he" : "en",
        }}
      >
        {/* ========== Title (HE vs. EN) ========== */}
        <title>
          {i18n.language === "he"
            ? searchQuery
              ? `חיפוש: ${searchQuery} - נומיסנסט | זירת מכירות פומביות למטבעות ואספנות`
              : "נומיסנסט | זירת מכירות פומביות למטבעות, שטרות ואספנות בישראל"
            : searchQuery
              ? `Search: ${searchQuery} - Numisnest | Israel's Auction Platform for Coins & Collectibles`
              : "Numisnest | Israel’s Leading Auction Site for Coins, Banknotes & Collectibles"}
        </title>

        {/* ========== Meta Description (HE vs. EN) ========== */}
        <meta
          name="description"
          content={
            i18n.language === "he"
              ? searchQuery
                ? `תוצאות החיפוש עבור "${searchQuery}" באתר נומיסנסט. גלו מגוון מטבעות, שטרות ופריטי אספנות נדירים.`
                : "נומיסנסט - הבית הישראלי לאספנות. זירת מכירות פומביות למטבעות, שטרות ופריטים נדירים. הצטרפו עכשיו וגלה את הפריט הבא שלך!"
              : searchQuery
                ? `Search results for "${searchQuery}" on Numisnest. Explore a wide range of rare coins, banknotes, and collectible items.`
                : "Numisnest - Israel's premier auction platform for coins, banknotes, and collectible items. Join now to discover your next treasure!"
          }
        />

        {/* ========== Open Graph (OG) Tags ========== */}
        <meta
          property="og:title"
          content={
            i18n.language === "he"
              ? searchQuery
                ? `חיפוש: ${searchQuery} - נומיסנסט | מכירות פומביות למטבעות ואספנות`
                : "נומיסנסט | זירת מכירות פומביות למטבעות, שטרות ואספנות בישראל"
              : searchQuery
                ? `Search: ${searchQuery} - Numisnest | Israel’s Coin & Collectibles Auction Platform`
                : "Numisnest | Israel’s Leading Auction Site for Coins & Collectibles"
          }
        />
        <meta
          property="og:description"
          content={
            i18n.language === "he"
              ? searchQuery
                ? `תוצאות החיפוש עבור "${searchQuery}" באתר נומיסנסט. גלו מטבעות, שטרות ופריטי אספנות נדירים.`
                : "נומיסנסט - האתר המוביל בישראל לאספנות ומטבעות. הצטרפו למכירות פומביות או קנו ישירות!"
              : searchQuery
                ? `Search results for "${searchQuery}" on Numisnest. Discover coins, banknotes, and rare collectibles.`
                : "Numisnest - Israel's leading site for auctions and direct sales of coins, banknotes, and rare collectibles."
          }
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/items`}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:locale"
          content={i18n.language === "he" ? "he_IL" : "en_US"}
        />
        <meta
          property="og:locale:alternate"
          content={i18n.language === "he" ? "en_US" : "he_IL"}
        />

        {/* ========== Twitter Card ========== */}
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content={
            i18n.language === "he"
              ? searchQuery
                ? `חיפוש: ${searchQuery} - נומיסנסט | מכירות פומביות למטבעות`
                : "נומיסנסט | זירת מכירות פומביות למטבעות ופריטי אספנות"
              : searchQuery
                ? `Search: ${searchQuery} - Numisnest | Israel’s Coins & Collectibles Marketplace`
                : "Numisnest | Leading Israeli Auction Site for Coins & Banknotes"
          }
        />
        <meta
          name="twitter:description"
          content={
            i18n.language === "he"
              ? searchQuery
                ? `תוצאות החיפוש עבור "${searchQuery}" באתר נומיסנסט. בדקו פריטי אספנות ייחודיים.`
                : "נומיסנסט - הזירה הישראלית למכירות מטבעות, שטרות ופריטי אספנות."
              : searchQuery
                ? `Search results for "${searchQuery}" on Numisnest. Find rare coins, banknotes, and collectibles.`
                : "Numisnest - Israel's top platform for coin, banknote, and collectible auctions."
          }
        />

        {/* ========== Keywords ========== */}
        <meta
          name="keywords"
          content={
            i18n.language === "he"
              ? "נומיסנסט, מכירות פומביות, מטבעות נדירים, שטרות, אספנות, אספנים, מטבעות ישראליים"
              : "Numisnest, auctions, rare coins, banknotes, collectibles, Israel auctions"
          }
        />

        {/* ========== JSON-LD Structured Data ========== */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name:
              i18n.language === "he"
                ? searchQuery
                  ? `חיפוש: ${searchQuery} - נומיסנסט`
                  : "נומיסנסט | זירת מכירות פומביות למטבעות, שטרות ואספנות"
                : searchQuery
                  ? `Search: ${searchQuery} - Numisnest`
                  : "Numisnest | Leading Auction Platform for Coins & Banknotes in Israel",
            description:
              i18n.language === "he"
                ? searchQuery
                  ? `תוצאות החיפוש עבור "${searchQuery}" באתר נומיסנסט. גלו מבחר מטבעות, שטרות ופריטי אספנות נדירים.`
                  : "נומיסנסט - זירת מכירות פומביות בישראל למטבעות, שטרות ואספנות ייחודיים."
                : searchQuery
                  ? `Search results for "${searchQuery}" on Numisnest. Explore rare coins, banknotes, and collectible items.`
                  : "Numisnest - Israel's top destination for coin and collectible auctions.",
            url: `${window.location.origin}/items`,
            publisher: {
              "@type": "Organization",
              name: "Numisnest",
              logo: {
                "@type": "ImageObject",
                url: `${window.location.origin}/assets/logo.png`,
              },
            },
            inLanguage: i18n.language === "he" ? "he-IL" : "en-US",
            image: `${window.location.origin}/assets/og-items-he.jpg`,
            potentialAction: {
              "@type": "SearchAction",
              target: `${window.location.origin}/items?q={search_term}`,
              "query-input": "required name=search_term",
            },
            breadcrumb: {
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: i18n.language === "he" ? "עמוד הבית" : "Home",
                  item: `${window.location.origin}/`,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: i18n.language === "he" ? "כל הפריטים" : "All Items",
                  item: `${window.location.origin}/items`,
                },
              ],
            },
          })}
        </script>

        {/* ========== Canonical & Robots ========== */}
        <link
          rel="canonical"
          href={`${window.location.origin}/items`}
        />
        <meta
          name="robots"
          content="index, follow"
        />
      </Helmet>

      <Header />
      <h1 className="collectibles-numismatics-coins-banknotes">
        {
          "Numisnest - Numismatics, Coins, Banknotes worldwide collector's site "
        }
      </h1>
      <main>
        <div className="py-8 md:py-4 bg-primary2">
          <div className="mx-auto max-w-[1560px] lg:min-h-[90vh] px-1 w-full">
            <div className="px-6">
              <p className="text-xl md:text-3xl font-bold mb-3 text-center">
                {!hasSeller ? t("All Items") : "User's Items"}
              </p>
              <Filters
                selectedCurrency={currency}
                setFilteredItems={setFilteredItems}
                usedCategories={usedCategories}
                items={items}
                setSearchInProgress={setSearchInProgress}
                searchInProgress={searchInProgress}
                filteredItems={filteredItems}
                search={search}
                setSearch={setSearch}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                years={years}
                isLoading={loading}
                setYears={setYears}
                prices={prices}
                setPrices={setPrices}
                urlParamsLoaded={urlParamsLoaded}
                exchangeRates={exchangeRates}
                setLoading={setLoading}
                setItemsOffset={safeSetItemOffset}
                convertedPrices={items.map(i => ({
                  id: i?._id,
                  convertedPrice: convert(i.price, i.currency, currency),
                  currency: i.selectedCurrency || currency,
                }))}
                setCurrencyChanged={setCurrencyChanged}
                currencyChanged={currencyChanged}
              />
            </div>
            {loading || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : (
              <div className="grid gap-2 max-md:min-h-[40vh] md:min-h-[65vh] md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {filteredItems
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <Item
                      key={item?._id || idx}
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      isCountry={item.isCountry}
                      isSeller={false}
                      name={item.name}
                      uid={item?.uid?._id}
                      user_data={item.uid}
                      description={item.description}
                      country={item.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={userCurrency}
                      price={convert(item.price, item.currency, userCurrency)}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.updatedAt}
                      onPinToggle={handlePinToggle}
                      setCurrencyChanged={setCurrencyChanged}
                      currencyChanged={currencyChanged}
                      onItemClick={handleItemClick}
                    />
                  ))}
              </div>
            )}
          </div>
          <br />

          <div dir="ltr">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              forcePage={currentPage}
              previousLabel={"<"}
              nextLabel={">"}
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Items;
