import React, { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { cld, extractPublicIdFromUrl } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IBasketSubItem } from "../../hooks/useSellerBasket";
import { useCurrency } from "../../context/currency";
import Button from "../../common/Button";
import { handlePrice } from "../../utils/handlePrice";
import LocalizedLink from "../../utils/LocalizedLink";

interface SellerBasketItemsProps {
  items: IBasketSubItem[];
  removingItemId: string | null;
  onRemove: (id: string) => void;
  onMarkPaid: (ids: string[]) => void;
  markPaidMode: boolean;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const SellerBasketItems: React.FC<SellerBasketItemsProps> = ({
  items,
  removingItemId,
  onRemove,
  onMarkPaid,
  markPaidMode,
  selectedItems,
  setSelectedItems,
}) => {
  const { t } = useTranslation();
  const { convert, userCurrency } = useCurrency();

  const unpaidItems = items.filter(bi => !bi.paid);
  const paidItems = items.filter(bi => bi.paid);

  const sortedUnpaidItems = [...unpaidItems].sort((a, b) => {
    if (a.deletedBySeller && !b.deletedBySeller) return 1;
    if (!a.deletedBySeller && b.deletedBySeller) return -1;
    return 0;
  });

  const nonDeletedUnpaidItems = sortedUnpaidItems.filter(
    bi => !bi.deletedBySeller,
  );
  const allUnpaidSelected =
    markPaidMode &&
    nonDeletedUnpaidItems.length > 0 &&
    nonDeletedUnpaidItems.every(bi => selectedItems.includes(bi.item._id));

  useEffect(() => {
    if (!markPaidMode) {
      setSelectedItems([]);
    }
  }, [markPaidMode, setSelectedItems]);

  const handleCheckboxChange = (id: string) => {
    setSelectedItems(prev =>
      prev.includes(id) ? prev.filter(itemId => itemId !== id) : [...prev, id],
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allIds = nonDeletedUnpaidItems.map(bi => bi.item._id);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const renderItem = (
    bi: IBasketSubItem,
    idx: number,
    showCheckbox = false,
    isLast: boolean = false,
  ) => {
    const { item, deletedBySeller } = bi;
    const borderClass = bi.paid
      ? isLast
        ? "border-b-2 border-gray-800"
        : "border-b border-gray-300"
      : "border-b";
    return (
      <li
        key={item._id}
        className={`flex flex-wrap items-center pb-3 gap-4 ${borderClass}`}
      >
        {showCheckbox && (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id={`markPaid-${item._id}`}
              checked={selectedItems.includes(item._id)}
              onChange={() => handleCheckboxChange(item._id)}
              className="h-5 w-5"
              disabled={!!deletedBySeller}
            />
          </div>
        )}
        <LocalizedLink to={`/item/${item._id}`}>
          {item.photos?.[0]?.url && (
            <AdvancedImage
              cldImg={cld
                .image(extractPublicIdFromUrl(item.photos[0].url))
                .resize(scale().width(100))
                .format("avif")}
              plugins={[lazyload()]}
              className="w-16 h-16 object-cover rounded"
              alt={item.name}
            />
          )}
        </LocalizedLink>
        <div className="flex-1">
          <p
            className="font-medium text-lg"
            style={
              deletedBySeller
                ? { textDecoration: "line-through", color: "red" }
                : {}
            }
            dir="auto"
          >
            {idx + 1}. {item.name}
          </p>
          <p
            className="text-gray-700 text-sm mt-1"
            style={
              deletedBySeller
                ? { textDecoration: "line-through", color: "red" }
                : {}
            }
          >
            {t("Price")}:{" "}
            <strong>
              {convert(item.price, item.currency, userCurrency)}{" "}
              {t(userCurrency)}
            </strong>
          </p>
        </div>
        {!bi.paid && !deletedBySeller && (
          <button
            onClick={() => onRemove(item._id)}
            disabled={removingItemId === item._id}
            className="text-red-500 hover:text-red-700"
            aria-label={t("Remove item")}
          >
            <FaTrash />
          </button>
        )}
      </li>
    );
  };

  const groupedPaidItems = paidItems.reduce<Record<string, IBasketSubItem[]>>(
    (groups, bi) => {
      const dateKey = bi.paidOn
        ? new Date(bi.paidOn).toLocaleDateString()
        : "Unknown Date";
      if (!groups[dateKey]) groups[dateKey] = [];
      groups[dateKey].push(bi);
      return groups;
    },
    {},
  );

  const sortedGroupKeys = Object.keys(groupedPaidItems).sort((a, b) => {
    if (a === "Unknown Date") return 1;
    if (b === "Unknown Date") return -1;
    return new Date(b).getTime() - new Date(a).getTime();
  });

  return (
    <div>
      {/* UNPAID ITEMS */}
      {sortedUnpaidItems.length > 0 && (
        <section className="mb-8">
          {markPaidMode && (
            <div className="flex flex-col gap-2 mb-2">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="h-5 w-5"
                  checked={allUnpaidSelected}
                  onChange={handleSelectAll}
                />
                <label className="cursor-pointer font-medium">
                  {t("Select all")}
                </label>
              </div>
            </div>
          )}
          <ul className="space-y-3">
            {sortedUnpaidItems.map((bi, idx) =>
              renderItem(bi, idx, markPaidMode),
            )}
          </ul>
        </section>
      )}

      {/* PAID ITEMS (grouped by paid date) */}
      {paidItems.length > 0 && (
        <section className="mb-8">
          <h2 className="text-xl font-bold text-green-600">
            {t("Paid")} ({paidItems.length})
          </h2>
          {sortedGroupKeys.map(date => {
            const groupItems = groupedPaidItems[date];
            const groupTotal = groupItems.reduce((acc, bi) => {
              return (
                acc + convert(bi.item.price, bi.item.currency, userCurrency)
              );
            }, 0);
            return (
              <div
                key={date}
                className="mb-4"
              >
                <h3 className="text-lg font-medium mb-2">
                  {t("Paid on")} {date}
                </h3>
                <div className="mb-2 text-right font-bold">
                  {t("Paid")}: {handlePrice(groupTotal)} {t(userCurrency)}
                </div>
                <ul className="space-y-3">
                  {groupItems.map((bi, idx) => {
                    const isLast = idx === groupItems.length - 1;
                    return renderItem(bi, idx, false, isLast);
                  })}
                </ul>
              </div>
            );
          })}
        </section>
      )}
    </div>
  );
};

export default SellerBasketItems;
