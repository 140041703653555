// src/pages/AllOrdersPage.tsx
import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Api from "../api/api";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { useAuth } from "../context/auth";
import OrdersBuyerGroupTiles, {
  IOrdersBuyerGroup,
  IOrderBuyer,
  IOrderItem,
} from "../components/OrdersBuyerGroupTiles";

interface IOrder {
  _id: string;
  buyer: IOrderBuyer;
  items: Array<{
    item: {
      _id: string;
      name: string;
      price: number;
      currency: string;
      photos?: Array<{ url: string }>;
    };
    paid?: boolean;
    paidOn?: string | null;
    deletedBySeller?: boolean;
    addedAt?: string;
  }>;
  sentAt?: Date | null;
  paidAll?: boolean;
}

const AllOrdersPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, authLoading } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [ordersEmpty, setOrdersEmpty] = useState(false);
  const [buyerGroups, setBuyerGroups] = useState<IOrdersBuyerGroup[]>([]);

  useEffect(() => {
    if (!authLoading && user) {
      fetchAllOrders();
    }
  }, [authLoading, user]);

  async function fetchAllOrders() {
    setLoading(true);
    setError(null);
    setOrdersEmpty(false);

    try {
      const response = await Api.getAllOrdersForSeller();
      const data: IOrder[] = response?.data?.data;

      if (!data || data.length === 0) {
        setOrdersEmpty(true);
        return;
      }

      // Group orders by buyer.
      const grouped: Record<
        string,
        { buyer: IOrderBuyer; items: IOrderItem[]; paidAll?: boolean }
      > = {};

      data.forEach(order => {
        const { buyer, items } = order;
        if (!buyer || !buyer._id) return;

        // Filter out sub-items that are either paid or deleted by the seller.
        const validSubItems = (items || []).filter(
          subItem => !subItem.deletedBySeller && !subItem.paid,
        );

        // Compute paidAll: if there are no unpaid items, then this order is fully paid.
        const computedPaidAll = validSubItems.length === 0;

        // Transform each valid sub-item to the minimal IOrderItem shape.
        const flatItems: IOrderItem[] = validSubItems.map(subItem => {
          const itm = subItem.item || {};
          return {
            _id: itm._id,
            name: itm.name,
          };
        });

        if (!grouped[buyer._id]) {
          grouped[buyer._id] = { buyer, items: [] };
        }
        grouped[buyer._id].items.push(...flatItems);

        // Overall buyer group is fully paid only if every order is fully paid.
        if (grouped[buyer._id].paidAll === undefined) {
          grouped[buyer._id].paidAll = computedPaidAll;
        } else {
          grouped[buyer._id].paidAll =
            grouped[buyer._id].paidAll && computedPaidAll;
        }
      });

      // Filter out buyer groups that have no valid items.
      const finalGroups = Object.values(grouped).filter(
        group => group.items.length > 0 || group.paidAll,
      );
      setBuyerGroups(finalGroups);
    } catch (err: any) {
      setError(err.message || "Error loading orders");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header />
      <main className="max-w-[1260px] mx-auto p-4">
        {authLoading ? (
          <div className="flex justify-center items-center h-screen">
            <FaSpinner className="animate-spin text-3xl text-primary" />
          </div>
        ) : !user ? (
          <div className="flex justify-center items-center h-screen">
            <p className="text-xl text-gray-600">
              {t("User not authenticated")}
            </p>
          </div>
        ) : loading ? (
          <div className="flex justify-center items-center h-screen">
            <FaSpinner className="animate-spin text-3xl text-primary" />
          </div>
        ) : error ? (
          <div className="p-4 text-center text-red-500">{error}</div>
        ) : ordersEmpty ? (
          <div className="p-4 text-center">{t("No orders found")}</div>
        ) : (
          <>
            <h1 className="text-2xl font-bold mb-4">{t("Received Orders")}</h1>
            <OrdersBuyerGroupTiles
              buyerGroups={buyerGroups}
              getViewLink={(buyer: IOrderBuyer) =>
                `/orders/received/${buyer._id}`
              }
              onViewClick={(buyerId: string) => {
                history.push(`/orders/received/${buyerId}`);
              }}
            />
          </>
        )}
      </main>
      <Footer />
    </>
  );
};

export default AllOrdersPage;
