import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import Api from "../api/api";
import Dots from "../assets/dots.svg";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Search from "../components/Search";
import Seller from "../components/Sellers/Seller";

import { countries } from "../utils/constant";
const Sellers: React.FC = () => {
  const [sellers, setSellers] = useState<[]>([]);
  const [itemOffset, setItemOffset] = useState(0);

  const { t } = useTranslation();
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState<string | undefined>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("everywhere");
  const [filteredSellers, setFilteredSellers] = useState<[]>([]);
  const history = useHistory();
  const perPage = 32;

  const handlePageClick = (event: any) => {
    setItemOffset(event.selected);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };
  async function getAllUsers() {
    const res: any = await Api.getUsers(
      "seller",
      search,
      itemOffset + 1,
      perPage,
    );
    if (res.status == 200) {
      const fetchedUsers = res?.data?.data?.users;
      setSellers(fetchedUsers);
      setFilteredSellers(res?.data?.data?.users);
      setPageCount(res?.data?.data?.totalPages);
    }
  }
  const sellerCountries = Array.from(
    new Set(sellers.map((seller: any) => seller.country)),
  ).filter(Boolean);
  const availableCountries = countries.filter(country =>
    sellerCountries.includes(country.value),
  );
  const onSearchClear = () => {
    setSearch("");
  };
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  useEffect(() => {
    getAllUsers();
  }, [search, itemOffset]);
  useEffect(() => {
    let filtered: any = sellers;

    if (selectedCountry !== "everywhere") {
      filtered = filtered.filter(
        (seller: any) => seller.country && seller.country === selectedCountry,
      );
    }

    if (search) {
      filtered = filtered.filter(
        (seller: any) =>
          seller.fname.toLowerCase().includes(search.toLowerCase()) ||
          seller.lname.toLowerCase().includes(search.toLowerCase()) ||
          seller.email.toLowerCase().includes(search.toLowerCase()) ||
          seller.phone.toLowerCase().includes(search.toLowerCase()),
      );
    }
    setFilteredSellers(filtered);
    // setItemOffset(0);
  }, [selectedCountry, sellers]);
  return (
    <>
      <Helmet>
        {/* **Dynamic SEO Title** */}
        <title>{`כל המוכרים | נומיסנסט - זירת אספנות ומכירות פומביות`}</title>

        {/* **Meta Description** */}
        <meta
          name="description"
          content="גלו את כל המוכרים המובילים שלנו בנומיסנסט, זירת האספנות והמכירות הפומביות המובילה בישראל. התחברו לאספנים אמינים, עיינו בפריטים נדירים, והשתתפו במכירות פומביות."
        />

        {/* **Open Graph Tags for Social Media** */}
        <meta
          property="og:title"
          content="כל המוכרים | נומיסנסט - זירת אספנות ומכירות פומביות"
        />
        <meta
          property="og:description"
          content="גלו את כל המוכרים המובילים שלנו בנומיסנסט, זירת האספנות והמכירות הפומביות המובילה בישראל. התחברו לאספנים אמינים, עיינו בפריטים נדירים, והשתתפו במכירות פומביות."
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/sellers`}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo2.png`}
        />
        <meta
          property="og:locale"
          content="he_IL"
        />
        <meta
          property="og:locale:alternate"
          content="en_US"
        />

        {/* **Twitter Tags** */}
        <meta
          name="twitter:title"
          content="כל המוכרים | נומיסנסט - זירת אספנות ומכירות פומביות"
        />
        <meta
          name="twitter:description"
          content="גלו את כל המוכרים המובילים שלנו בנומיסנסט, זירת האספנות והמכירות הפומביות המובילה בישראל. התחברו לאספנים אמינים, עיינו בפריטים נדירים, והשתתפו במכירות פומביות."
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/logo2.png`}
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />

        {/* **Keywords Meta Tag** */}
        <meta
          name="keywords"
          content="נומיסנסט, מוכרים, אספנות, מכירות פומביות, מטבעות נדירים, שטרות, אספנים, פריטי אספנות, זירת אספנות, אספני מטבעות, שטרות נדירים"
        />

        {/* **Structured Data (JSON-LD) for SEO** */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "כל המוכרים | נומיסנסט - זירת אספנות ומכירות פומביות",
            description:
              "גלו את כל המוכרים המובילים שלנו בנומיסנסט, זירת האספנות והמכירות הפומביות המובילה בישראל. התחברו לאספנים אמינים, עיינו בפריטים נדירים, והשתתפו במכירות פומביות.",
            url: `${window.location.origin}/sellers`,
            publisher: {
              "@type": "Organization",
              name: "נומיסנסט",
              logo: {
                "@type": "ImageObject",
                url: `${window.location.origin}/assets/logo.png`, // Replace with your actual logo path
              },
            },
            potentialAction: {
              "@type": "SearchAction",
              target: `${window.location.origin}/sellers?search={search_term}`,
              "query-input": "required name=search_term",
            },
            image: `${window.location.origin}/assets/og-sellers.jpg`, // Replace with your actual image path
            inLanguage: "he-IL",
          })}
        </script>

        {/* **Canonical URL** */}
        <link
          rel="canonical"
          href={`${window.location.origin}/sellers`}
        />

        {/* **Robots Meta Tag** */}
        <meta
          name="robots"
          content="index, follow"
        />
      </Helmet>

      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full lg:min-h-[90vh]">
            <div className="relative flex items-center justify-end gap-2 mb-2">
              <span className="font-medium">{t("Showing sellers from")}</span>
              <select
                className="rounded-md outline-none border border-black px-3 py-2 text-sm"
                value={selectedCountry}
                onChange={e => setSelectedCountry(e.target.value)}
              >
                <option value="everywhere">{t("Everywhere")}</option>
                {availableCountries.map(country => (
                  <option
                    key={country.value}
                    value={country.value}
                  >
                    {country.label}
                  </option>
                ))}
              </select>
              <img
                src={Dots}
                alt="dots"
                className="max-md:hidden absolute right-0 top-12 -z-10"
              />
            </div>
            <p className="text-2xl md:text-3xl font-bold mb-3">
              {t("All Sellers")}
            </p>
            <Search
              value={search}
              onChange={e => setSearch(e.target.value)}
              onClear={onSearchClear}
            />
            <br />
            <br />
            <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {filteredSellers?.map((itm, idx) => (
                <Seller
                  key={idx}
                  itm={itm}
                />
              ))}
            </div>
          </div>
          <br />
          <div dir="ltr">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              previousLabel={"<"}
              nextLabel={">"}
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Sellers;
