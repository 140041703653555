// src/i18n/index.ts

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
const savedLanguage = Cookies.get("language") || "en";
// Initialize i18next
i18n
  .use(HttpApi) // Load translation files via HTTP
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    lng: savedLanguage,
    supportedLngs: ["en", "he"], // Add other languages as needed
    fallbackLng: "en",
    debug: false, // Set to true for debugging

    interpolation: {
      escapeValue: false, // React already protects from XSS
    },

    backend: {
      addPath: `${process.env.REACT_APP_API_URL}/api/v1/missing-key/{{lng}}/{{ns}}`,
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      //loadPath: `${process.env.REACT_APP_API_URL}/api/v1/translation/{{lng}}/{{ns}}`, // Adjust as needed
    },

    detection: {
      // Override default detection settings
      order: ["querystring", "cookie"],
      caches: ["cookie"],
    },
    saveMissing: false,

    react: {
      useSuspense: false, // Set to true if using Suspense
    },
    //missingKeyHandler: (lng, ns, key, res) => key,
  });

export default i18n;
