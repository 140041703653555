import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaBarsStaggered, FaCoins, FaLocationDot } from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { RiAuctionFill } from "react-icons/ri";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../assets/logo.avif";
import mobile_logo from "../assets/mobile_logo.avif";
import { useAuth } from "../context/auth";
import { useCurrency } from "../context/currency";
import { LanguageContext } from "../context/language";
import { useLocationContext } from "../context/location";
import { LINKS, REACT_APP_KEY_NAME } from "../utils/constant";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";
import LocalizedLink from "../utils/LocalizedLink";
const Header: React.FC = () => {
  const location = useLocation();
  const { user, authLoading } = useAuth();
  const [tooltipVisible, setTooltipVisible] = useState(
    () => sessionStorage.getItem("tooltipVisible") !== "false",
  );
  const { t } = useTranslation();

  const { language, changeLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return Cookies.get("i18next") || "en"; // Default to English if no cookie is set
  });
  const handleToggleLanguage = () => {
    // Assume that the first segment of the pathname is the language param.
    const segments = location.pathname.split("/");
    const currentLang = segments[1] || "en"; // default to "en"
    const newLang = currentLang === "en" ? "he" : "en";
    // Update language in context and cookie
    changeLanguage(newLang);
    // Replace the language part in the URL
    segments[1] = newLang;
    const newPath = segments.join("/") || `/${newLang}`;
    history.push(newPath);
  };
  const languageToggleLabel = language === "en" ? "עברית" : "English";
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { pathname } = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();
  const activeLink = (path: string) => {
    if (location.pathname === path) return "text-primary font-medium";
    return "text-main";
  };
  const isHomePage = location.pathname === "/";
  const displayedLogo = isMobile ? mobile_logo : logo;
  const handleLogout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    history.push("/");
    window.location.reload();
  };

  const {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    updateUserLocation,
  } = useLocationContext();

  const [currencyChanged, setCurrencyChanged] = useState(false);
  const { userCurrency, updateUserCurrency } = useCurrency();

  const handleCurrencyChange = (newCurrency: string) => {
    updateUserCurrency(newCurrency);
  };
  const handleLocationChange = (newLocation: string) => {
    updateUserLocation(newLocation);
  };
  useEffect(() => {
    setToggleSidebar(false);
    // @ts-expect-error ts-ignore
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);
  useEffect(() => {
    if (!isHomePage && tooltipVisible) {
      setTooltipVisible(false);
      sessionStorage.setItem("tooltipVisible", "false"); // Disable tooltip for future visits
    }
  }, [isHomePage, tooltipVisible]);
  const handleLanguageChange = (languageLocal: string) => {
    changeLanguage(languageLocal);
  };
  return (
    <header className="bg-white">
      <div
        style={{ direction: window.innerWidth < 0 ? "ltr" : "inherit" }}
        className="max-w-[1567px]  mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-1 md:py-2 relative z-20"
      >
        <Sidebar
          user={user}
          handleLogout={handleLogout}
          toggle={toggleSidebar}
          setToggle={setToggleSidebar}
          activeLink={activeLink}
        />
        <LocalizedLink to="/">
          <Helmet>
            <link
              rel="preload"
              as="image"
              href={displayedLogo}
              fetchPriority="high"
            />
          </Helmet>
          <img
            className="w-[175px] md:w-[200px]"
            style={{
              display:
                window.innerWidth > 1023 && window.innerWidth < 1190
                  ? "none"
                  : "block",
            }}
            src={displayedLogo}
            fetchPriority="high"
            alt="Numisnest Logo"
          />
        </LocalizedLink>
        {/* Language Selector */}

        <div className="flex items-center md:hidden mr-auto ltr:justify-end rtl:justify-start gap-2">
          <IoLanguage
            size={22}
            fill="var(--primary)"
          />
          <span
            onClick={handleToggleLanguage}
            className="cursor-pointer text-primary text-lg hover:text-primary"
          >
            {languageToggleLabel}
          </span>
        </div>
        <FaBarsStaggered
          size={24}
          fill="var(--primary)"
          className="lg:hidden cursor-pointer max-w-[50% ltr:ml-auto rtl:mr-auto"
          onClick={() => [
            setToggleSidebar(true),
            // @ts-expect-error ts-ignore
            (document.querySelector("body").style.overflowY = "hidden"),
          ]}
        />
        <div className="flex items-center gap-1 whitespace-nowrap max-lg:hidden">
          {/* This will ensure the links are in a row */}
          <div className="flex gap-2">
            {LINKS?.map((itm, idx) => (
              <div
                className="relative"
                key={idx}
              >
                {/* Home Link with Dropdown */}
                {itm.path === "/" ? (
                  <div
                    onMouseEnter={() => setDropdownVisible(true)}
                    onMouseLeave={() => setDropdownVisible(false)}
                  >
                    <LocalizedLink
                      to={itm.path}
                      className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-2 xl:p-4 hover:transition ${activeLink(
                        itm.path,
                      )}`}
                    >
                      {t(itm.title)}
                    </LocalizedLink>

                    {dropdownVisible && (
                      <div className="absolute top-full left-0 bg-white shadow-lg border rounded-lg mt-1 z-50">
                        <LocalizedLink
                          to="/items"
                          className="block px-6 py-4 text-lg  text-main hover:bg-gray-100 hover:text-primary transition-all"
                        >
                          {t("Items")}
                        </LocalizedLink>
                        <LocalizedLink
                          to="/sellers"
                          className="block px-6 py-4 text-lg  text-main hover:bg-gray-100 hover:text-primary transition-all"
                        >
                          {t("Sellers")}
                        </LocalizedLink>
                      </div>
                    )}
                  </div>
                ) : (
                  <LocalizedLink
                    key={idx}
                    to={itm.path}
                    className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary  p-2 xl:p-4 hover:transition ${activeLink(
                      itm.path,
                    )}`}
                  >
                    {t(itm.title)}
                    {itm.path === "/auctions" && (
                      <RiAuctionFill
                        fill="var(--primary)"
                        className="inline-block ml-[0.1rem] mb-[0.1rem] text-primary"
                      />
                    )}
                  </LocalizedLink>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex max-lg:hidden items-center gap-2 xl:gap-6 ltr:ml-auto rtl:mr-auto">
          <div className="flex items-center gap-1 whitespace-nowrap max-lg:hidden"></div>
          {/* Location Select */}
          {!pathname.startsWith("/seller/") &&
            !pathname.startsWith("/collection/") &&
            !pathname.startsWith("/user-items/") &&
            !pathname.startsWith("/hidden/") && (
              <div className="flex hidden items-center gap-2">
                <FaLocationDot
                  size={22}
                  fill="var(--primary)"
                />
                <select
                  value={selectedLocation}
                  onChange={e => handleLocationChange(e.target.value)}
                  className="rounded-sm outline-none border border-primary px-3 py-1 text-sm md:max-w-[120px] lg:max-w-[140px]"
                >
                  <option value="">{t("Everywhere")}</option>
                  {locationOptions.map((country, idx) => (
                    <option
                      key={idx}
                      value={country.value}
                    >
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          {/* Currency Select */}
          <div className="max-lg:hidden flex items-center gap-2">
            <FaCoins
              size={22}
              fill="var(--primary)"
            />
            <select
              value={userCurrency}
              onChange={e => handleCurrencyChange(e.target.value)}
              className="rounded-sm outline-none border border-primary px-3 py-1 text-sm"
            >
              <option value="USD">{t("USD")}</option>
              <option value="EUR">{t("EUR")}</option>
              <option value="ILS">{t("ILS")}</option>
            </select>
          </div>
          <div className="flex gap-4 max-md:hidden relative">
            {!user ? (
              <>
                <LocalizedLink
                  to="/auth/login"
                  className="rounded-lg border hover:bg-secondary-light transition-colors border-primary text-primary bg-[#ffffff] whitespace-nowrap text-base text-center px-4 py-2"
                >
                  {t("Login")}
                </LocalizedLink>
                <LocalizedLink
                  to="/auth/signup"
                  className="rounded-lg border hover:bg-primary-dark transition-colors border-primary bg-primary text-[#ffffff]  whitespace-nowrap text-base text-center px-4 py-2"
                >
                  {t("Sign up")}
                </LocalizedLink>
                <div>
                  {!user && !authLoading && tooltipVisible && isHomePage && (
                    <div
                      style={{
                        width:
                          window.screen.width > 1080 &&
                          window.screen.width < 1400
                            ? "140px !important"
                            : "",
                      }}
                      className="absolute top-full md:min-w-[140px] bg-primary 
                       xl:min-w-[170px] 2xl:min-w-[180px] ltr:left-[40%] rtl:left-[58%] font-semibold
                        transform -translate-x-1/2 mt-2 text-white 
                         border-primary shadow-lg p-2 border rounded-lg 
                         md:text-sm lg:text-sm text-primary z-50"
                    >
                      {t(
                        `Any collectibles you want to sell? Sign up! It's completely free`,
                      )}
                      !
                      <button
                        onClick={() => setTooltipVisible(false)}
                        className="absolute top-0 ltr:right-0 rtl:left-0 mt-1 ltr:mr-1 rtl:ml-1 text-red-600"
                      >
                        <MdCancel style={{ fill: "white" }} />
                      </button>
                      <div className="tooltip-tail"></div>{" "}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={handleLogout}
                  className="cursor-pointer hover:bg-secondary-light transition-colors whitespace-nowrap rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
                >
                  {t("Log out")}
                </div>
                <LocalizedLink
                  to={`/seller/${user._id}`}
                  className="cursor-pointer hover:bg-primary-dark transition-colors rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center px-4 py-2"
                >
                  {t("Profile")}
                </LocalizedLink>
              </>
            )}
            <div className="flex items-center gap-2">
              <IoLanguage
                size={22}
                fill="var(--primary)"
              />
              <span
                onClick={handleToggleLanguage}
                className="cursor-pointer text-primary text-lg hover:text-primary"
              >
                {languageToggleLabel}
              </span>
            </div>
          </div>
        </div>
        {/* Language Selector */}
      </div>
    </header>
  );
};

export default Header;
