import Cookies from "js-cookie";
import { JwtPayload, jwtDecode } from "jwt-decode";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Api from "../api/api";
import { REACT_APP_KEY_NAME, ROLES } from "../utils/constant";
import { IUser } from "../types/auth";

interface IAuthContext {
  user: IUser | undefined;
  isAdmin: () => boolean | undefined;
  authLoading: boolean;
  /** Optionally: a method to re-fetch the profile if needed. */
  refetchProfile: () => Promise<void>;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IUser | undefined>();
  const [authLoading, setAuthLoading] = useState<boolean>(true); // Start "true" until we attempt to load

  const isAdmin = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    const decode_token: JwtPayload & { role?: number } = token
      ? jwtDecode(token)
      : {};
    if (user && ROLES.ADMIN === decode_token?.role) {
      Cookies.set("language", "en", { expires: 365, secure: true });
      return true;
    }
    return false;
  };

  // Function to get user profile from the server
  const getMyProfile = async () => {
    setAuthLoading(true);
    try {
      const res = await Api.getMeProfile();
      if (res.status === 200) {
        setUser(res.data.data);
      }
    } catch (error) {
      console.log("Failed to fetch profile:", error);
      // If request fails, user remains undefined, or you can do setUser(null)
    } finally {
      setAuthLoading(false);
    }
  };

  // Provide a manual way to re-fetch if needed
  const refetchProfile = async () => {
    if (Cookies.get(REACT_APP_KEY_NAME)) {
      await getMyProfile();
    }
  };

  useEffect(() => {
    // On first mount, if there's a token, fetch the user. Otherwise, we're done loading
    if (Cookies.get(REACT_APP_KEY_NAME)) {
      getMyProfile();
    } else {
      setAuthLoading(false);
    }
  }, []);

  const value = useMemo(
    () => ({
      user,
      isAdmin,
      authLoading,
      refetchProfile,
    }),
    [user, authLoading],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
