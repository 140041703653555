// src/components/OrdersBuyerGroupTiles.tsx
import React from "react";
import { Link } from "react-router-dom";
import avatarFallback from "../assets/avatar.avif";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";
import LocalizedLink from "../utils/LocalizedLink";

/** Minimal shape of each order's buyer. Extend as needed. */
export interface IOrderBuyer {
  _id: string;
  fname: string;
  lname: string;
  avatar?: string;
  email?: string;
  phone?: string;
}

/** Minimal shape of each item in a seller's received order. */
export interface IOrderItem {
  _id: string;
  name: string;
  // Optionally add price, currency, etc. if you like
}

/** A "buyer group" for a single buyer plus their items. */
export interface IOrdersBuyerGroup {
  buyer: IOrderBuyer;
  items: IOrderItem[];

  /**
   * If the entire group is fully paid, we show "Paid all"
   * (like the auction logic).
   */
  paidAll?: boolean;
}

/**
 * Props for OrdersBuyerGroupTiles
 * - buyerGroups: array of IOrdersBuyerGroup objects
 * - onViewClick?: callback if you want to handle "View" button logic
 * - getViewLink?: function returning a string link for the "View" button
 */
export interface OrdersBuyerGroupTilesProps {
  buyerGroups: IOrdersBuyerGroup[];
  onViewClick?: (buyerId: string) => void;
  getViewLink?: (buyer: IOrderBuyer) => string;
}

const OrdersBuyerGroupTiles: React.FC<OrdersBuyerGroupTilesProps> = ({
  buyerGroups,
  onViewClick,
  getViewLink,
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-4 relative sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {buyerGroups.map((group, idx) => {
        const { buyer, items, paidAll } = group;

        // Decide the link for the “View” button. Fallback to "#".
        const viewLink = getViewLink ? getViewLink(buyer) : "#";

        return (
          <div
            key={buyer?._id || idx}
            className="relative p-4 bg-white rounded-lg border shadow hover:shadow-md transition-shadow"
            aria-label={`Buyer ${buyer?.fname} ${buyer?.lname}`}
          >
            {/* Buyer info: avatar, name, email, phone */}
            <div className="flex gap-3 items-center mb-2">
              <img
                src={buyer?.avatar || avatarFallback}
                alt={`${buyer?.fname} ${buyer?.lname}`}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="flex flex-col">
                <span className="font-semibold text-gray-900 text-base">
                  {buyer?.fname} {buyer?.lname}
                </span>
                {buyer?.email && (
                  <a
                    href={`mailto:${buyer.email}`}
                    className="text-sm text-gray-600 hover:underline"
                  >
                    {buyer.email}
                  </a>
                )}
                {buyer?.phone && (
                  <span
                    dir="ltr"
                    className="text-sm text-gray-600 rtl:text-right"
                  >
                    {buyer.phone}
                  </span>
                )}
              </div>
            </div>

            {/* If fully paid => show “Paid All”; otherwise show items */}
            {paidAll ? (
              <div className="flex justify-center items-center h-[45%]">
                <p className="text-3xl font-medium text-green-600">
                  {t("Paid all")}
                </p>
              </div>
            ) : (
              <div className="text-sm text-gray-800 mt-2 space-y-1">
                <p className="font-medium">
                  {t("Items")} ({items.length}):
                </p>
                <ul className="list-disc ltr:ml-5 rtl:mr-5">
                  {items.slice(0, 3).map(itemObj => (
                    <li
                      key={itemObj._id}
                      className="truncate max-w-[80%]"
                    >
                      {itemObj.name}
                    </li>
                  ))}
                  {items.length > 3 && <li>{t("...and more")}</li>}
                </ul>
              </div>
            )}

            {/* “View” button. If onViewClick or getViewLink is provided */}
            <LocalizedLink
              to={viewLink}
              // If you have a tile-level click, you'd do stopPropagation here.
              onClick={() => {
                if (onViewClick) onViewClick(buyer._id);
              }}
            >
              <Button className="absolute bottom-4 ltr:right-4 rtl:left-4 !w-auto px-3 py-1">
                {t("View")}
              </Button>
            </LocalizedLink>
          </div>
        );
      })}
    </div>
  );
};

export default OrdersBuyerGroupTiles;
