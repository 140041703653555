// LanguageWrapper.tsx
import React, { useContext, useEffect } from "react";
import { useParams, Switch, Route, Redirect } from "react-router-dom";
import i18n from "./i18n";
import { ROUTES, AUTH_ROUTES, PROTECTED_ROUTES, ADMIN_ROUTES } from "./routes";
import Auth from "../authRoutes";
import Protected from "../protectedRoute";
import AdminProtected from "../adminRoute";
import MobileBottomNav from "../common/MobileButtonNav";
import { LanguageContext } from "../context/language";

interface RouteParams {
  lang: string;
}

const LanguageWrapper: React.FC = () => {
  const { lang } = useParams<RouteParams>();
  const { language, changeLanguage } = useContext(LanguageContext);
  useEffect(() => {
    // Set language if valid, otherwise default to "en"
    const newLang = lang === "he" || lang === "en" ? lang : "en";
    changeLanguage(newLang === "en" ? "en" : "he");
  }, [lang, language]);

  return (
    <>
      <Switch>
        {/* Render all public routes */}
        {ROUTES.map((route, index) => (
          <Route
            key={index}
            exact
            {...route}
          />
        ))}

        {/* Auth routes */}
        {AUTH_ROUTES.map((route, index) => (
          <Auth
            key={index}
            exact
            {...route}
          />
        ))}

        {/* Protected routes */}
        {PROTECTED_ROUTES.map((route, index) => (
          <Protected
            key={index}
            exact
            {...route}
          />
        ))}

        {/* Admin routes */}
        {ADMIN_ROUTES.map((route, index) => (
          <AdminProtected
            key={index}
            exact
            {...route}
          />
        ))}

        {/* If no route matches, fallback to /:lang */}
        <Route path="*">
          <Redirect to={`/${lang}`} />
        </Route>
      </Switch>
      <MobileBottomNav />
    </>
  );
};

export default LanguageWrapper;
