import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Button from "../common/Button";
import { useSellerBasket } from "../hooks/useSellerBasket";
import SellerBasketItems from "../components/Basket/SellerBasketItems";
import BasketTotals from "../components/Basket/BasketTotals";
import { useTranslation } from "react-i18next";
import Api from "../api/api";
import { toUserProfile } from "../utils/toUserProfile";
import BasketProfile from "../components/Basket/BasketProfile";
import { useAuth } from "../context/auth";
import MarkPaidActions from "../components/Basket/MarkPaidActions";
import SendSummaryActions from "../components/Basket/SendSummaryActions";
import BackButton from "../common/BackButton";
import LocalizedLink from "../utils/LocalizedLink";

const SellerBasketPage: React.FC = () => {
  const { id: buyerId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    basket,
    loading,
    error: LocalError,
    removingItemId,
    sellerDeleteItem,
  } = useSellerBasket(buyerId);
  const { user } = useAuth();

  const [markPaidMode, setMarkPaidMode] = useState(false);
  const [markingPaid, setMarkingPaid] = useState(false);
  const [localBasket, setLocalBasket] = useState(basket);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  useEffect(() => {
    if (basket && basket._id) {
      Api.markBasketViewed(basket._id);
    }
    setLocalBasket(basket);
  }, [basket]);

  useEffect(() => {
    if (LocalError) {
      toast.error(LocalError);
    }
  }, [LocalError]);

  const showProfile = basket?.buyer;
  const hidePaymentOption = true;
  const unpaidItemsCount = localBasket?.items.filter(
    bi => !bi.paid && !bi.deletedBySeller,
  ).length;

  const handleMarkPaid = async (itemIds: string[]) => {
    if (itemIds.length === 0) {
      setMarkPaidMode(false);
      return;
    }
    setMarkingPaid(true);
    try {
      const response = await Api.markItemsInBasketAsPaid({ buyerId, itemIds });
      if (response.status === 200) {
        toast.success(t("Items marked as paid"));
        if (localBasket) {
          const now = new Date().toISOString();
          const updatedItems = localBasket.items.map(bi =>
            itemIds.includes(bi.item._id) && !bi.paid
              ? { ...bi, paid: true, paidOn: now }
              : bi,
          );
          setLocalBasket({ ...localBasket, items: updatedItems });
        }
      } else {
        toast.error(t("Failed to mark items as paid"));
      }
    } catch (error) {
      console.error("Error marking items as paid:", error);
      toast.error(t("Failed to mark items as paid"));
    } finally {
      setMarkPaidMode(false);
      setMarkingPaid(false);
      setSelectedItemIds([]);
    }
  };

  // Only show the MarkPaidActions button if there are unpaid (non-deleted) items.
  const hasUnpaidNotDeleted = localBasket?.items.some(
    item => !item.paid && !item.deletedBySeller,
  );

  return (
    <>
      <Header />
      <main className="max-w-[1260px] mx-auto px-4 py-6 min-h-[60vh]">
        <BackButton
          label={"Back to orders"}
          to="/orders/received"
        />
        {loading ? (
          <div className="flex justify-center items-center h-[50vh]">
            <FaSpinner className="animate-spin text-3xl text-primary" />
          </div>
        ) : !localBasket ? (
          <div className="flex justify-center items-center h-[50vh]">
            <p className="text-xl text-gray-600">{t("Order not found")}</p>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex flex-wrap items-center justify-between border-b pb-4 mb-6 gap-4">
              {showProfile ? (
                <>
                  <BasketProfile
                    profile={{
                      ...toUserProfile(showProfile),
                      ...(hidePaymentOption ? { paymentOption: "" } : {}),
                    }}
                  />
                  <div>
                    <LocalizedLink to={`/seller/${buyerId}`}>
                      <Button className="!w-auto px-4 py-2 whitespace-nowrap">
                        {t("Visit profile")}
                      </Button>
                    </LocalizedLink>
                  </div>
                </>
              ) : (
                <p className="text-red-500">{t("Opposite user not found")}</p>
              )}
            </div>

            <div className="flex flex-wrap items-center justify-between mb-6">
              <div>
                <h2 className="text-xl font-semibold mb-2">
                  {t("Order total")}:
                </h2>
                <BasketTotals items={localBasket.items} />
              </div>
              <div className="flex gap-4">
                {hasUnpaidNotDeleted && (
                  <MarkPaidActions
                    markPaidMode={markPaidMode}
                    toggleMarkPaidMode={() => setMarkPaidMode(prev => !prev)}
                    confirmPay={() => handleMarkPaid(selectedItemIds)}
                    confirming={markingPaid}
                    confirmDisabled={false}
                  />
                )}
                {/* Render SendSummaryActions with the profile info */}
                {showProfile && (
                  <SendSummaryActions
                    profileId={showProfile._id}
                    profileName={`${showProfile.fname} ${showProfile.lname}`}
                  />
                )}
              </div>
            </div>

            {localBasket.items.length > 0 && (
              <section className="mb-8">
                <div className="flex flex-col gap-2 mb-2">
                  <h2 className="text-xl font-bold">
                    {t("Basket items")} ({unpaidItemsCount})
                  </h2>
                </div>
                <SellerBasketItems
                  items={localBasket.items}
                  onMarkPaid={handleMarkPaid}
                  removingItemId={removingItemId}
                  onRemove={sellerDeleteItem}
                  markPaidMode={markPaidMode}
                  selectedItems={selectedItemIds}
                  setSelectedItems={setSelectedItemIds}
                />
              </section>
            )}
          </div>
        )}
      </main>
      <Footer />
    </>
  );
};

export default SellerBasketPage;
