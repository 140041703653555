import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useTranslation } from "react-i18next";

// Optional: if you want dayjs to handle day names properly,
// add the localizedFormat plugin:
dayjs.extend(localizedFormat);

interface Props {
  endAt?: Date | string;
}

const AuctionEndTime: React.FC<Props> = ({ endAt }) => {
  const { t, i18n } = useTranslation();

  if (!endAt) return null;

  // Convert endAt to a Day.js object & localize:
  const dateObj = dayjs(endAt).locale(i18n.language);
  // e.g. "Saturday at 20:00"
  const formatted = dateObj.format("dddd HH:mm");

  return (
    <div className="flex items-center gap-2">
      {/* Calendar Icon in the same color */}
      <FaRegCalendarAlt fill={"var(--primary)"} />
      {/* “Auction will end on Saturday at 20:00” */}
      <span className="text-primary">
        {t("Auction will end on")} {formatted}
      </span>
    </div>
  );
};

export default AuctionEndTime;
