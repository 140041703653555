import { AdvancedImage } from "@cloudinary/react";
import {
  closestCorners,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import imageCompression from "browser-image-compression";
import React, { useEffect, useRef, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { RiImageAddLine, RiInformationLine } from "react-icons/ri";
import { cld, extractPublicIdFromUrl } from "../utils/helpers";

import { CSS } from "@dnd-kit/utilities";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MdVideoSettings } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import Api from "../api/api";
import { allKeywords } from "../utils/constant";
import ImageEditor from "./ImageEditor";

interface ImageUploaderProps {
  images: any;
  setImages: any;
  video: string;
  setVideo: React.Dispatch<React.SetStateAction<string>>;
}

const SortableImage = React.memo(
  ({
    url,
    index,
    id,
    handleRemoveImage,
    openImageEditor,
    totalImages,
    isButtonClicked,
    setIsButtonClicked,
    isLoading,
  }: any) => {
    const [isHolding, setIsHolding] = useState(false);
    const holdTimeoutRef = useRef<number | null>(null);

    const borderStyle =
      index === 0 ? "border-4 border-primary" : "border border-primary";
    const { t } = useTranslation();
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
      setActivatorNodeRef,
    } = useSortable({
      id,
      disabled: isButtonClicked || isHolding,
    });

    const wrapperStyle: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
      ...(index === 0
        ? {
            height:
              window.innerWidth < 408
                ? "6rem"
                : window.innerWidth < 760
                  ? "7rem"
                  : "14rem",
            width:
              window.innerWidth < 408
                ? "6rem"
                : window.innerWidth < 760
                  ? "7rem"
                  : "14rem",
          }
        : {
            width:
              window.innerWidth < 408
                ? "6rem"
                : window.innerWidth < 760
                  ? "7rem"
                  : "14rem",
            height:
              window.innerWidth < 408
                ? "6rem"
                : window.innerWidth < 760
                  ? "7rem"
                  : "14rem",
          }),
      opacity: isDragging ? 0 : 1,
      zIndex: isDragging ? 10000 : "auto",
      position: "relative",
    };

    const handleClick = React.useCallback(
      (e: React.MouseEvent | React.TouchEvent, action: () => void) => {
        e.stopPropagation();
        setIsButtonClicked(true);
        action();
      },
      [],
    );
    const handleButtonMouseDown = (e: React.MouseEvent) => {
      e.stopPropagation();

      setIsHolding(true);
    };

    const handleButtonMouseUp = (e: React.MouseEvent) => {
      e.stopPropagation();

      setIsHolding(false);
    };
    return (
      <div
        ref={setNodeRef}
        style={wrapperStyle}
        {...attributes}
        className={`relative rounded-lg p-2 overflow-hidden group ${borderStyle}`}
      >
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-gray-200">
            <ClipLoader
              size={30}
              color="#3498db"
            />
          </div>
        ) : (
          <>
            <AdvancedImage
              cldImg={cld.image(extractPublicIdFromUrl(url))}
              className="max-w-full max-h-full min-w-full min-h-full object-contain"
              alt={allKeywords}
            />

            <p className="absolute top-0.5 left-0.5 text-xs bg-white px-1 rounded">
              {index ? index + 1 : "Cover"}
            </p>

            <div
              ref={setActivatorNodeRef}
              {...listeners}
              className="absolute inset-0"
            >
              <button
                type="button"
                onClick={e => handleClick(e, () => openImageEditor(url, index))}
                onTouchStart={e =>
                  handleClick(e, () => openImageEditor(url, index))
                }
                onMouseEnter={() => setIsHolding(true)}
                onMouseDown={e => handleButtonMouseDown(e)}
                onMouseUp={e => handleButtonMouseUp(e)}
                onMouseLeave={handleButtonMouseUp}
                className="absolute top-1 right-10 text-white p-1 bg-blue-500 rounded hover:bg-blue-700 opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <FaPencilAlt />
              </button>
              <button
                type="button"
                onClick={e => handleClick(e, () => handleRemoveImage(index))}
                onTouchStart={e =>
                  handleClick(e, () => handleRemoveImage(index))
                }
                onMouseEnter={() => setIsHolding(true)}
                onMouseDown={e => handleButtonMouseDown(e)}
                onMouseUp={e => handleButtonMouseUp(e)}
                onMouseLeave={e => handleButtonMouseUp(e)}
                className="absolute top-1 right-2 text-white p-1 bg-red-500 rounded hover:bg-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <IoIosClose />
              </button>
            </div>
          </>
        )}
      </div>
    );
  },
);
SortableImage.displayName = "SortableImage";
const ImageUploader: React.FC<ImageUploaderProps> = ({
  images,
  setImages,
  video,
  setVideo,
}) => {
  const imgRef = useRef<HTMLInputElement>(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null,
  );
  const [uploadedPublicIds, setUploadedPublicIds] = useState<string[]>([]);

  const [isItemUpdated, setIsItemUpdated] = useState(false);
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const cloudinaryRef = useRef("");
  const widgetRef = useRef<any>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false); // Move isButtonClicked here
  const generateId = image => image?.publicId || `${image?.url}-${Date.now()}`;
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const { t } = useTranslation();
  const openImageEditor = (imgSrc: string, index: number) => {
    setSelectedImage(imgSrc);
    setSelectedImageIndex(index);
    setIsImageEditorOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 408);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const handleSaveEditedImage = async (editedImgBase64: string) => {
    if (selectedImageIndex !== null) {
      // Start loading spinner for the specific image being edited
      setImages(prevImages => {
        const updatedImages = [...prevImages];
        if (updatedImages[selectedImageIndex]) {
          updatedImages[selectedImageIndex].isLoading = true;
        }
        return updatedImages;
      });

      const imageToReplace = images[selectedImageIndex];
      setIsImageEditorOpen(false);
      try {
        const formData = new FormData();
        formData.append("file", editedImgBase64);
        formData.append(
          "upload_preset",
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET as string,
        );
        formData.append(
          "cloud_name",
          process.env.REACT_APP_CLOUDINARY_CLOUD_NAME as string,
        );

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          {
            method: "POST",
            body: formData,
          },
        );

        if (!response.ok) {
          throw new Error("Failed to upload edited image");
        }

        const data = await response.json();

        const newImage = {
          url: data.eager[0].secure_url,
          publicId: data?.public_id,
          width: data.width, // <-- ADD THIS
          height: data.height,
        };

        if (imageToReplace?.publicId) {
          await Api.deleteImage(
            imageToReplace?.publicId ||
              extractPublicIdFromUrl(imageToReplace?.url),
          );
        }

        setImages(prevImages =>
          prevImages?.map(image =>
            image.publicId === imageToReplace.publicId
              ? { ...newImage, isLoading: false }
              : image,
          ),
        );
        setIsButtonClicked(false);
        toast.success("Image edited and saved successfully");
      } catch (error) {
        console.error("Error uploading edited image to Cloudinary:", error);
        toast.error("Failed to save edited image. Please try again.");
        setImages(prevImages => {
          const updatedImages = [...prevImages];
          if (updatedImages[selectedImageIndex]) {
            updatedImages[selectedImageIndex].isLoading = false;
          }
          return updatedImages;
        });
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.cloudinary) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cloudinaryRef.current = window.cloudinary;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      widgetRef.current = cloudinaryRef.current.createUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
          resourceType: "video",
          sources: ["local", "url", "camera"],
          maxFiles: 1,
          maxVideoFileSize: 50 * 1024 * 1024,
          clientAllowedFormats: ["mp4", "mov", "webm", "ogv"],
        },
        (error, result) => {
          if (error) {
            toast.error("Video upload failed. Please try again.");
          } else if (result && result.event === "success") {
            const cloudinaryUrl = result.info.secure_url;
            setVideo(cloudinaryUrl); // Set the Cloudinary video URL correctly
            toast.success("Video uploaded successfully!");
          }
        },
      );
    }
  }, [setVideo]);
  const handleImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (images.length + files.length > 15) {
        toast.error("Only 15 images are allowed");
        return;
      }

      const newImages = Array.from(files)?.map((file, index) => ({
        file,
        isLoading: true,
        url: "",
        publicId: `${file.name}-${Date.now()}-${index}`, // Unique publicId for now
      }));

      // Keep track of the publicIds of the new images
      const newImagePublicIds = newImages?.map(img => img.publicId);

      // Update images state to reflect new images being uploaded
      setImages(prev => [...prev, ...newImages]);
      const failedUploads: string[] = [];
      try {
        const uploadedImages = await Promise.all(
          Array.from(files)?.map(async (file, idx) => {
            const compressedFile = await imageCompression(file, {
              maxSizeMB: 2,
              maxWidthOrHeight: 1980,
            });

            const formData = new FormData();
            formData.append("file", compressedFile);
            formData.append(
              "upload_preset",
              process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET as string,
            );
            formData.append(
              "cloud_name",
              process.env.REACT_APP_CLOUDINARY_CLOUD_NAME as string,
            );
            formData.append(
              "api_key",
              process.env.REACT_APP_CLOUDINARY_API_KEY as string,
            );

            const response = await fetch(
              `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
              {
                method: "POST",
                body: formData, // FormData automatically sets the appropriate headers
              },
            );

            if (!response.ok) {
              // If the upload fails, store the failed image's publicId
              failedUploads.push(newImagePublicIds[idx]);
              throw new Error("Failed to upload image");
            }
            const data = await response.json();

            return {
              url: data?.eager[0].secure_url,
              publicId: data?.public_id, // The actual publicId from Cloudinary
              isLoading: false,
            };
          }),
        );

        // Update images to mark only the uploaded images as not loading
        setImages(prev =>
          prev?.map(
            img =>
              newImagePublicIds.includes(img?.publicId)
                ? uploadedImages.shift() || img // Replace with the uploaded image details
                : img, // Keep existing images unchanged
          ),
        );
        setIsButtonClicked(false);
        toast.success("Images uploaded successfully!");
      } catch (error) {
        console.error("Error uploading to Cloudinary:", error);
        setImages(prev =>
          prev.filter(img => !failedUploads.includes(img?.publicId)),
        ),
          toast.error("Failed to upload images. Please try again.");
      }
    }
  };

  // Remove image from Cloudinary
  const handleRemoveImage = async (index: number) => {
    const imageToRemove = images[index];

    setImages(prev => prev.filter((_, idx) => idx !== index));
    setIsButtonClicked(false);

    try {
      if (imageToRemove?.publicId) {
        await Api.deleteImage(imageToRemove?.publicId);
        toast.success("Image removed successfully");
      }
    } catch (error) {
      console.error("Error removing image from Cloudinary:", error);
      toast.error("Failed to remove image from Cloudinary.");
    }
  };
  const handleRemoveVideo = () => {
    setVideo("");
    toast.success("Video removed successfully!");
  };
  const handleDragStart = (event: any) => {
    const { active } = event;

    const activeImg = images.find((img: any) => img?.publicId === active.id);

    if (activeImg) {
      setActiveImage(activeImg.url);
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) {
      setActiveImage(null);
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = images.findIndex(
        (img: any) => img?.publicId === active.id,
      );
      const newIndex = images.findIndex(
        (img: any) => img?.publicId === over.id,
      );

      const updatedImages = arrayMove(images, oldIndex, newIndex);
      setImages(updatedImages);
    }

    setActiveImage(null);
  };
  const sensors = useSensors(useSensor(PointerSensor, {}));

  const renderOverlayContent = () => {
    if (!activeImage) return null;

    return (
      <div
        style={{
          width:
            window.innerWidth < 408
              ? "6rem"
              : window.innerWidth < 760
                ? "7rem"
                : "14rem",
          height:
            window.innerWidth < 408
              ? "6rem"
              : window.innerWidth < 760
                ? "7rem"
                : "14rem",

          border: "2px solid blue",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <img
          src={activeImage}
          alt={allKeywords}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  };

  const tileStyles = {
    width:
      images.length === 0
        ? "100%" // Full width if there are no images
        : window.innerWidth < 408
          ? "6rem"
          : window.innerWidth < 760
            ? "7rem"
            : "14rem",

    height:
      images.length === 0
        ? "200px" // Fixed height if there are no images
        : window.innerWidth < 408
          ? "6rem"
          : window.innerWidth < 760
            ? "7rem"
            : "14rem",
  };
  const onDropHandler = async (e: React.DragEvent) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    const syntheticEvent = {
      target: {
        files: droppedFiles,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    await handleImages(syntheticEvent);
  };
  return (
    <>
      <DndContext
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={images.map(image => generateId(image))}
          strategy={rectSortingStrategy}
        >
          <div
            className={`grid gap-4  ${
              images?.length === 0
                ? "grid-cols-[70%_30%] w-full"
                : "grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5"
            }`}
            style={{ touchAction: "none" }}
          >
            {images?.map((img, index) => (
              <SortableImage
                key={generateId(img)}
                url={img?.url}
                id={generateId(img)}
                index={index}
                isLoading={img?.isLoading}
                handleRemoveImage={handleRemoveImage}
                openImageEditor={openImageEditor}
                totalImages={images?.length}
                isButtonClicked={isButtonClicked}
                setIsButtonClicked={setIsButtonClicked}
              />
            ))}
            {images?.length < 15 && (
              <label
                htmlFor="images"
                className={`rounded-2xl border-[2px] border-dashed border-black grid place-items-center cursor-pointer ${
                  images?.length === 0 ? "h-[200px] w-full" : ""
                }`}
                style={tileStyles}
                onDrop={onDropHandler}
                onDragOver={e => e.preventDefault()}
              >
                <div className="grid place-items-center">
                  <div className="size-12 bg-[#9CC6E240] grid place-items-center rounded-full">
                    <RiImageAddLine size={30} />
                  </div>
                  <p className="text-xs text-center font-medium mt-2">
                    {t("Add photos or drag and drop")}
                  </p>
                </div>
              </label>
            )}

            {!video && (
              <div className="relative">
                <label
                  className={`rounded-2xl   border-[2px] border-dashed border-black grid place-items-center cursor-pointer ${
                    images.length === 0 && !video
                      ? "h-[200px] w-[full]"
                      : " ml-1 "
                  }`}
                  style={tileStyles}
                  onClick={() => widgetRef.current?.open()}
                >
                  <div className="grid place-items-center">
                    <div className="size-12 bg-[#9CC6E240] grid place-items-center rounded-full">
                      <MdVideoSettings size={30} />
                    </div>
                    <p className="text-xs font-medium text-center mt-2">
                      {t("Upload videos")}
                    </p>
                  </div>
                  <div
                    className="absolute top-2 right-2"
                    onMouseEnter={() => setIsTooltipVisible(true)}
                    onMouseLeave={() => setIsTooltipVisible(false)}
                  >
                    <RiInformationLine
                      size={20}
                      className="text-gray-500 hover:text-gray-700 cursor-pointer"
                    />
                    {isTooltipVisible && (
                      <div
                        className="absolute right-0 bottom-full mb-2 bg-white text-black text-xs p-2 rounded shadow-lg"
                        style={{
                          width: "200px",
                          whiteSpace: "normal",
                          textAlign: "left",
                        }}
                      >
                        {t("Up to 60 seconds and no bigger than 50 MB")}
                      </div>
                    )}
                  </div>
                </label>
              </div>
            )}
            {video && (
              <div className="relative rounded-2xl border-[2px] border-black">
                <video
                  className="w-full h-[11.5rem] object-cover rounded-lg"
                  controls
                  src={video}
                />
                <button
                  type="button"
                  onClick={handleRemoveVideo}
                  className="absolute top-2 right-2 text-white p-1 bg-red-500 rounded hover:bg-red-700"
                >
                  <IoIosClose size={20} />
                </button>
              </div>
            )}
          </div>
        </SortableContext>

        <DragOverlay>{renderOverlayContent()}</DragOverlay>
      </DndContext>

      <input
        ref={imgRef}
        id="images"
        type="file"
        onChange={handleImages}
        className="hidden"
        accept="image/png, image/jpeg, image/webp, image/avif"
        multiple
      />

      {selectedImage && (
        <ImageEditor
          isOpen={isImageEditorOpen}
          imageSrc={selectedImage}
          onClose={() => {
            setIsImageEditorOpen(false), setIsButtonClicked(false);
          }}
          onSave={handleSaveEditedImage}
        />
      )}
    </>
  );
};

export default ImageUploader;
