// src/components/SellerGroupTiles.tsx
import React from "react";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar.jpg";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";
import LocalizedLink from "../utils/LocalizedLink";

export interface ISeller {
  _id: string;
  fname: string;
  lname: string;
  avatar?: string;
  email?: string;
  phone?: string;
}

export interface IGenericItem {
  _id: string;
  name: string;
}

export interface ISellerGroup<T extends IGenericItem> {
  seller: ISeller;
  items: T[];
  // Add more fields like totalSpent, currency, etc. if needed
}

export interface SellerGroupTilesProps<T extends IGenericItem> {
  sellerGroups: Array<ISellerGroup<T>>;
  /**
   * Called when the user clicks anywhere on the tile (except on the 'View' link).
   * Useful for scroll restoration, analytics, etc.
   */
  onComponentClick?: () => void;
  /**
   * Called when the user clicks the “View” button.
   */
  onViewClick?: (sellerId: string) => void;
  /**
   * A function to generate the “View” link for each seller.
   * Defaults to "#" if not provided.
   */
  getViewLink?: (seller: ISeller) => string;
}

function SellerGroupTiles<T extends IGenericItem>({
  sellerGroups,
  onComponentClick,
  onViewClick,
  getViewLink,
}: SellerGroupTilesProps<T>) {
  const { t } = useTranslation();

  return (
    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {sellerGroups.map((group, idx) => {
        const { seller, items } = group;
        // If getViewLink isn’t provided, use “#” as a fallback
        const viewLink = getViewLink ? getViewLink(seller) : "#";

        return (
          <div
            key={seller?._id || idx}
            className="relative p-4 bg-white rounded-lg border shadow hover:shadow-md transition-shadow"
            aria-label={`Seller: ${seller?.fname} ${seller?.lname}`}
            onClick={() => {
              // Fire the parent’s tile click if provided
              if (onComponentClick) onComponentClick();
            }}
          >
            {/* Seller Info */}
            <div className="flex gap-3 items-center mb-2">
              <img
                src={seller.avatar || avatar}
                alt={`${seller.fname} ${seller.lname}`}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div>
                <span className="font-semibold text-gray-900 text-base">
                  {seller.fname} {seller.lname}
                </span>
                {seller.email && (
                  <a
                    href={`mailto:${seller.email}`}
                    className="block text-sm text-gray-600 hover:underline"
                  >
                    {seller.email}
                  </a>
                )}
                {seller.phone && (
                  <span
                    className="block text-sm rtl:text-right text-gray-600"
                    dir="ltr"
                  >
                    {seller.phone}
                  </span>
                )}
              </div>
            </div>

            {/* Items */}
            <p className="text-sm text-gray-800 mt-2 font-medium space-y-1">
              {t("Items")}: {items.length}
            </p>
            <ul className="list-disc ltr:ml-5 rtl:mr-5 ltr:mr-1 rtl:ml-1 text-sm text-gray-800 mt-1">
              {items.slice(0, 3).map(item => (
                <li key={item._id}>
                  {" "}
                  <span
                    className="truncate block max-w-[80%]"
                    dir="auto"
                  >
                    {item.name}
                  </span>
                </li>
              ))}
              {items.length > 3 && <li>{t("...and more")}</li>}
            </ul>

            {/* "View" button */}
            <LocalizedLink
              to={viewLink}
              // Stop the tile-wide click from firing when the user clicks "View"
              onClick={e => e.stopPropagation()}
            >
              <Button
                onClick={() => {
                  if (onViewClick) onViewClick(seller._id);
                }}
                className="absolute bottom-4 ltr:right-4 rtl:left-4 !w-auto px-3 py-1"
              >
                {t("View")}
              </Button>
            </LocalizedLink>
          </div>
        );
      })}
    </div>
  );
}

export default SellerGroupTiles;
