import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaCoins, FaLocationDot } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useCurrency } from "../../context/currency";
import { useLocationContext } from "../../context/location";
import { ADMIN_LINKS, REACT_APP_KEY_NAME } from "../../utils/constant";
import LocalizedLink from "../../utils/LocalizedLink";

const AdminHeader: React.FC = () => {
  const location = useLocation();
  const { isAdmin, user } = useAuth();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { pathname } = useLocation();
  const activeLink = (path: string) => {
    if (location.pathname === path) return "text-primary font-medium";
    return "text-main";
  };

  const handleLogout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    window.location.reload();
  };

  const {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    updateUserLocation,
  } = useLocationContext();

  const [currencyChanged, setCurrencyChanged] = useState(false);
  const { userCurrency, updateUserCurrency } = useCurrency();
  const { selectedLocation: selectedLocations } = useLocationContext();
  const handleCurrencyChange = (newCurrency: string) => {
    updateUserCurrency(newCurrency);
  };
  const handleLocationChange = (newLocation: string) => {
    updateUserLocation(newLocation);
  };
  useEffect(() => {
    setToggleSidebar(false);
    // @ts-expect-error ts-ignore
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);
  if (!isAdmin) return null;
  return (
    <header className="bg-white">
      <div className="max-w-[1667px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-1 md:py-2 relative z-20">
        <div className="flex items-center gap-3 max-lg:hidden">
          <div>
            {ADMIN_LINKS?.map((itm, idx) => (
              <LocalizedLink
                key={idx}
                to={itm.path}
                className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(itm.path)}`}
              >
                {itm.title}
              </LocalizedLink>
            ))}
          </div>
        </div>

        <div className="flex max-md:hidden items-center gap-6 ml-auto">
          {/* Location Select */}
          {!pathname.startsWith("/seller/") &&
            !pathname.startsWith("/collection/") &&
            !pathname.startsWith("/user-items/") &&
            !pathname.startsWith("/hidden/") && (
              <div className="flex items-center gap-2">
                <FaLocationDot
                  size={22}
                  fill="var(--primary)"
                />
                <select
                  value={selectedLocation}
                  onChange={e => handleLocationChange(e.target.value)}
                  className="rounded-sm outline-none border border-primary px-3 py-1 text-sm md:max-w-[120px] lg:max-w-[140px]"
                >
                  <option value="">Everywhere</option>
                  {locationOptions.map((country, idx) => (
                    <option
                      key={idx}
                      value={country.value}
                    >
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          {/* Currency Select */}
          <div className="max-md:hidden flex items-center gap-2">
            <FaCoins
              size={22}
              fill="var(--primary)"
            />
            <select
              value={userCurrency}
              onChange={e => handleCurrencyChange(e.target.value)}
              className="rounded-sm outline-none border border-primary px-3 py-1 text-sm"
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="ILS">ILS</option>
            </select>
          </div>
          {!user ? (
            <>
              <LocalizedLink
                to="/auth/login"
                className="rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
              >
                Log in
              </LocalizedLink>
              <LocalizedLink
                to="/auth/signup"
                className="rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center px-4 py-2"
              >
                Sign up
              </LocalizedLink>
            </>
          ) : (
            <div
              onClick={handleLogout}
              className="cursor-pointer rounded-lg border border-primary text-primary bg-[#ffffff]  text-base text-center px-4 py-2"
            >
              Log out
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
