// src/pages/UnsubscribeFromNewsletterPage.tsx

import React from "react";
import { useHistory, useParams } from "react-router-dom";

// Example: If you use a toast system or i18n, bring them in:
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";

const UnsubscribeFromNewsletterPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth();
  // ^ For a route like "/unsubscribe-newsletter/:id",
  // or whichever route you define in your App routes

  const handleConfirmUnsubscribe = async () => {
    try {
      // Call your unsubscribe API
      await Api.unsubscribeFromNewsletter();
      toast.success(t("You have been unsubscribed from the newsletter."));
    } catch (error) {
      toast.error(t("Failed to unsubscribe. Please try again."));
      console.error("Unsubscribe error:", error);
    } finally {
      // Go back to the user profile (assuming /seller/:id)
      history.push(`/seller/${user?._id}`);
    }
  };

  const handleCancel = () => {
    // Simply redirect to user profile without unsubscribing
    history.push(`/seller/${user?._id}`);
  };

  return (
    <>
      <Header />
      <main className="min-h-[80vh] flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-md max-w-md w-full p-6">
          <h1 className="text-xl font-bold mb-4">
            {t("Unsubscribe from newsletter")}
          </h1>
          <p className="mb-6">
            {t("Are you sure you want to unsubscribe from the newsletter?")}
          </p>
          <div className="flex items-center justify-end gap-4">
            <button
              onClick={handleCancel}
              className="px-4 py-2 rounded-md border border-primary text-primary 
                         hover:bg-gray-100 transition-colors"
            >
              {t("No")}
            </button>
            <button
              onClick={handleConfirmUnsubscribe}
              className="px-4 py-2 rounded-md bg-red-500 text-white 
                         hover:bg-red-600 transition-colors"
            >
              {t("Yes")}
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default UnsubscribeFromNewsletterPage;
