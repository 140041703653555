import React from "react";
import { useTranslation } from "react-i18next";

interface IUserProfile {
  _id: string;
  fname: string;
  lname: string;
  avatar?: string;
  email?: string;
  phone?: string;
  paymentOption?: string;
}

interface BasketProfileProps {
  profile: IUserProfile;
  hidePaymentOption?: boolean;
}

const BasketProfile: React.FC<BasketProfileProps> = ({
  profile,
  hidePaymentOption = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-4">
      <img
        src={profile.avatar || "/default-avatar.png"}
        alt={`${profile.fname} ${profile.lname}`}
        className="w-16 h-16 rounded-full object-cover"
      />
      <div>
        <h1 className="text-2xl font-bold">
          {profile.fname} {profile.lname}
        </h1>
        {profile.phone && (
          <p className="text-gray-700">
            {t("Phone")}:{" "}
            <a
              dir="ltr"
              href={`tel:${profile.phone}`}
              className="text-blue-600 hover:underline"
            >
              {profile.phone}
            </a>
          </p>
        )}
        {profile.email && (
          <p className="text-gray-700">
            {t("Email")}:{" "}
            <a
              href={`mailto:${profile.email}`}
              className="text-blue-600 hover:underline"
            >
              {profile.email}
            </a>
          </p>
        )}
        {profile.paymentOption && !hidePaymentOption && (
          <div>
            {t("Payment Details")}:
            <p
              dir="auto"
              className="text-gray-700 whitespace-pre-line"
            >
              {profile.paymentOption}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasketProfile;
