import React, { useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import Api from "../../api/api"; // Assuming Api is available for updating avatar
import avatarPlaceholder from "../../assets/avatar.jpg";
import Button from "../../common/Button";
import { countries } from "../../utils/constant";

interface ProfileEditAdminProps {
  sellerData: any;
  onSave: (id: string, updatedData: any) => void;
  onCancel: () => void;
}

const ProfileEditAdmin: React.FC<ProfileEditAdminProps> = ({
  sellerData,
  onSave,
  onCancel,
}) => {
  const [editedSeller, setEditedSeller] = useState(sellerData);
  const [photo, setPhoto] = useState<string>(
    sellerData?.avatar || avatarPlaceholder,
  );
  const [tempAvatar, setTempAvatar] = useState<string>("");
  const imgRef = useRef<HTMLInputElement>(null);
  const [isDisable, setIsDisable] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setEditedSeller(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempAvatar(reader.result as string);
        setPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDisable(true);

    try {
      if (tempAvatar) {
        const avatarUploadResponse = await Api.updateAvatarByAdmin(
          editedSeller._id,
          tempAvatar,
        );
        if (avatarUploadResponse) {
          setEditedSeller(prev => ({
            ...prev,
            avatar: avatarUploadResponse.url,
          }));
        } else {
          throw new Error("Failed to upload avatar.");
        }
      }

      // Handle the update process
      onSave(editedSeller._id, editedSeller);
    } catch (error: any) {
      console.error("Error updating seller:", error.message || error);
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 max-h-[80vh] overflow-y-auto"
    >
      {/* Avatar Upload */}
      <input
        id="avatar"
        type="file"
        accept="image/png, image/jpeg, image/webp"
        className="hidden"
        ref={imgRef}
        onChange={handleImageChange}
        disabled={isDisable}
      />
      <label
        htmlFor="avatar"
        className="w-fit cursor-pointer block relative mb-6"
      >
        <FaCamera
          fill="var(--primary)"
          className="absolute right-1 bottom-1"
        />
        <img
          src={photo ?? avatarPlaceholder}
          alt="avatar"
          className="w-[150px] h-[150px] rounded-xl object-cover"
        />
      </label>

      {/* First Name & Last Name */}
      <input
        type="text"
        name="fname"
        value={editedSeller.fname}
        onChange={handleInputChange}
        placeholder="First Name"
        className="w-full p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      />
      <input
        type="text"
        name="lname"
        value={editedSeller.lname}
        onChange={handleInputChange}
        placeholder="Last Name"
        className="w-full p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      />

      {/* Email */}
      <input
        type="email"
        name="email"
        value={editedSeller.email}
        onChange={handleInputChange}
        placeholder="Email"
        className="w-full p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      />

      {/* Country */}
      <select
        name="country"
        value={editedSeller.country}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      >
        {countries.map((country, idx) => (
          <option
            key={idx}
            value={country.value}
          >
            {country.label}
          </option>
        ))}
      </select>

      {/* Phone Input */}
      <PhoneInput
        value={editedSeller.phone}
        onChange={(value: string) =>
          setEditedSeller(prev => ({ ...prev, phone: value }))
        }
        containerClass="bg-[#fff] border-b border-[#000] rounded-none text-sm w-full"
        inputClass="!h-[40px] !w-full !bg-[#fff] !border-none !rounded-none !pl-10"
        buttonClass="!bg-[#fff] !border-none !rounded-none"
        disabled={isDisable}
      />

      {/* About Section */}
      <textarea
        name="about"
        value={editedSeller.about}
        onChange={handleInputChange}
        placeholder="About"
        className="w-full min-h-[70px] p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      ></textarea>

      {/* Profile Description */}
      <textarea
        name="description"
        value={editedSeller.description}
        onChange={handleInputChange}
        placeholder="Profile Description"
        className="w-full min-h-[70px] p-2 border border-gray-300 rounded-md"
        maxLength={500}
        disabled={isDisable}
      ></textarea>
      <p className="text-end text-xs mt-1">
        {editedSeller?.description?.length || 0}/500 characters
      </p>

      {/* Delivery Option */}
      <textarea
        name="deliveryOption"
        value={editedSeller.deliveryOption}
        onChange={handleInputChange}
        placeholder="Delivery Option"
        className="w-full min-h-[70px] p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      />

      {/* Contact */}
      <input
        type="text"
        name="contact"
        value={editedSeller.contact}
        onChange={handleInputChange}
        placeholder="Contact Information"
        className="w-full p-2 border border-gray-300 rounded-md"
        disabled={isDisable}
      />

      {/* Submit / Cancel Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <Button
          className="bg-gray-300"
          onClick={onCancel}
          disabled={isDisable}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="bg-blue-600 text-white"
          disabled={isDisable}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default ProfileEditAdmin;
