// src/hooks/useSellerBasket.ts
import { useState, useEffect } from "react";
import Api from "../api/api";

export interface IBasketSubItem {
  item: {
    _id: string;
    name: string;
    price: number;
    currency: string;
    photos?: Array<{ url: string }>;
  };
  paid?: boolean;
  paidOn?: string | null;
  deletedBySeller?: boolean;
  addedAt?: string;
}

export interface IBasket {
  _id: string;
  items: IBasketSubItem[];
  buyer: any; // ideally, use a proper user type
  seller: any;
  sentAt?: string | null;
}

export function useSellerBasket(buyerId: string) {
  const [basket, setBasket] = useState<IBasket | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [removingItemId, setRemovingItemId] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBasket() {
      try {
        setLoading(true);
        const { data } = await Api.getSingleReceivedOrder(buyerId);
        if (data?.status === 200) {
          setBasket(data.data);
        } else {
          setError(data?.message || "Failed to fetch order");
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    fetchBasket();
  }, [buyerId]);

  async function sellerDeleteItem(itemId: string) {
    setRemovingItemId(itemId);
    try {
      const { data } = await Api.sellerDeleteItemFromOrder(buyerId, itemId);
      if (data?.status === 200) {
        // Assume the endpoint returns { basket, totalPrice }
        setBasket(data.data.basket);
      } else {
        setError(data?.message || "Error marking item as deleted");
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setRemovingItemId(null);
    }
  }

  return {
    basket,
    loading,
    error,
    removingItemId,
    sellerDeleteItem,
  };
}
