import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import toast from "react-hot-toast";

interface PaymentDetailsProps {
  paymentOption: string | null;
  onSave: (paymentDetails: string) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  paymentOption,
  onSave,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(paymentOption || "");
  const [charCount, setCharCount] = useState(paymentOption?.length || 0);
  const { t } = useTranslation();

  const maxChars = 400;
  const maxLines = 7;

  const handleSave = () => {
    if (paymentDetails.length <= maxChars) {
      onSave(paymentDetails);
      setModalOpen(false);
    }
  };

  const handleCancel = () => {
    setPaymentDetails(paymentOption || "");
    setCharCount(paymentOption?.length || 0);
    setModalOpen(false);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const lines = value.split("\n");

    if (lines.length > maxLines) {
      toast.error(
        t("You cannot exceed the maximum of 7 lines for payment details."),
      );
      return;
    }

    if (value.length <= maxChars) {
      setPaymentDetails(value);
      setCharCount(value.length);
    }
  };

  return (
    <>
      {(!paymentOption || paymentOption.trim() === "") && (
        <div className="flex flex-col items-center gap-1">
          <button
            onClick={() => setModalOpen(true)}
            className="flex items-center gap-2 bg-orange-400 text-white font-semibold rounded-full px-6 py-3 hover:bg-orange-500 transition focus:outline-none focus:ring-4 focus:ring-orange-300"
          >
            <span className="text-2xl font-bold">+</span>
            <span className="text-lg">{t("Add Payment Details")}</span>
          </button>
          <p className="text-sm text-gray-600">
            {t("Let buyers know how to send you money")}
          </p>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        ariaHideApp={false}
        style={{
          content: {
            width: "90%", // Adapts width for mobile
            maxWidth: "400px", // Caps width for larger screens
            height: "auto", // Adjusts height based on content
            margin: "auto", // Centers horizontally
            borderRadius: "12px",
            padding: "20px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
            position: "relative", // Ensures proper alignment
            inset: "unset", // Fixes alignment issues caused by default `inset` value
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex", // Enables centering
            justifyContent: "center",
            alignItems: "center", // Centers vertically
          },
        }}
      >
        <h2 className="text-lg font-bold mb-4">{t("Payment Details")}</h2>
        <textarea
          value={paymentDetails}
          onChange={handleTextChange}
          placeholder={t(
            "How would you like people to pay you for items you sell",
          )}
          dir="auto"
          rows={7}
          style={{
            height: "7rem", // Locks textarea to show 7 rows max
            resize: "none",
          }}
          className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-orange-400"
        />
        <div className="flex justify-between items-center mt-4">
          <span className="text-sm text-gray-500">{`${charCount}/${maxChars} ${t(
            "characters",
          )}`}</span>
          <div className="flex gap-2">
            <button
              onClick={handleCancel}
              className="bg-gray-200 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-300 transition"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleSave}
              className="bg-orange-400 text-white rounded-lg px-4 py-2 hover:bg-orange-500 transition"
              disabled={charCount === 0}
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentDetails;
