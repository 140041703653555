import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useDirection from "../../hooks/useDirection";
import i18n from "../../utils/i18n";

interface CountdownProps {
  remainingTime: number; // Remaining time in milliseconds
}

const Countdown: React.FC<CountdownProps> = ({ remainingTime }) => {
  const [timeLeft, setTimeLeft] = useState<number>(remainingTime);
  const { t } = useTranslation();
  // Calculate the end date based on the remaining time
  const endDate = useRef(new Date(Date.now() + remainingTime));
  const direction = useDirection();
  useEffect(() => {
    // Update endDate when remainingTime changes
    endDate.current = new Date(Date.now() + remainingTime);
    setTimeLeft(remainingTime);

    const timer = setInterval(() => {
      setTimeLeft(prevTime => (prevTime > 1000 ? prevTime - 1000 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [remainingTime]);

  const formatTimeLeft = (ms: number) => {
    if (ms <= 0) return t("Auction Ended");

    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const date = endDate.current.toLocaleDateString(
      direction === "rtl" ? "he-IL" : "en-GB",
    ); // Format as DD/MM
    const time = endDate.current.toLocaleTimeString(
      direction === "rtl" ? "he-IL" : "en-GB",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      },
    );
    if (i18n.language === "he") {
      if (ms > 24 * 3600 * 1000) {
        // יותר מ-24 שעות
        return `${days} ימים ${hours} שעות נותרו (${date} בשעה ${time})`;
      } else if (ms > 3600 * 1000) {
        // פחות מ-24 שעות אבל יותר משעה
        return `${hours} שעות ${minutes} דקות נותרו (${date} בשעה ${time})`;
      } else if (ms > 5 * 60 * 1000) {
        // פחות משעה אבל יותר מ-5 דקות
        return `${minutes} דקות ${seconds} שניות נותרו (${date} בשעה ${time})`;
      } else {
        // פחות מ-5 דקות
        return `${minutes} דקות ${seconds} שניות נותרו (${date} בשעה ${time})`;
      }
    } else {
      // Default (e.g. English) or other languages
      if (ms > 24 * 3600 * 1000) {
        return `${days}d ${hours}h left (${date} at ${time})`;
      } else if (ms > 3600 * 1000) {
        return `${hours}h ${minutes}m left (${date} at ${time})`;
      } else if (ms > 5 * 60 * 1000) {
        return `${minutes}m ${seconds}s left (${date} at ${time})`;
      } else {
        return `${minutes}m ${seconds}s left (${date} at ${time})`;
      }
    }
  };

  const formattedTime = formatTimeLeft(timeLeft);

  return (
    <div>
      {timeLeft > 24 * 3600 * 1000 ? (
        <div className="countdown-end-date   font-semibold  sm:mt-[-1rem] md:mt-[-1rem] text-lg">
          {formattedTime}
        </div>
      ) : (
        <div
          className={`sm:mt-[-1rem] md:mt-[-1rem] text-lg ${
            timeLeft <= 5 * 60 * 1000 ? "text-red-500 font-bold" : ""
          }`}
        >
          <div className="whitespace-nowrap max-md:text-sm">
            {formattedTime}
            {timeLeft <= 10 * 60 * 1000 && timeLeft > 0 && (
              <span className="text-green-500 max-md:text-sm font-semibold rtl:mr-1 ltr:ml-1">
                {t("+5 minutes")}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Countdown;
