// src/components/BackButton.tsx
import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { useIsHebrew } from "../hooks/useIsHebrew";
import LocalizedLink from "../utils/LocalizedLink";

interface BackButtonProps {
  to: string;
  label?: string;
  ariaLabel?: string;
  icon?: React.ReactNode;
}

const BackButton: React.FC<BackButtonProps> = ({
  to,
  label,
  ariaLabel,
  icon,
}) => {
  const { t } = useTranslation();
  const isHebrew = useIsHebrew();
  const defaultLabel = t("Back");
  const displayLabel = label ? t(label) : defaultLabel;
  const displayAriaLabel = ariaLabel ? t(ariaLabel) : displayLabel;

  return (
    <div className="max-md:mb-2 max-md:mt-[-1rem] max-md:ml-[-1rem] md:my-4 px-4">
      <LocalizedLink
        to={to}
        aria-label={displayAriaLabel}
        className="w-full sm:w-auto"
      >
        <Button className="w-full sm:w-auto flex items-center justify-center bg-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 text-white px-4 py-2 rounded-md shadow transition-colors duration-200">
          {icon ||
            (isHebrew ? (
              <FaArrowRight
                fill="white"
                className="ml-2"
              />
            ) : (
              <FaArrowLeft
                fill="white"
                className="mr-2"
              />
            ))}
          <span className="text-white font-semibold">{displayLabel}</span>
        </Button>
      </LocalizedLink>
    </div>
  );
};

export default BackButton;
