// src/components/Basket/BasketTotals.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../context/currency";
import { IBasketSubItem } from "../../hooks/useBasket";

interface BasketTotalsProps {
  items: IBasketSubItem[];
}

const BasketTotals: React.FC<BasketTotalsProps> = ({ items }) => {
  const { t } = useTranslation();
  const { convert, userCurrency } = useCurrency();

  if (items.length === 0) {
    return <p className="text-gray-500">{t("No items in basket")}</p>;
  }

  let unpaidTotal = 0;
  let paidTotal = 0;

  for (const basketItem of items) {
    if (basketItem.deletedBySeller) continue;
    const price = basketItem?.item?.price;
    const currency = basketItem?.item?.currency;
    const converted = convert(price, currency, userCurrency);

    if (basketItem?.paid) {
      paidTotal += converted;
    } else if (!basketItem?.paid && !basketItem?.deletedBySeller) {
      unpaidTotal += converted;
    }
  }

  const formattedUnpaid = unpaidTotal.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const formattedPaid = paidTotal.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <div className="space-y-1">
      <p className="text-red-600 text-xl font-bold">
        {t("Unpaid")}:{formattedUnpaid} {t(userCurrency)}
      </p>
      <p className="text-green-600 text-xl font-bold">
        {t("Paid")}: {formattedPaid} {t(userCurrency)}
      </p>
    </div>
  );
};

export default BasketTotals;
