import React, { ReactNode, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineFullscreen,
} from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { useSwipeable } from "react-swipeable";
import { allKeywords } from "../utils/constant";
import { cld, extractPublicIdFromUrl, isVideo } from "../utils/helpers";
import { useFullScreen } from "../context/fullscreen";
// At the top of your file or in a global styles import:
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-video.css";

// For the React component:
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { trim } from "@cloudinary/url-gen/actions/videoEdit";
import { scale } from "@cloudinary/url-gen/actions/resize";
import Thumbnail from "./../../node_modules/lightgallery/plugins/thumbnail/lg-thumbnail.es5";

function getLightGalleryItems(items: string[]) {
  return items.map(url => {
    if (url.includes("youtube") || url.includes("vimeo")) {
      return { iframe: true, src: url, thumb: url };
    } else if (isVideo(url)) {
      const videoPublicId = extractPublicIdFromUrl(url);
      const thumbUrl = cld
        .video(videoPublicId)
        .videoEdit(trim().startOffset(8.5))
        .resize(scale().width(300))
        .format("png")
        .toURL();

      const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
      const embedSrc = `https://player.cloudinary.com/embed/?cloud_name=${cloudName}&public_id=${videoPublicId}`;

      return {
        iframe: true, // Use iframe mode for Cloudinary embed
        src: embedSrc,
        thumb: thumbUrl,
      };
    } else {
      return { src: url, thumb: url };
    }
  });
}

// =============================================================================
// Props definition
interface ImageHandlerProps {
  photos: string[];
  selectedPhoto: string;
  setSelectedPhoto: (photo: string) => void;
  children?: ReactNode;
  className?: string;
  onEnlargedChange?: (isEnlarged: boolean) => void;
  imageSize?: any;
  isSold?: boolean;
  isMainImageLoading?: boolean;
  setIsMainImageLoading: any;
}

// =============================================================================
// The main component
const ImageHandler: React.FC<ImageHandlerProps> = ({
  photos,
  selectedPhoto,
  onEnlargedChange,
  setSelectedPhoto,
  children,
  className,
  isMainImageLoading,
  setIsMainImageLoading,
  isSold = false,
  imageSize,
}) => {
  const { t } = useTranslation();
  const { isFullScreen, setIsFullScreen } = useFullScreen();

  // Desktop modal states
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [currentIndexLocal, setCurrentIndexLocal] = useState(0);
  const [zoomOrigin, setZoomOrigin] = useState({ xPercent: 50, yPercent: 50 });
  const imgRef = useRef<HTMLImageElement>(null);
  const [swipePosition, setSwipePosition] = useState(0);
  const [finalSwipePosition, setFinalSwipePosition] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionDirection, setTransitionDirection] = useState<
    null | "next" | "prev"
  >(null);
  const [hoveredPhoto, setHoveredPhoto] = useState<string | null>(null);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(3);
  const [touchStartDistance, setTouchStartDistance] = useState(0);
  const [touchCenter, setTouchCenter] = useState({ x: 0, y: 0 });
  const [isPinching, setIsPinching] = useState(false);
  const zoomMin = 1;
  const zoomMax = 4;
  const lgItems = getLightGalleryItems(photos);
  const currentIndexRef = useRef<number>(
    photos.indexOf(selectedPhoto) >= 0 ? photos.indexOf(selectedPhoto) : 0,
  );
  // Update local index when selectedPhoto changes
  useEffect(() => {
    const newIndex = photos.indexOf(selectedPhoto);
    if (newIndex !== -1) {
      setCurrentIndexLocal(newIndex);
    }
  }, [selectedPhoto, photos]);
  const renderMedia = (url: string) => {
    if (isVideo(url)) {
      const videoPublicId = extractPublicIdFromUrl(url);
      const thumbUrl = cld
        .video(videoPublicId)
        .videoEdit(trim().startOffset(8.5))
        .resize(scale().width(300))
        .format("png")
        .toURL();

      return (
        <video
          controls
          src={url}
          poster={thumbUrl}
          className="w-screen h-screen object-contain"
        />
      );
    } else {
      return (
        <img
          src={url}
          alt={allKeywords}
          className="w-screen h-screen object-contain"
          ref={imgRef}
          style={{
            transform: `scale(${zoomLevel}) translate(${panPosition.x}px, ${panPosition.y}px)`,
            transformOrigin: "center center",
            cursor: isZoomed ? "zoom-out" : "zoom-in",
            transition: "transform 0.3s ease",
          }}
        />
      );
    }
  };

  // =============================================================================
  // LightGallery integration (mobile only)
  // Convert each photo URL to a LightGallery item.
  // For videos (including Cloudinary-hosted MP4), use html5Video if not YouTube/Vimeo.

  // Use our hook to get openLG, passing setSelectedPhoto and photos.
  const [showMobileGallery, setShowMobileGallery] = useState(false);
  // On mobile: tap the image to open LightGallery.
  // On desktop: toggle your zoom/modal behavior.
  const handleImageClick = () => {
    if (isMobile) {
      setShowMobileGallery(true);
      setIsFullScreen(true);
      const index = photos.indexOf(selectedPhoto);
    } else {
      setIsEnlarged(true);
      setIsFullScreen(true);
    }
  };

  // Desktop: toggle zoom when clicking image (unused on mobile)
  const handleZoomClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isZoomed) {
      if (imgRef.current) {
        imgRef.current.style.transformOrigin = "center center";
      }
      setIsZoomed(false);
    } else {
      const containerRect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - containerRect.left;
      const offsetY = e.clientY - containerRect.top;
      const width = containerRect.width;
      const height = containerRect.height;
      const xPercent = (offsetX / width) * 100;
      const yPercent = (offsetY / height) * 100;
      const constrainedXPercent = Math.max(0, Math.min(xPercent, 100));
      const constrainedYPercent = Math.max(0, Math.min(yPercent, 100));
      if (imgRef.current) {
        imgRef.current.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
      }
      setZoomOrigin({
        xPercent: constrainedXPercent,
        yPercent: constrainedYPercent,
      });
      setIsZoomed(true);
    }
  };

  const handleFullScreenToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (isMobile) {
      // On mobile, let LightGallery handle fullscreen.
      handleImageClick();
    } else {
      setIsEnlarged(true);
      setIsFullScreen(true);
    }
  };

  const handleClose = () => {
    setIsEnlarged(false);
    setIsZoomed(false);
    setIsFullScreen(false);
  };

  // Mouse move handler (desktop zoom behavior)
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!imgRef.current || !isZoomed) return;
    const containerRect = e.currentTarget.getBoundingClientRect();
    const cursorX = e.clientX - containerRect.left;
    const cursorY = e.clientY - containerRect.top;
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;
    const xPercent = (cursorX / containerWidth) * 100;
    const yPercent = (cursorY / containerHeight) * 100;
    const constrainedXPercent = Math.max(0, Math.min(xPercent, 100));
    const constrainedYPercent = Math.max(0, Math.min(yPercent, 100));
    if (imgRef.current) {
      imgRef.current.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
    }
    setZoomOrigin({
      xPercent: constrainedXPercent,
      yPercent: constrainedYPercent,
    });
  };

  // =============================================================================
  // Touch & Swipe Handlers (desktop modal only; mobile uses LightGallery)
  const calculateDistance = (
    touch1: React.Touch,
    touch2: React.Touch,
  ): number => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 2) {
      setIsPinching(true);
      const distance = calculateDistance(e.touches[0], e.touches[1]);
      setTouchStartDistance(distance);
      const centerX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
      const centerY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
      const containerRect = e.currentTarget.getBoundingClientRect();
      const xPercent =
        ((centerX - containerRect.left) / containerRect.width) * 100;
      const yPercent =
        ((centerY - containerRect.top) / containerRect.height) * 100;
      setTouchCenter({ x: centerX, y: centerY });
      setZoomOrigin({ xPercent, yPercent });
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 2 && isPinching) {
      const distance = calculateDistance(e.touches[0], e.touches[1]);
      const zoomChange = distance / touchStartDistance;
      const newZoomLevel = Math.min(
        zoomMax,
        Math.max(zoomMin, zoomLevel * zoomChange),
      );
      setZoomLevel(newZoomLevel);
    } else if (e.touches.length < 2 && isPinching) {
      setIsPinching(false);
    }
  };

  // Swipe handlers for desktop modal (mobile LightGallery handles swiping)
  const swipeHandlers = useSwipeable({
    onSwiping: eventData => {
      if (!isMobile && !isPinching) {
        const maxSwipe = window.innerWidth * 0.7;
        const deltaX = eventData.deltaX;
        const clampedDeltaX = Math.max(-maxSwipe, Math.min(deltaX, maxSwipe));
        setSwipePosition(clampedDeltaX);
      }
    },
    onSwiped: eventData => {
      if (!isMobile && !isPinching) {
        handleSwipeEnd(eventData.deltaX);
      }
    },
    preventScrollOnSwipe: isEnlarged,
    trackTouch: true,
    trackMouse: false,
    delta: 10,
  });

  const handleSwipeEnd = (deltaX: number) => {
    const threshold = 50;
    if (deltaX < -threshold) {
      const distanceToMove = -window.innerWidth - swipePosition;
      setFinalSwipePosition(distanceToMove);
      setTransitionDirection("next");
    } else if (deltaX > threshold) {
      const distanceToMove = window.innerWidth - swipePosition;
      setFinalSwipePosition(distanceToMove);
      setTransitionDirection("prev");
    } else {
      setFinalSwipePosition(-swipePosition);
      setTransitionDirection(null);
    }
    setIsTransitioning(true);
  };
  const getThumbnail = (url: string) => {
    if (isVideo(url)) {
      const publicId = extractPublicIdFromUrl(url);
      return cld
        .video(publicId)
        .videoEdit(trim().startOffset(8.5))
        .resize(scale().width(500))
        .format("png")
        .toURL();
    }
    return url;
  };
  // Keyboard navigation for desktop modal
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isEnlarged) {
        if (e.key === "Escape") {
          handleClose();
        } else if (e.key === "ArrowRight") {
          nextImage();
        } else if (e.key === "ArrowLeft") {
          prevImage();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isEnlarged, currentIndexLocal, photos]);

  useEffect(() => {
    if (onEnlargedChange) {
      onEnlargedChange(isEnlarged);
    }
  }, [isEnlarged, onEnlargedChange]);

  // For desktop modal: Prepare a 3-item array for smooth transition.
  const getImagesForLooping = () => {
    const currentPhoto = hoveredPhoto || selectedPhoto;
    const currentIndex = photos.indexOf(currentPhoto);
    const prevIndex = (currentIndex - 1 + photos.length) % photos.length;
    const nextIndex = (currentIndex + 1) % photos.length;
    return [photos[prevIndex], currentPhoto, photos[nextIndex]];
  };
  const imagesForLooping = getImagesForLooping();
  const maxTransform = window.innerWidth * 0.7;
  const clampedTransformX = Math.max(
    -window.innerWidth + finalSwipePosition - maxTransform,
    Math.min(
      -window.innerWidth + swipePosition + finalSwipePosition,
      -window.innerWidth + finalSwipePosition + maxTransform,
    ),
  );

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    setSwipePosition(0);
    setFinalSwipePosition(0);

    if (transitionDirection === "next") {
      const nextIndex = (currentIndexLocal + 1) % photos.length;
      setCurrentIndexLocal(nextIndex);
      setSelectedPhoto(photos[nextIndex]);
    } else if (transitionDirection === "prev") {
      const prevIndex = (currentIndexLocal - 1 + photos.length) % photos.length;
      setCurrentIndexLocal(prevIndex);
      setSelectedPhoto(photos[prevIndex]);
    }
    setTransitionDirection(null);
  };

  const nextImage = () => {
    const nextIndex = (currentIndexLocal + 1) % photos.length;
    setCurrentIndexLocal(nextIndex);
    setSelectedPhoto(photos[nextIndex]);
    setSwipePosition(0);
  };

  const prevImage = () => {
    const prevIndex = (currentIndexLocal - 1 + photos.length) % photos.length;
    setCurrentIndexLocal(prevIndex);
    setSelectedPhoto(photos[prevIndex]);
    setSwipePosition(0);
  };

  return (
    <>
      {/* Main Image Container */}
      <div
        className={`relative overflow-hidden ${className}`}
        onClick={isMobile ? handleImageClick : undefined}
        {...(!isMobile ? swipeHandlers : {})}
        style={{
          minHeight: isMobile ? "200px" : "80vh",
          width: "fit-content",
        }}
      >
        <div className="relative inline-block">
          {isSold && (
            <div
              className="sold-ribbon-overlay absolute top-0 left-0 pointer-events-none"
              style={{ zIndex: 10, width: "50%", height: "50%" }}
            >
              <svg
                viewBox="0 0 200 200"
                preserveAspectRatio="xMinYMin meet"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full"
              >
                <polygon
                  points="0,0 200,0 200,0 0,200 0,200"
                  fill="#1a6bee"
                />
                <text
                  x={isMobile ? "110" : "100"}
                  y="80"
                  fontSize="40"
                  textAnchor="middle"
                  fill="white"
                  fontWeight="bold"
                  transform="rotate(-45 100 100)"
                >
                  SOLD
                </text>
              </svg>
            </div>
          )}

          {/* Render children or main image */}
          {children}

          {/* Fullscreen Icon for Desktop */}
          {!isMobile && !isVideo(selectedPhoto) && (
            <AiOutlineFullscreen
              size={30}
              className="absolute top-2 right-2 cursor-pointer text-white bg-black bg-opacity-50 rounded-full p-1"
              onClick={handleFullScreenToggle}
              title="View Fullscreen"
              style={{ fill: "white" }}
            />
          )}
        </div>
      </div>

      {/* Desktop Fullscreen Modal */}
      {isEnlarged &&
        ReactDOM.createPortal(
          <div
            dir="ltr"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
          >
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 p-1 text-white text-3xl z-10"
              onClick={handleClose}
            >
              <FaTimes
                style={{ fill: isVideo(selectedPhoto) ? "white" : "black" }}
              />
            </button>

            {/* Image Container with Swipe Handlers */}
            <div
              {...(!isMobile ? swipeHandlers : {})}
              className="relative"
              style={{ overflow: "hidden", width: "100vw", height: "100vh" }}
              onClick={handleZoomClick}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onMouseMove={handleMouseMove}
            >
              {isVideo(selectedPhoto) ? (
                // For videos, always render the video without zooming
                renderMedia(selectedPhoto)
              ) : isZoomed ? (
                // Zoomed view for images
                <img
                  src={imagesForLooping[1]}
                  alt={allKeywords}
                  className="w-screen h-screen object-contain"
                  style={{
                    transform: `scale(${zoomLevel}) translate(${panPosition.x}px, ${panPosition.y}px)`,
                    transformOrigin: `${zoomOrigin.xPercent}% ${zoomOrigin.yPercent}%`,
                    cursor: "zoom-out",
                    transition: "transform 0.3s ease",
                  }}
                  ref={imgRef}
                />
              ) : (
                // Swipeable view for images
                <div
                  className="flex bg-white unselectable"
                  style={{
                    width: "300%",
                    overflow: "hidden",
                    transform: `translateX(${clampedTransformX}px)`,
                    transition: isTransitioning
                      ? "transform 0.3s ease"
                      : "none",
                  }}
                  onTransitionEnd={handleTransitionEnd}
                >
                  {imagesForLooping.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={allKeywords}
                      className="w-screen h-screen object-contain flex-shrink-0 cursor-zoom-in"
                      ref={index === 1 ? imgRef : null}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* Thumbnails (Desktop Only) */}
            {!isMobile && (
              <div
                className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2"
                style={{ zIndex: 10 }}
                onMouseLeave={() => setHoveredPhoto(null)}
              >
                {photos.map((photo, index) => {
                  const thumbnailUrl = getThumbnail(photo);
                  return (
                    <img
                      key={index}
                      src={thumbnailUrl}
                      alt={allKeywords}
                      className={`thumbnail-image unselectable w-16 h-16 object-cover cursor-pointer border-2 ${
                        selectedPhoto === photo
                          ? "border-blue-500"
                          : "border-transparent"
                      } hover:border-blue-500`}
                      onMouseEnter={() => {
                        if (photo !== selectedPhoto) {
                          setIsZoomed(false);
                        }
                        setHoveredPhoto(thumbnailUrl);
                      }}
                      onClick={() => {
                        setSelectedPhoto(photo);
                        setHoveredPhoto(null);
                      }}
                    />
                  );
                })}
              </div>
            )}

            {/* Navigation Arrows (Desktop Only) */}
            {!isMobile && (
              <>
                <AiOutlineArrowLeft
                  size={50}
                  className="absolute left-4 text-white cursor-pointer"
                  onClick={prevImage}
                  style={{ fill: "black" }}
                />
                <AiOutlineArrowRight
                  size={50}
                  className="absolute right-4 text-white cursor-pointer"
                  onClick={nextImage}
                  style={{ fill: "black" }}
                />
              </>
            )}
          </div>,
          document.body,
        )}
      {isMobile && showMobileGallery && (
        <LightGallery
          dynamic
          loop={true}
          counter={true}
          dynamicEl={lgItems} // array of { src, thumb } or { html5Video } objects
          index={
            photos.indexOf(selectedPhoto) >= 0
              ? photos.indexOf(selectedPhoto)
              : 0
          }
          plugins={[lgZoom, lgVideo]}
          mobileSettings={
            {
              // swipeToClose: false,
            }
          }
          videojs={true}
          videojsOptions={{
            controls: true,

            playsinline: true,
          }}
          onInit={detail => {
            // Auto-open the gallery as soon as it mounts
            // Pass the starting index so it opens at the current photo
            detail.instance.openGallery(
              photos.indexOf(selectedPhoto) >= 0
                ? photos.indexOf(selectedPhoto)
                : 0,
            );
          }}
          onAfterSlide={detail => {
            // Update the current index ref
            currentIndexRef.current = detail.index;

            // Get the arrow elements (LightGallery creates them with these classes)
            const arrows = document.querySelectorAll(".lg-prev, .lg-next");
            arrows.forEach(arrow => {
              // Immediately make arrows visible and remove any existing animation
              (arrow as HTMLElement).style.opacity = "1";
              (arrow as HTMLElement).style.animation = "none";
            });

            // After a short delay, reapply the fade-out animation so they disappear after 1 second
            setTimeout(() => {
              arrows.forEach(arrow => {
                (arrow as HTMLElement).style.animation =
                  "hideArrows 0.5s forwards";
                (arrow as HTMLElement).style.animationDelay = "1s";
              });
            }, 50);
          }}
          onBeforeClose={() => {
            // Use the ref's value to update the external state
            setIsFullScreen(false);
            setSelectedPhoto(photos[currentIndexRef.current]);
            setShowMobileGallery(false);
          }}
          onAfterClose={() => {}}
        />
      )}
    </>
  );
};

export default ImageHandler;
