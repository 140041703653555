// App.tsx

import Cookies from "js-cookie";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { AuthProvider } from "./context/auth";
import { CurrencyProvider } from "./context/currency";
import { LocationProvider } from "./context/location";

import { europeanCountries, REACT_APP_KEY_NAME } from "./utils/constant";

import "./App.css";
import MobileBottomNav from "./common/MobileButtonNav";
import { FullScreenProvider } from "./context/fullscreen";
import LanguageWrapper from "./utils/LanguageWrapper";
import FacebookPixel from "./components/FacebookPixel";
import SingleItemRoute from "./utils/SingleItemProps";
import ScrollToTopNoAnimation from "./utils/ScrollToTopNoAnimation";
function App() {
  const locationRouter = useLocation();

  const verifyToken = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    if (token) {
      const decode_token: JwtPayload = jwtDecode(token);
      if (
        (decode_token.exp && decode_token.exp * 1000 < new Date().getTime()) ||
        Cookies.get(REACT_APP_KEY_NAME) === undefined ||
        localStorage.getItem(REACT_APP_KEY_NAME) === "undefined"
      ) {
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    verifyToken();
    window.scrollTo(0, 0);
  }, [locationRouter.pathname]);
  return (
    <>
      <FacebookPixel pixelId="1030737585763587" />
      <AuthProvider>
        <CurrencyProvider europeanCountries={europeanCountries}>
          <LocationProvider>
            <FullScreenProvider>
              <Router>
                <ScrollToTopNoAnimation />
                <Switch>
                  {/* 1) If user visits "/", redirect to "/en" */}
                  <Route
                    exact
                    path="/"
                  >
                    <Redirect to="/en" />
                  </Route>

                  {/* 2) If user visits ANY route that starts with "/en" or "/he", 
                      we delegate to LanguageWrapper */}
                  <Route path="/:lang(en|he)">
                    <LanguageWrapper />
                  </Route>

                  {/* 3) For ANY route that doesn't match the above, automatically 
                      insert "/en". This covers e.g. "/items" => "/en/items" */}
                  <Route
                    path="*"
                    render={props => {
                      // For example, read from a cookie that stores the current language.
                      const currentLang = Cookies.get("i18next") || "en";
                      const path = props.location.pathname;
                      const search = props.location.search || "";
                      // Redirect to the current language version of the URL
                      return (
                        <Redirect to={`/${currentLang}${path}${search}`} />
                      );
                    }}
                  />
                </Switch>
              </Router>
            </FullScreenProvider>
          </LocationProvider>
        </CurrencyProvider>
      </AuthProvider>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>
  );
}

export default App;
