import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";

import topRatedBadge from "../../assets/top_rated_badge.png";
import { countries } from "./../../utils/constant";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { cld, extractPublicIdForSeller } from "../../utils/helpers";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { isMobile } from "react-device-detect";
import LocalizedLink from "../../utils/LocalizedLink";
const Seller = params => {
  const [hoveredCountry, setHoveredCountry] = React.useState(null);
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null);
  const scaleWidth = isMobile ? 200 : 300;
  const getCountryName = (countryCode: string) => {
    const temp = countries?.find(c => c.value === countryCode);
    return temp ? temp.label : "Unknown";
  };
  const isRTL = (text: string): boolean => {
    const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/; // Matches Hebrew (U+0590–U+05FF) and Arabic (U+0600–U+06FF) Unicode ranges
    return rtlPattern.test(text);
  };
  const { t } = useTranslation();
  return (
    <LocalizedLink
      to={`/seller/${params?.itm?._id}`}
      className="block bg-white rounded-xl p-3 h-full hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px]"
    >
      <div className="relative mt-[-0.5rem]">
        {params?.itm?.country && (
          <div
            className="absolute"
            onMouseEnter={() => setHoveredCountry(params?.itm?.country)}
            onMouseLeave={() => setHoveredCountry(null)}
          >
            <img
              className="w-[20px] sm:max-w-[15px] md:min-w-[20px] ml-[-0.5rem] rounded"
              src={`https://flagcdn.com/32x24/${params?.itm?.country?.toLowerCase()}.png`}
              alt={`${params?.itm?.country} flag`}
            />

            {/* Tooltip */}
            {hoveredCountry === params?.itm?.country && (
              <div
                style={{
                  whiteSpace: "nowrap", // Prevent line breaks
                }}
                className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-10"
              >
                {getCountryName(params?.itm?.country)}
              </div>
            )}
          </div>
        )}
        {params?.itm?.topRated && (
          <div
            className="absolute rtl:left-0.5 ltr:right-0.5 top-1"
            onMouseEnter={() => setHoveredTopRated(true)}
            onMouseLeave={() => setHoveredTopRated(null)}
          >
            <img
              className="w-[15px] sm:max-w-[15px] md:min-w-[25px] "
              src={topRatedBadge}
              alt={`Top Rated Seller`}
            />

            {/* Tooltip */}
            {hoveredTopRated && (
              <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-10">
                {"Top Rated Seller"}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="h-[10rem] w-full sm:w-[8rem] md:w-[12rem] mx-auto my-2 overflow-hidden">
          {params?.itm?.avatar?.startsWith("https") ? (
            <img
              src={params?.itm?.avatar}
              className="max-w-full max-h-full min-w-full min-h-full object-contain"
              alt={
                params.fname + " " + params.lname + " profile picture" ||
                "Seller profile image"
              }
              loading="lazy"
            />
          ) : (
            <AdvancedImage
              cldImg={cld
                .image(extractPublicIdForSeller(params?.itm?.avatar))
                .resize(scale().width(scaleWidth))
                .format("avif")}
              className="max-w-full max-h-full min-w-full min-h-full object-contain"
              plugins={[lazyload()]}
              alt={
                params.fname + " " + params.lname + " profile picture" ||
                "Seller profile image"
              }
            />
          )}
        </div>
        <div>
          <div className="mb-1  flex items-center gap-1 justify-center">
            <p
              style={{
                direction: isRTL(params?.itm?.fname) ? "rtl" : "ltr",
                fontSize: window.innerWidth < 760 ? "0.8rem" : "1.05rem",
                wordBreak: "break-all", // Ensures long words break onto a new line
                overflowWrap: "break-word", // Breaks words that overflow the container
                whiteSpace: "normal", // Allows text to wrap normally
              }}
              className="overflow-hidden line-clamp-1 ml-[0rem] mr-[0rem] text-sm md:text-xl lg:text-sm font-semibold"
            >
              {params?.itm?.fname + " " + params?.itm?.lname}
            </p>
            <IoIosCheckmarkCircle
              className="min-w-[14px]"
              fill="#00ab07"
            />
          </div>
          <p className="text-center text-xs">{params?.itm?.about}</p>
        </div>
      </div>
    </LocalizedLink>
  );
};

export default Seller;
