import React from "react";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";
import { FaMoneyCheck } from "react-icons/fa";

interface MarkPaidActionsProps {
  markPaidMode: boolean;
  toggleMarkPaidMode: () => void;
  confirmPay: () => void;
  confirming?: boolean;
  confirmDisabled?: boolean;
}

const MarkPaidActions: React.FC<MarkPaidActionsProps> = ({
  markPaidMode,
  toggleMarkPaidMode,
  confirmPay,
  confirming = false,
  confirmDisabled = false,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (!markPaidMode) {
      // Enter mark-paid mode (show checkboxes)
      toggleMarkPaidMode();
    } else {
      // Confirm marking as paid
      confirmPay();
    }
  };

  return (
    <Button
      onClick={handleClick}
      disabled={markPaidMode ? confirmDisabled || confirming : false}
      className="!bg-orange-500 !hover:bg-orange-600 active:bg-orange-700 !text-white !px-3 !py-2 !text-sm flex items-center gap-2 !whitespace-nowrap"
    >
      <FaMoneyCheck className="inline-block" />
      {markPaidMode ? t("Confirm paid") : t("Mark paid")}
    </Button>
  );
};

export default MarkPaidActions;
