import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { FaEllipsisV, FaSpinner } from "react-icons/fa";

import ReactPaginate from "react-paginate";
import { Link, useHistory, useLocation } from "react-router-dom";
import Api from "../api/api";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AuctionItem from "../components/Items/AuctionItem";
import AuctionsFilter from "../components/Items/AuctionsFilterTest";
import { PhoneVerificationModals } from "../components/PhoneVerification/PhoneVerificationModals";
import { usePhoneVerification } from "../components/PhoneVerification/usePhoneVerification";
import { useAuth } from "../context/auth";
import { useCurrency } from "../context/currency";
import { useLocationContext } from "../context/location";

import { ISelectOption } from "../types/common";

import SellerGroupTiles from "../components/SellerGroupTiles";

import AuctionsPageHelmet from "./../components/AuctionsPageHelmet";
import { url } from "inspector";
import LocalizedLink from "../utils/LocalizedLink";

const AuctionsPage = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loadingInner, setLoadingInner] = useState(false);
  const { user } = useAuth();

  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [filteredAuctions, setFilteredAuctions] = useState<any[]>([]);
  const [pastAuctionItems, setPastAuctionItems] = useState<any[] | null>(null);
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);

  const [groupBySeller, setGroupBySeller] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const { convert } = useCurrency();

  const [myBids, setMyBids] = useState<any[] | null>(null);
  const [purchases, setPurchases] = useState<any[] | null>(null);
  const [urlParamsLoaded, setUrlParamsLoaded] = useState(false);
  const history = useHistory();
  const [showPage, setShowPage] = useState(false);
  const perPage = isMobile ? 36 : 48;
  const pageCount = Math.ceil(filteredAuctions.length / perPage);
  const [phoneVerifiedLoading, setPhoneVerifiedLoading] = useState(true);
  const currentPage = Math.floor(itemOffset / perPage);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    phoneNumber,
    setPhoneNumber,
    otpCode,
    setOtpCode,
    isPhoneModalOpen,
    isOtpModalOpen,
    openPhoneModal,
    closePhoneModal,
    closeOtpModal,
    sendVerification,
    verifyCode: verifyCodeHook,
    loading,
  } = usePhoneVerification(user);
  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [restoringSession, setRestoringSession] = useState(true);

  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const { selectedLocation: selectedLocations } = useLocationContext();
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  /* const [locationOptions, setLocationOptions] = useState<
    ISelectOption[] | any[]
  >(["Everywhere"]);*/
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [search, setSearch] = useState("");
  //const [isRestoring, setIsRestoring] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );
  type Filter = {
    search: string;
    selectedCategories: ISelectOption[];
    selectedCountries: ISelectOption[];
    years: { min: string | number; max: string | number };
    prices: { min: string | number; max: string | number };
  };

  const defaultFilters: Filter = {
    search: "",
    selectedCategories: [],
    selectedCountries: [],
    years: { min: "", max: "" },
    prices: { min: "", max: "" },
  };

  const [view, setView] = useState<"all" | "myBids" | "purchases" | "history">(
    "all",
  );
  // Store filters per view
  const [viewFilters, setViewFilters] = useState<{
    [key in "all" | "myBids" | "purchases" | "history"]: Filter;
  }>({
    all: { ...defaultFilters },
    myBids: { ...defaultFilters },
    purchases: { ...defaultFilters },
    history: { ...defaultFilters },
  });
  const [viewPagination, setViewPagination] = useState<{
    all: number;
    myBids: number;
    purchases: number;
    history: number;
  }>({
    all: 0,
    myBids: 0,
    purchases: 0,
    history: 0,
  });
  const currentFilters = viewFilters[view];
  const [isPhoneVerifiedLocal, setIsPhoneVerifiedLocal] = useState<
    boolean | undefined | null
  >(null);

  const [restoreScrollPosition, setRestoreScrollPosition] = useState<
    number | null
  >(null);
  const [isMoreDropdownOpen, setMoreDropdownOpen] = useState(false);

  const handleViewChange = (
    newView: "all" | "myBids" | "purchases" | "history",
  ) => {
    if (!user && (newView === "myBids" || newView === "purchases")) {
      localStorage.setItem("redirect", `/auctions`);
      history.push("/auth/login");
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("view", newView);
    if (urlParamsLoaded) queryParams.delete("q");
    if (!loadingInner && !searchInProgress && urlParamsLoaded) {
      queryParams.delete("categories");
    }
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    setView(newView);
    setItemOffset(viewPagination[newView] ?? 0);

    // Reset pagination when switching views
  };
  // A generic setter for the current view's filter fields
  const setCurrentFilter = <K extends keyof Filter>(
    field: K,
    value: React.SetStateAction<Filter[K]>,
  ) => {
    setViewFilters(prev => {
      const current = prev[view][field];
      const newValue =
        typeof value === "function"
          ? (value as (prev: Filter[K]) => Filter[K])(current)
          : value;
      return {
        ...prev,
        [view]: { ...prev[view], [field]: newValue },
      };
    });
  };

  const verifyCode = async () => {
    const success = await verifyCodeHook();
    if (success) {
      setIsPhoneVerifiedLocal(true); // Trigger re-render to hide button
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const viewParam = queryParams.get("view");
    if (viewParam) {
      setView(viewParam as "all" | "myBids" | "purchases" | "history");
    }
  }, [location.search]);
  useEffect(() => {
    const handleView = async () => {
      setLoadingInner(true);
      if (view === "myBids") {
        if (myBids === null) {
          await fetchUserActiveBids(); // fetchUserActiveBids sets 'filteredItems' and 'filteredAuctions'
          setLoadingInner(false); // Set loading to false after fetching
        } else {
          setFilteredItems(myBids);
          setFilteredAuctions(myBids);
          setLoadingInner(false);
        }
      } else if (view === "purchases") {
        if (purchases === null) {
          await fetchPurchases(); // fetchPurchases sets 'filteredItems' and 'filteredAuctions'
          setLoadingInner(false); // Set loading to false after fetching
        } else {
          setFilteredItems(purchases);
          setFilteredAuctions(purchases);
          setLoadingInner(false);
        }
      } else if (view === "history") {
        if (pastAuctionItems === null) {
          setLoadingInner(true);

          setFilteredAuctions([]);
          await fetchHistory();
        } else {
          setFilteredItems(pastAuctionItems);
          // setFilteredAuctions(pastAuctionItems);
          setLoadingInner(false);
        }
      } else {
        setLoadingInner(true);
        // setFilteredItems(items);
        // Optionally handle other views
      }
    };

    handleView();
  }, [view, items, purchases, myBids, pastAuctionItems]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
    setViewPagination(prev => ({ ...prev, [view]: newOffset }));
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };

  const encodeOptions = (options: ISelectOption[]) =>
    options.map(o => `${o.value}:${encodeURIComponent(o.label)}`).join(",");

  const decodeOptions = (optionString: string) =>
    optionString.split(",").map(pair => {
      const [value, label] = pair.split(":");
      return { value, label: decodeURIComponent(label) };
    });
  const refreshItems = () => {
    getAllItems();
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Check if focus is within the dropdown and prevent closing if so
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.relatedTarget as Node)
    ) {
      setMoreDropdownOpen(false); // Close the dropdown
    }
  };
  const handleOptionSelect = (option: "history" | "guidelines") => {
    if (option === "history") {
      handleViewChange("history");
    } else if (option === "guidelines") {
      // Navigate to the Auction Guidelines page
      history.push("/auction-guidelines");
    }
    setMoreDropdownOpen(false);
  };
  /*const populateLocationOptions = useCallback(() => {
    const uniqueCountries = Array.from(
      new Set(items.map(item => item.uid?.country as string)),
    ).filter(Boolean);
    const countryOptions: ISelectOption[] = countries
      .filter(country => uniqueCountries.includes(country.value))
      .map(country => ({
        value: country.value,
        label: country.label,
      }));
    countryOptions.unshift({ value: "", label: "Everywhere" });
    setLocationOptions(countryOptions);
  }, []);*/
  useEffect(() => {
    // 1) Check if there's a stored "groupBySeller"
    const storedGroupBySeller = sessionStorage.getItem("groupBySeller");

    // 2) If it exists, setGroupBySeller
    if (storedGroupBySeller) {
      setGroupBySeller(storedGroupBySeller === "true");
    }

    // 3) Optionally remove it from sessionStorage if you only want it used once
    // sessionStorage.removeItem("groupBySeller");
  }, []);
  const handleComponentClick = () => {
    const scrollPosition = window.scrollY;
    const currentPageLocal = Math.floor(itemOffset / perPage);
    sessionStorage.setItem("itemsScrollPosition", scrollPosition.toString());
    sessionStorage.setItem("itemsCurrentPage", currentPageLocal.toString());

    sessionStorage.setItem("currentView", view);
  };

  useEffect(() => {
    // If user is no longer on "purchases," disable grouping
    if (view !== "purchases" && groupBySeller) {
      setGroupBySeller(false);
      sessionStorage.removeItem("groupBySeller");
    }
  }, [view, groupBySeller]);
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 100);
    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);

  useEffect(() => {
    if (!searchInProgress) {
      getAllItems();
    }
  }, []);

  const fetchPurchases = async () => {
    setShowPage(false);
    try {
      const res = await Api.getPurchasedItems();

      if (res.status === 200) {
        setPurchases(res.data.data);
      } else {
        setPurchases([]); // Handle non-200 responses
      }
    } catch (error) {
      console.error("Error fetching purchases:", error);
      setPurchases([]); // Handle errors gracefully
    }
    setRestoringSession(false);
    setShowPage(true);
  };
  const fetchUserActiveBids = async () => {
    setShowPage(false);
    try {
      const res = await Api.getUserCurrentBids();

      if (res.status === 200) {
        setMyBids(res.data.data);
      } else {
        setMyBids([]); // Handle non-200 responses
      }
    } catch (error) {
      console.error("Error fetching purchases:", error);
      setMyBids([]); // Handle errors gracefully
    }
    setRestoringSession(false);
    setShowPage(true);
  };
  const fetchHistory = async () => {
    setShowPage(false);
    setSearchInProgress(true);
    try {
      const res = await Api.getAllPastAuctions();

      if (res.status === 200) {
        setPastAuctionItems(res.data.data);
      } else {
        setPastAuctionItems([]); // Handle non-200 responses
      }
    } catch (error) {
      console.error("Error fetching purchases:", error);
      setPastAuctionItems([]); // Handle errors gracefully
    } finally {
      setSearchInProgress(false);
      setRestoringSession(false);
      setShowPage(true);
      setLoadingInner(false);
    }
  };
  const { t } = useTranslation();

  const getAllItems = async () => {
    const res = await Api.getAllActiveAuctions();
    if (res.status == 200) {
      const allActiveItems = res.data.data;
      setItems(allActiveItems);
      setUrlParamsLoaded(true);
    }
  };
  const purchasesGroupedBySeller = React.useMemo(() => {
    if (!groupBySeller) return [];
    // 1) Group each purchased item by seller ID
    const grouped: Record<string, { seller: any; items: any[] }> = {};
    purchases?.forEach(item => {
      const seller = item.uid; // Or wherever the seller data is stored
      if (!seller?._id) return;

      if (!grouped[seller._id]) {
        grouped[seller._id] = {
          seller,
          items: [],
        };
      }
      grouped[seller._id].items.push(item);
    });

    // 2) Convert into an array of { seller, sellerItems }
    return Object.values(grouped);
  }, [groupBySeller, purchases]);
  const handleGroupBySellerToggle = (checked: boolean) => {
    setGroupBySeller(checked);
    sessionStorage.setItem("groupBySeller", String(checked));
  };
  /*useEffect(() => {
    populateLocationOptions();
  }, [items, populateLocationOptions]);*/
  // NEW: Read filters from URL parameters on component mount
  /*useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Read categories from URL
    const categoriesParam = queryParams.get("categories");
    console.log("categoriesParam", categoriesParam);
    if (categoriesParam) {
      const selectedCategoriesFromURL = decodeOptions(categoriesParam);

      //setCurrentFilter("selectedCategories", selectedCategoriesFromURL);
      // setSelectedCategories(selectedCategoriesFromURL);
    }
    // Read countries from URL
    const countriesParam = queryParams.get("countries");
    if (countriesParam) {
      const selectedCountriesFromURL = decodeOptions(countriesParam);
      setSelectedCountries(selectedCountriesFromURL);
    }

    // Read years from URL
    const yearMinParam = queryParams.get("yearMin");
    const yearMaxParam = queryParams.get("yearMax");
    if (yearMinParam || yearMaxParam) {
      setYears({
        min: yearMinParam || "",
        max: yearMaxParam || "",
      });
    }

    // Read prices from URL
    const priceMinParam = queryParams.get("priceMin");
    const priceMaxParam = queryParams.get("priceMax");
    if (priceMinParam || priceMaxParam) {
      setPrices({
        min: priceMinParam || "",
        max: priceMaxParam || "",
      });
    }
    //setUrlParamsLoaded(true);
  }, []);*/
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const viewParam = queryParams.get("view");

    if (view !== viewParam) {
      const categoriesParam = queryParams.get("categories");

      if (categoriesParam) {
        const selectedCategoriesFromURL = decodeOptions(categoriesParam);

        setCurrentFilter("selectedCategories", selectedCategoriesFromURL);
      }
      return;
    }
    const categoriesParam = queryParams.get("categories");

    if (categoriesParam) {
      const selectedCategoriesFromURL = decodeOptions(categoriesParam);

      setCurrentFilter("selectedCategories", selectedCategoriesFromURL);
    }

    // Restore other filters similarly…
    const qParam = queryParams.get("q");
    if (qParam) {
      setCurrentFilter("search", qParam);
    }
    setUrlParamsLoaded(true);
    setShowPage(true);
    setLoadingInner(false);
  }, [location.search, view]);
  // NEW: Update URL parameters when filters change
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    //queryParams.set("view", view);
    // Remove existing filter parameters

    queryParams.delete("yearMin");
    queryParams.delete("yearMax");
    queryParams.delete("priceMin");
    queryParams.delete("priceMax");
    if (!loadingInner && !searchInProgress && urlParamsLoaded) {
      queryParams.delete("categories");
    }
    // Remove existing filter parameters

    // Remove existing countries parameter
    queryParams.delete("countries");

    // Add countries as value-label pairs
    if (selectedCountries.length > 0) {
      queryParams.set("countries", encodeOptions(selectedCountries));
    }
    // Add categories as label-value pairs
    if (currentFilters.selectedCategories.length > 0) {
      queryParams.set(
        "categories",
        encodeOptions(currentFilters.selectedCategories),
      );
    }

    if (years.min) {
      queryParams.set("yearMin", years.min.toString());
    }
    if (years.max) {
      queryParams.set("yearMax", years.max.toString());
    }

    if (prices.min) {
      queryParams.set("priceMin", prices.min.toString());
    }
    if (prices.max) {
      queryParams.set("priceMax", prices.max.toString());
    }

    // Preserve other query parameters like 'q' and 'seller'
    const qParam = q.get("q");

    if (qParam) {
      //setCurrentFilter("search", qParam);
      queryParams.set("q", qParam);
    }
    const sellerParam = q.get("seller");
    if (sellerParam) {
      queryParams.set("seller", sellerParam);
    }

    // Update the URL without reloading the page
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [selectedCategories, currentFilters, selectedCountries, years, prices]);
  useEffect(() => {
    if (!searchInProgress) {
      setLoadingInner(true);
      let updatedItems = items;

      if (selectedLocations.length > 0) {
        updatedItems = items.filter(item =>
          selectedLocations.includes(item.uid?.country),
        );
      }
      if (view === "all") {
        //setFilteredItems(updatedItems);
      }
      if (view === "history") {
        // setFilteredItems(updatedItems);
      }
      setLoadingInner(false);
    }
  }, [selectedLocations, items, view]);

  useEffect(() => {
    if (user) {
      setIsPhoneVerifiedLocal(user.isPhoneVerified);
    } else {
      setIsPhoneVerifiedLocal(true);
    }
    setPhoneVerifiedLoading(false);
  }, [user]);
  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem("itemsScrollPosition");
    const storedCurrentPage = sessionStorage.getItem("itemsCurrentPage");

    // Notice we don’t read `groupByBuyer` here
    if (storedScrollPosition !== null && storedCurrentPage !== null) {
      setItemOffset(parseInt(storedCurrentPage, 10) * perPage);
      setRestoreScrollPosition(parseInt(storedScrollPosition, 10));

      sessionStorage.removeItem("itemsScrollPosition");
      sessionStorage.removeItem("itemsCurrentPage");
      // sessionStorage.removeItem("groupByBuyer"); // <--- REMOVED
    }
  }, []);

  // 2) Create a separate effect to restore groupByBuyer if it exists
  useEffect(() => {
    const storedGroupBySeller = sessionStorage.getItem("groupBySeller");
    const storedView = sessionStorage.getItem("currentView");
    if (storedView) {
      setView(storedView as "all" | "myBids" | "purchases" | "history");

      if (storedGroupBySeller !== null) {
        setGroupBySeller(storedGroupBySeller === "true");
      }
    }

    sessionStorage.removeItem("groupBySeller");
    sessionStorage.removeItem("currentView");
    //setUrlParamsLoaded(true);
    // Up to you whether you remove it from sessionStorage or
    // preserve it for future visits. If you'd like it cleared once read:
  }, []);
  useEffect(() => {
    if (
      restoreScrollPosition !== null &&
      filteredItems.length > 0 &&
      urlParamsLoaded &&
      searchInProgress !== true
    ) {
      window.scrollTo(0, restoreScrollPosition);
      setRestoreScrollPosition(null);
      setRestoringSession(false);
    }
  }, [restoreScrollPosition, filteredItems, urlParamsLoaded, searchInProgress]);
  const handleMoreClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMoreDropdownOpen(prevState => !prevState);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setMoreDropdownOpen(false); // Close the dropdown
    }
  };

  useEffect(() => {
    // Attach the event listener to detect clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <AuctionsPageHelmet view={view} />
      <Header />
      <main>
        <div className="py-5 md:py-3 bg-primary2">
          <div className="mx-auto max-w-[1260px] px-1 w-full lg:min-h-[90vh]">
            <div className="px-6 mb-[1rem]">
              <div className="text-2xl md:text-3xl font-semibold mb-1 ltr:text-left rtl:text-right">
                <h1 className="collectibles-numismatics-coins-banknotes">
                  Numisnest - Israel online auction site, thousands of
                  banknotes, coins medals, collectible,and more. Some starts
                  from 1 ILS - {"Numisnest - Numismatics, Coins, Banknotes"}
                </h1>
                {t("Auctions")}
                {user?.isAuctioneer && user?.allAuctionsCount !== 0 && (
                  <LocalizedLink
                    to={`seller/${user?._id}/auctions`}
                    className="text-primary text-sm ml-2"
                    title="User's auctions"
                  >
                    {`(` + t("Switch to my auctions") + `)`}
                  </LocalizedLink>
                )}
              </div>

              <div className="flex items-center space-x-2">
                {/* Buttons for All Auctions, My Bids, Purchases */}
                <button
                  className={`whitespace-nowrap rtl:ml-[0.3rem] text-lg ${view === "all" ? "font-semibold" : ""}`}
                  onClick={() => handleViewChange("all")}
                >
                  {isMobile ? t("All") : t("All Auctions")}
                </button>
                <span className="">{"|"}</span>
                <button
                  className={`whitespace-nowrap text-lg ${view === "myBids" ? "font-semibold" : ""}`}
                  onClick={() => handleViewChange("myBids")}
                >
                  {t("My Bids")}
                </button>
                <span>{"|"}</span>
                <button
                  className={`whitespace-nowrap text-lg ${view === "purchases" ? "font-semibold" : ""}`}
                  onClick={() => handleViewChange("purchases")}
                >
                  {t("Purchases")}
                </button>

                <div>
                  <div
                    ref={dropdownRef}
                    className="relative inline-block"
                  >
                    <button
                      onClick={handleMoreClick}
                      className={`flex items-center hover:bg-gray-200 rounded-full py-1 ${view === "history" ? "font-semibold" : ""}`}
                      aria-label="More options"
                    >
                      <FaEllipsisV size={16} />
                      <span
                        className={`text-lg ${view === "history" ? "font-semibold" : ""}`}
                      >
                        {t("More")}
                      </span>
                    </button>

                    {/* Dropdown Menu */}
                    {isMoreDropdownOpen && (
                      <div
                        tabIndex={-1}
                        onBlur={handleBlur}
                        className="absolute ltr:right-1 rtl:left-1 md:ltr:left-3 md:rtl:right-3 top-7 w-48 bg-white border rounded-md shadow-lg z-[100000]"
                      >
                        <div
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleOptionSelect("history")}
                        >
                          {t("Auction History")}
                        </div>
                        <div
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleOptionSelect("guidelines")}
                        >
                          {t("Auction Guidelines")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {view === "purchases" && (
                <div
                  className={`flex items-center ${isMobile ? "justify-center" : "justify-end"} gap-6 ${isMobile ? "mt-1" : "mt-[-2rem]"}`}
                >
                  {/* Toggle grouping */}
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="groupBySeller"
                      checked={groupBySeller}
                      onChange={e =>
                        handleGroupBySellerToggle(e.target.checked)
                      }
                      className="form-checkbox h-5 w-5 text-primary"
                    />
                    <label
                      htmlFor="groupBySeller"
                      className="text-sm font-medium"
                    >
                      {t("Group Items by Seller")}
                    </label>
                  </div>
                </div>
              )}
              {(view === "history" || view === "all") && (
                <div className="">
                  <AuctionsFilter
                    filteredAuctions={filteredAuctions}
                    setFilteredAuctions={setFilteredAuctions}
                    selectedCurrency={currency}
                    setFilteredItems={setFilteredItems}
                    items={view === "history" ? pastAuctionItems || [] : items}
                    setSearchInProgress={setSearchInProgress}
                    searchInProgress={searchInProgress}
                    filteredItems={filteredItems}
                    search={currentFilters.search}
                    setSearch={val => setCurrentFilter("search", val)}
                    selectedCategories={currentFilters.selectedCategories}
                    setSelectedCategories={val =>
                      setCurrentFilter("selectedCategories", val)
                    }
                    selectedCountries={currentFilters.selectedCountries}
                    setSelectedCountries={val =>
                      setCurrentFilter("selectedCountries", val)
                    }
                    years={currentFilters.years}
                    setYears={val => setCurrentFilter("years", val)}
                    prices={currentFilters.prices}
                    setPrices={val => setCurrentFilter("prices", val)}
                    isLoading={loadingInner}
                    view={view}
                    urlParamsLoaded={urlParamsLoaded}
                    exchangeRates={exchangeRates}
                    setLoading={setLoadingInner}
                    setItemsOffset={setItemOffset}
                    convertedPrices={items.map(i => ({
                      id: i?._id,
                      convertedPrice: convert(i.price, i.currency, currency),
                      currency: i.selectedCurrency || currency,
                    }))}
                    setCurrencyChanged={setCurrencyChanged}
                    currencyChanged={currencyChanged}
                  />{" "}
                </div>
              )}

              {!loadingInner &&
                !phoneVerifiedLoading &&
                user &&
                !isPhoneVerifiedLocal && (
                  <button
                    onClick={openPhoneModal}
                    className="bg-orange-200 text-orange-900 max-md:w-[100%] md:max-w-[160px] align-center justify-center font-semibold px-3 py-1 rounded hover:bg-orange-300 hover:text-orange-900 text-sm font-medium transition duration-200 md:absolute md:ltr:right-[9rem] md:rtl:left-[9rem] md:top-20 z-50"
                    style={{ top: "5rem" }}
                  >
                    {t("Verify your phone to place your bid")}
                  </button>
                )}
            </div>

            {searchInProgress || loadingInner || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : filteredItems.length === 0 &&
              view !== "all" &&
              !restoringSession ? (
              <div className="flex justify-center items-center py-10">
                <p className="text-gray-500 text-lg">{t("No items")}</p>
              </div>
            ) : // ADDED: If “purchases” + groupBySeller => show SellerGroupTiles
            view === "purchases" && groupBySeller ? (
              <SellerGroupTiles
                sellerGroups={purchasesGroupedBySeller}
                onComponentClick={handleComponentClick}
                onViewClick={handleComponentClick}
                getViewLink={seller => `/auction/seller/${seller._id}`}
              />
            ) : (
              <div className="grid gap-2 max-md:min-h-[50vh] md:min-h-[70vh] md:gap-4 grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                {filteredAuctions
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <AuctionItem
                      key={item?._id || idx}
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      userOriginalBid={{
                        amount: item?.originalBidAmount || null,
                        currency: item?.originalCurrency || null,
                        bidStatus: item?.bidStatus || null,
                      }}
                      isCountry={item.isCountry}
                      isSeller={false}
                      name={item.name}
                      hasBid={item.hasBid}
                      uid={item?.uid?._id}
                      description={item.description}
                      country={item.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={item.currency}
                      price={convert(item.price, item.currency, item.currency)}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      endAt={item.endAt}
                      createdAt={item?.createdAt}
                      updatedAt={item?.updatedAt}
                      setCurrencyChanged={setCurrencyChanged}
                      currencyChanged={currencyChanged}
                      onItemClick={handleComponentClick}
                      bidStatus={item.bidStatus}
                      isSold={item.isSold}
                      remainingTime={item.remainingTime}
                      user_data={item.uid}
                      onAuctionEnd={refreshItems}
                    />
                  ))}
              </div>
            )}
          </div>

          <br />
          {!groupBySeller && (
            <div dir="ltr">
              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel={"<"}
                nextLabel={">"}
                renderOnZeroPageCount={null}
                containerClassName="flex flex-wrap justify-center mt-4"
                pageClassName="mx-2"
                pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                previousClassName="mx-2"
                previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                nextClassName="mx-2"
                nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                breakClassName="mx-2"
                breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                activeClassName="bg-slate-200 rounded-full "
                activeLinkClassName="font-semibold bg-slate-200"
              />
            </div>
          )}
        </div>
      </main>
      <PhoneVerificationModals
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        otpCode={otpCode}
        setOtpCode={setOtpCode}
        isPhoneModalOpen={isPhoneModalOpen}
        isOtpModalOpen={isOtpModalOpen}
        closePhoneModal={closePhoneModal}
        closeOtpModal={closeOtpModal}
        sendVerification={sendVerification}
        verifyCode={verifyCode}
        loading={loading}
      />
      <Footer />
    </>
  );
};
const Select = ({
  Icon,
  title,
  options,
  value,
  setValue,
}: {
  Icon: IconType;
  title: string;
  options: ISelectOption[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <Icon
        size={22}
        fill="var(--primary)"
      />
      <span className="font-medium text-sm text-primary">{title}</span>
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
        className="rounded-sm outline-none border border-primary px-3 py-2 text-sm"
      >
        {options.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
          >
            {itm.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default AuctionsPage;
