import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import Api from "../api/api"; // Your API utility for handling requests
import logo from "../assets/logo.avif"; // Update the logo path
import Button from "../common/Button"; // Reusable button component
import LocalizedLink from "../utils/LocalizedLink";
import { useTranslation } from "react-i18next";

const SignupConfirmation: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  // Extract the query parameter
  const searchParams = new URLSearchParams(location.search);
  const userEmail = searchParams.get("email") || null;
  const [isSending, setIsSending] = useState(false); // Loading state
  if (!userEmail) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 px-4">
        <div className="bg-white shadow-lg rounded-lg p-6 md:p-8 max-w-lg text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Invalid URL</h1>
          <p className="text-gray-600 text-sm md:text-base mb-4">
            The URL is invalid. Please check the link and try again.
          </p>
          <LocalizedLink
            to="/"
            className="text-sm font-medium text-primary hover:underline"
          >
            Back to Home page
          </LocalizedLink>
        </div>
      </div>
    );
  }

  const handleResendEmail = async () => {
    setIsSending(true);
    try {
      const res = await Api.sendVerificationEmail({ email: userEmail });
      if (res.status === 200) {
        toast.success("Verification email sent successfully.");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Failed to resend verification email.",
      );
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center max-md:min-h-[50vh] md:min-h-screen bg-gray-50 px-4">
      {/* Container */}
      <div className="bg-white shadow-lg rounded-lg p-6 md:p-8 max-w-lg text-center">
        {/* Logo */}
        <LocalizedLink to="/">
          <img
            src={logo}
            alt="Numisnest Logo"
            className="w-full mx-auto mb-4"
          />
        </LocalizedLink>
        {/* Heading */}
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Thank you for signing up!
        </h1>

        {/* Message */}
        <p className="text-gray-600 text-sm md:text-base mb-4">
          We sent a verification email to:{" "}
          <span className="font-medium text-gray-900">{userEmail}</span>
          <br />
          Please check your inbox or spam folder and click the link to verify
          your account.
        </p>

        {/* Resend Email Button */}
        <Button
          className="w-full md:w-auto bg-primary hover:bg-primary-dark text-white rounded-md px-4 py-2 transition-all"
          onClick={handleResendEmail}
          disabled={isSending}
        >
          {isSending ? "Sending..." : "Resend Verification Email"}
        </Button>

        {/* Back to Home */}
        <div className="mt-6">
          <LocalizedLink
            to="/"
            className="text-sm font-medium text-primary hover:underline"
          >
            {t("Back to Home page")}
          </LocalizedLink>
        </div>
      </div>
    </div>
  );
};

export default SignupConfirmation;
