import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// Example icons from react-icons (or any library you prefer):
import { FaHome, FaUser, FaStore, FaGavel } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/auth";
import { useFullScreen } from "../context/fullscreen";
import LocalizedLink from "../utils/LocalizedLink";

const MobileBottomNav: React.FC = () => {
  // If you have a more robust approach, e.g. a responsive hook or library, use that.
  // For demonstration, do a quick check of window size or your media query approach:
  const { t } = useTranslation();
  const { user, authLoading } = useAuth();
  const { isFullScreen } = useFullScreen();
  // If not mobile, return nothing or null.
  if (!isMobile || isFullScreen) return null;
  if (authLoading) return null;
  return (
    <nav
      className="bottom-[0]"
      style={{
        position: "fixed",

        left: 0,
        width: "100%",
        height: "60px",
        background: "#FFFFFF",
        borderTop: "1px solid #ccc",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        zIndex: 99999,
      }}
    >
      {/* Each navigation link */}
      <LocalizedLink
        to="/"
        style={linkStyle}
      >
        <FaHome
          fill={"var(--primary)"}
          size={24}
        />
        <span>{t("Home")}</span>
      </LocalizedLink>
      {user && (
        <LocalizedLink
          to={`/seller/${user?._id}`}
          style={linkStyle}
        >
          <FaUser
            fill={"var(--primary)"}
            size={24}
          />
          <span>{t("Profile")}</span>
        </LocalizedLink>
      )}
      <LocalizedLink
        to="/items"
        style={linkStyle}
      >
        <FaStore
          fill={"var(--primary)"}
          size={24}
        />
        <span>{t("Market")}</span>
      </LocalizedLink>
      <LocalizedLink
        to="/auctions"
        style={linkStyle}
      >
        <FaGavel
          fill={"var(--primary)"}
          size={24}
        />
        <span>{t("Auctions")}</span>
      </LocalizedLink>
      {!user && (
        <LocalizedLink
          to={`/auth/login`}
          style={linkStyle}
        >
          <FaUser
            fill={"var(--primary)"}
            size={24}
          />
          <span>{t("Log in")}</span>
        </LocalizedLink>
      )}
    </nav>
  );
};

// Inline example style for the nav links. You might prefer a CSS module or styled‐components:
const linkStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  color: "#000",
  fontSize: "0.75rem",
};

export default MobileBottomNav;
