import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Modal from "react-modal";
import "react-phone-input-2/lib/style.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import Api from "../../api/api";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Select from "../../common/Select";
import Auth from "../../layout/Auth";
import { ISignupData } from "../../types/auth";
import { countries, ROLES } from "../../utils/constant";
import LocalizedLink from "../../utils/LocalizedLink";

const initialData = {
  fname: "",
  lname: "",
  email: "",
  country: "",
  phone: "",
  about: "",
  password: "",
  confirmPassword: "",
};

const Signup: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<ISignupData>({ ...initialData });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const { t } = useTranslation();
  const fetchUserLocation = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const locationData = await response.json();
      const countryCode = locationData?.country || "IL";
      setData(prev => ({ ...prev, country: countryCode }));
    } catch (error) {
      console.error("Failed to fetch user location:", error);
      setData(prev => ({ ...prev, country: "IL" }));
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };
  const togglePasswordVisibility = (field: string) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  const handleGoogleSignIn = (response: any) => {
    const idToken = response.credential;
    const accessToken = response.accessToken;

    (window as any).googleToken = idToken;

    handleSubmitGoogleLogin(idToken);
  };
  const handleSubmitGoogleLogin = async (googleToken: string) => {
    try {
      const res = await Api.login({ googleToken: googleToken });
      if (res.status === 200) {
        const user = res?.data?.user;

        if (ROLES.ADMIN === user?.role) {
          window.location.replace("/admin/dashboard");
        } else {
          window.location.href = `/seller/${user._id}`;
        }
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    setIsDisable(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmPassword, ..._data } = data;
      const res = await Api.signup({
        ..._data,
        isNew: false,
        type: type || "seller",
      });
      if (res.status === 200) {
        history.push(`/signup-confirmation?email=${data.email}`);
        // setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);
  useEffect(() => {
    (window as any).google?.accounts?.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleSignIn,
      ux_mode: "popup",
      response_type: "token id_token",
      scope:
        "profile email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read",
    });
    (window as any).google?.accounts?.id.renderButton(
      document.getElementById("googleSignInBtn"),
      {
        theme: "outline",
        size: "large",
        width: isMobile ? "270" : "400",
        text: "continue_with",
      },
    );
  }, []);
  useEffect(() => {
    fetchUserLocation();
  }, []);
  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);
  return (
    <Auth
      title="Create an Account"
      submitHandle={handleSubmit}
    >
      <div className="max-w-full mt-[-1rem]">
        {/* Flexbox for name fields */}
        <div className="flex flex-col sm:flex-row sm:gap-4 sm:items-center mt-4">
          <Input
            className="flex-1 mb-1 sm:mt-[0.3rem] sm:mb-0"
            title={t("First Name") + "*"}
            name="fname"
            value={data?.fname}
            onChange={handleInputChange}
            placeholder={t("Enter First Name")}
            required
          />
          <Input
            className="flex-1 sm:mt-[0.2rem] sm:mb-0"
            title={t("Last Name") + "*"}
            name="lname"
            value={data?.lname}
            onChange={handleInputChange}
            placeholder={t("Enter Last Name")}
            required
          />
        </div>

        <Input
          type="email"
          title={t("Email") + "*"}
          name="email"
          value={data?.email}
          onChange={handleInputChange}
          placeholder={t("Enter Email")}
          required
          className="!mt-1.5"
        />

        <Select
          title={t("Country") + "*"}
          name="country"
          value={data?.country}
          onChange={handleInputChange}
          required
          options={countries}
          className="!mt-1.5"
        />

        <div className="relative">
          <Input
            type={showPassword.password ? "text" : "password"}
            title={t("Password") + "*"}
            name="password"
            value={data?.password}
            onChange={handleInputChange}
            placeholder={t("Enter Password")}
            required
          >
            <button
              type="button"
              onClick={() => togglePasswordVisibility("password")}
              className="absolute top-[65%] ltr:right-4 rtl:left-4 transform -translate-y-1/2 text-gray-500 focus:outline-none"
            >
              {showPassword.password ? (
                <FaEyeSlash fill={"var(--primary)"} />
              ) : (
                <FaEye fill={"var(--primary)"} />
              )}
            </button>
          </Input>
        </div>
        <div className="relative">
          <Input
            type={showPassword.password ? "text" : "password"}
            title={t("Confirm Password") + "*"}
            name="confirmPassword"
            value={data?.confirmPassword}
            onChange={handleInputChange}
            placeholder={t("Confirm Password")}
            required
          >
            <button
              type="button"
              onClick={() => togglePasswordVisibility("password")}
              className="absolute top-[65%] ltr:right-4 rtl:left-4 transform -translate-y-1/2 text-gray-500 focus:outline-none"
            >
              {showPassword.password ? (
                <FaEyeSlash fill={"var(--primary)"} />
              ) : (
                <FaEye fill={"var(--primary)"} />
              )}
            </button>
          </Input>
        </div>
        <div
          style={{ fontSize: isMobile ? "0.65rem" : "0.75rem" }}
          className="flex whitespace-nowrap  min-md:text-xs flex gap-1 items-center justify-center text-secondary mt-4"
        >
          {t("By Signing up, you agree to our")}{" "}
          <LocalizedLink
            to="/disclaimer"
            className="text-primary font-medium"
          >
            {t("Terms of Service")}
          </LocalizedLink>
          {" & "}
          <LocalizedLink
            to="/privacy-policy"
            className="text-primary font-medium"
          >
            {t("Privacy Policy")}
          </LocalizedLink>
        </div>
        <Button
          className="mt-3 mb-2 justify-center flex items-center max-w-[400px] sm:max-w-[270px] md:max-w-[400px] mx-auto rounded-sm"
          type="submit"
          disabled={isDisable}
        >
          {t("Sign up")}
        </Button>
        <div className="flex items-center justify-center ">
          <div
            id="googleSignInBtn"
            className="mb-1 items-center justify-center"
          ></div>
        </div>
        <div className="text-sm flex gap-1 items-center justify-center text-secondary mt-4">
          {t("Already a member?")}
          <LocalizedLink
            to="/auth/login"
            className="text-primary font-medium"
          >
            {t("Log in")}
          </LocalizedLink>
        </div>
        {!isMobile && (
          <div className="mt-4 text-center">
            <LocalizedLink
              to="/"
              className="text-sm font-medium text-primary"
            >
              {t("Go back to home page")}
            </LocalizedLink>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          history.push("/"); // Redirect to homepage on modal close
        }}
        contentLabel="Signup Confirmation"
        className=" fixed  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6 z-50 outline-none justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <h2 className="text-lg font-semibold">{t("Check Your Email")}</h2>
        <p className="mt-2">
          {t(
            "Please check your email and spam folder for the confirmation link",
          )}
          .
        </p>
        <Button
          className="mt-4"
          onClick={() => {
            setIsModalOpen(false);
            history.push("/auth/login");
          }}
        >
          {t("Okay")}
        </Button>
      </Modal>
    </Auth>
  );
};

export default Signup;
