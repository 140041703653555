import React from "react";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IBasketSubItem } from "../../hooks/useBasket";
import { useCurrency } from "../../context/currency";
import { handlePrice } from "../../utils/handlePrice";
import LocalizedLink from "../../utils/LocalizedLink";

interface BasketItemsProps {
  items: IBasketSubItem[];
  removingItemId: string | null;
  onRemove: (id: string) => void;
  isSentAt?: boolean;
}

const BasketItems: React.FC<BasketItemsProps> = ({
  items,
  removingItemId,
  onRemove,
  isSentAt,
}) => {
  const { t } = useTranslation();
  const { convert, userCurrency } = useCurrency();

  // Separate unpaid and paid items
  const unpaidItems = items.filter(item => !item.paid);
  const paidItems = items.filter(item => item.paid);

  // Sort unpaid items: non-deleted items come first, then deleted ones.
  const sortedUnpaidItems = [...unpaidItems].sort((a, b) =>
    a.deletedBySeller === b.deletedBySeller ? 0 : a.deletedBySeller ? 1 : -1,
  );

  // Group paid items by the day they were paid on.
  const paidGroups = paidItems.reduce<Record<string, IBasketSubItem[]>>(
    (groups, basketItem) => {
      const dateKey = basketItem.paidOn
        ? new Date(basketItem.paidOn).toLocaleDateString()
        : t("Unknown Date");
      if (!groups[dateKey]) groups[dateKey] = [];
      groups[dateKey].push(basketItem);
      return groups;
    },
    {},
  );

  // Render a single basket item. `showRemove` controls whether the remove button is rendered.
  const renderItem = (
    basketItem: IBasketSubItem,
    idx: number,
    showRemove: boolean,
  ) => {
    const { item } = basketItem;
    const isDeleted = basketItem.deletedBySeller === true;
    const itemNameClass = isDeleted
      ? "line-through text-red-500"
      : "font-medium text-lg";
    const isRemoveDisabled = isDeleted || removingItemId === item._id;

    return (
      <li
        key={item._id}
        className="flex flex-wrap items-center border-b pb-3 gap-4"
      >
        <LocalizedLink to={`/item/${item._id}`}>
          {item.photos && item.photos[0]?.url && (
            <img
              src={item.photos[0].url}
              alt={item.name}
              className={`w-16 h-16 object-cover rounded ${
                isDeleted ? "opacity-50" : ""
              }`}
            />
          )}
        </LocalizedLink>
        <div className="flex-1">
          <p
            className={itemNameClass}
            dir="auto"
          >
            {idx + 1}. {item.name}
          </p>
          <p className="text-gray-700 text-sm mt-1">
            {t("Price")}:{" "}
            <strong className={isDeleted ? "line-through text-red-500" : ""}>
              {isDeleted
                ? item.price
                : convert(item.price, item.currency, userCurrency)}{" "}
              {t(userCurrency)}
            </strong>
          </p>
          {isDeleted && (
            <p className="text-xs text-red-500 font-semibold">
              {t("Deleted by seller")}
            </p>
          )}
        </div>
        {showRemove && (
          <button
            onClick={() => onRemove(item._id)}
            disabled={isRemoveDisabled}
            className={`text-red-500 hover:text-red-700 ${
              isRemoveDisabled ? "opacity-40 cursor-not-allowed" : ""
            }`}
            aria-label={t("Remove item")}
          >
            <FaTrash />
          </button>
        )}
      </li>
    );
  };

  return (
    <div>
      {sortedUnpaidItems.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">{t("Unpaid items")}</h3>
          <ul className="space-y-3">
            {sortedUnpaidItems.map((basketItem, idx) =>
              renderItem(basketItem, idx, true),
            )}
          </ul>
        </div>
      )}
      {paidItems.length > 0 && (
        <div>
          <h3 className="text-xl font-semibold mb-3">{t("Paid items")}</h3>
          {Object.keys(paidGroups).map(dateKey => {
            const groupItems = paidGroups[dateKey];
            const groupTotal = groupItems.reduce((acc, basketItem) => {
              return (
                acc +
                convert(
                  basketItem.item.price,
                  basketItem.item.currency,
                  userCurrency,
                )
              );
            }, 0);
            return (
              <div
                key={dateKey}
                className="mb-4"
              >
                <h4 className="text-lg font-medium mb-2">
                  {t("Paid on")} {dateKey}
                </h4>
                <div className="mb-2 text-right font-bold">
                  {t("Paid")}: {handlePrice(groupTotal)} {t(userCurrency)}
                </div>
                <ul className="space-y-3">
                  {groupItems.map((basketItem, idx) =>
                    renderItem(basketItem, idx, false),
                  )}
                </ul>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BasketItems;
