import { IUser } from "../types/auth";
import { IUserProfile } from "../types/user";

export function toUserProfile(user: IUser): IUserProfile {
  return {
    _id: user._id,
    fname: user.fname,
    lname: user.lname,
    avatar: user.avatar,
    email: user.email,
    phone: user.phone,
    paymentOption: user.paymentOption,
  };
}
