import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";
import { MultiSelect } from "react-multi-select-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Api from "../../api/api";
import { useCurrency } from "../../context/currency";
import { useLocationContext } from "../../context/location";
import { ISelectOption } from "../../types/common";
import { sortedCountries as countries } from "../../utils/constant";
import Search from "../Search";
import { isMobile } from "react-device-detect";

const Filters: React.FC<{
  items: any;
  search: string;
  isLoading: boolean;
  usedCategories?: any;
  setFilteredItems: React.Dispatch<React.SetStateAction<any[]>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  selectedCategories: any;
  setSelectedCategories: React.Dispatch<any>;
  selectedCountries: any;
  selectedLocations?: any;
  setSelectedCountries: React.Dispatch<any>;
  setLoading?: React.Dispatch<any>;
  years: { min: string | number; max: string | number };
  filteredItems?: any;
  setYears: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  prices: { min: string | number; max: string | number };
  setPrices: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  urlParamsLoaded?: boolean;
  setItemsOffset?: any;
  isSellerItems?: boolean;
  selectedCurrency?: string;
  convertedPrice?: any;
  convertedPrices?: any;
  currency?: any;
  exchangeRates: any;
  setSearchInProgress: any;
  searchInProgress: any;
  setCurrencyChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  currencyChanged?: boolean;
}> = ({
  setFilteredItems,
  search,
  usedCategories,
  filteredItems,
  setSearch,
  setLoading,
  selectedCategories,
  setSelectedCategories,
  selectedCountries,
  setSelectedCountries,
  years,
  setYears,
  prices,
  setPrices,
  isSellerItems = false,
  items,
  urlParamsLoaded,
  convertedPrices,
  setItemsOffset = () => {},
  currency,
  isLoading = false,
  setSearchInProgress,
  exchangeRates,
  searchInProgress,
  selectedCurrency,
  setCurrencyChanged,
  currencyChanged,
}) => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const query = q.get("q") || "";
  const history = useHistory();
  const id = useParams<any>();
  const { t } = useTranslation();
  const { userCurrency, convert } = useCurrency();
  const [availableCategories, setAvailableCategories] = useState([]);
  const [categories, setCategories] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState(query);
  const [searchResults, setSearchResults] = useState<any[] | null>(null);
  const prevUserCurrencyRef = useRef(userCurrency);
  const [isRestored, setIsRestored] = useState(false);
  const { selectedLocation: selectedLocations } = useLocationContext();
  const getUniqueCountriesFromItems = () => {
    const uniqueCountries = Array.from(
      new Set(items.flatMap((item: any) => item.countries)),
    );

    // Filter the full countries list to only include those present in the items
    return countries.filter(country => uniqueCountries.includes(country.value));
  };
  const categoryRef = useRef<HTMLDivElement>(null);
  const [convertedPricesFilter, setConvertedPricesFilter] =
    useState(convertedPrices);
  const [filteredCountries, setFilteredCountries] = useState<ISelectOption[]>(
    getUniqueCountriesFromItems(),
  );
  const getAvailableCategories = () => {
    // Get unique categories from filteredItems
    const uniqueCategoryIds = Array.from(
      new Set(
        filteredItems.flatMap(item =>
          item.categories.map(category => category._id),
        ),
      ),
    );

    // Filter the existing category list based on available categories
    const filteredCategories = categories.filter(category =>
      uniqueCategoryIds.includes(category.value),
    );

    // Update the available categories
    setAvailableCategories(filteredCategories);
  };

  async function getAllCategories() {
    if (usedCategories.length > 0) return;
    const res = await Api.getUsedCategories();
    if (res.status == 200) {
      const temp = res?.data?.data?.map((category: any) => ({
        label: category.name,
        value: category._id,
      }));
      setCategories(
        temp.sort((a: any, b: any) => {
          return a.position - b.position;
        }),
      );
    }
  }
  const handleMenuOpen = (ref: React.RefObject<HTMLDivElement>) => {
    // Check if the viewport width is less than or equal to 768px (typical mobile width)
    if (isMobile && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const translatedCategories = usedCategories.map(category => ({
    ...category,
    label: t(category.label),
  }));

  const translatedCountries = filteredCountries.map(country => ({
    ...country,
    label: t(country.label),
  }));
  useEffect(() => {
    if (
      prevUserCurrencyRef.current &&
      prevUserCurrencyRef.current !== userCurrency
    ) {
      // Currency has changed
      const prevCurrency = prevUserCurrencyRef.current;

      const newPrices = { ...prices };

      if (prices.min) {
        const minValue = parseFloat(prices.min as string);
        if (!isNaN(minValue)) {
          const newMin = convert(minValue, prevCurrency, userCurrency);
          newPrices.min = newMin;
        }
      }

      if (prices.max) {
        const maxValue = parseFloat(prices.max as string);
        if (!isNaN(maxValue)) {
          const newMax = convert(maxValue, prevCurrency, userCurrency);
          newPrices.max = newMax;
        }
      }

      //setPrices(newPrices);
    }

    // Update the previous currency
    prevUserCurrencyRef.current = userCurrency;
  }, [userCurrency]);

  useEffect(() => {
    setCategories(usedCategories);
  }, [usedCategories]);

  useEffect(() => {
    // Update the filtered countries whenever items change
    setFilteredCountries(getUniqueCountriesFromItems());
  }, [items]);
  useEffect(() => {
    // Function to convert prices
    const recalculateConvertedPrices = () => {
      const newConvertedPrices = items.map(item => {
        const convertedPrice = convert(item.price, item.currency, userCurrency);
        return {
          id: item._id,
          convertedPrice,
        };
      });
      setConvertedPricesFilter(newConvertedPrices);
    };

    recalculateConvertedPrices();
  }, [items, userCurrency, exchangeRates]);
  useEffect(() => {
    setSearch(search);
  }, [search, setSearch]);
  const handleSearch = async () => {
    if (setLoading) setLoading(true);
    setSearchInProgress(true);

    if (searchQuery.trim()) {
      let response;
      if (isSellerItems) {
        response = await Api.searchUserItems(id.id, searchQuery);
      } else {
        response = await Api.searchItems(searchQuery);
      }
      if (response?.status === 200 && Array.isArray(response.data.data)) {
        let results = response.data.data;
        if (selectedLocations && selectedLocations.length > 0) {
          results = results.filter(item =>
            selectedLocations.includes(item.uid?.country),
          );
        }

        setSearchResults(results);

        setSearchInProgress(false);
      } else {
        setSearchResults([]);
        // setFilteredItems([]);
        setSearchInProgress(false);
      }
    } else {
      setSearchInProgress(false);
      setSearchResults(null);
    }
    const params = new URLSearchParams(location.search);
    params.set("q", searchQuery);
    history.replace({ search: params.toString() });
    if (setLoading) setLoading(false);
  };
  useEffect(() => {
    if (!searchInProgress && urlParamsLoaded && items && items.length > 0) {
      applyFilters();
    }
  }, [
    search,
    selectedCategories,
    selectedCountries,
    years,
    urlParamsLoaded,
    userCurrency,
    items,
    prices,
    searchResults,
    selectedCurrency,

    searchInProgress,
    currency,
    currencyChanged,
  ]);

  useEffect(() => {
    if (query) {
      if (setLoading) setLoading(true);
      setSearchQuery(query);
      handleSearch();
      if (setLoading) setLoading(false);
    }
    // setUrlParamsLoaded(true);
  }, [query, items]);
  const handleSearchInputChange = (value: string) => {
    setSearchQuery(value);
  };

  const handleSearchKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter" || event.key === "Next") {
      event.preventDefault();
      await handleSearch();
    }
  };
  const onSearchClear = () => {
    setSearchQuery("");
    setSearchResults(null);
    setSearch("");
    applyFilters(items);
  };
  const applyFilters = (
    itemsToFilter: any[] = searchResults !== null ? searchResults : items,
  ) => {
    let filtered: any = [...itemsToFilter];

    if (currencyChanged) {
      if (setCurrencyChanged) setCurrencyChanged(false);
    }
    /*const keywords = search.trim().toLowerCase().split(/\s+/);
    if (keywords.length > 0) {
      filtered = filtered.filter(item => {
        return keywords.some(
          keyword =>
            item?.name?.toLowerCase().includes(keyword) ||
            item?.year?.toString().includes(keyword) ||
            item?.category?.toLowerCase().includes(keyword) ||
            item?.country?.toLowerCase().includes(keyword) ||
            item?.sellerName?.toLowerCase().includes(keyword),
        );
      });
    }*/

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(item =>
        selectedCategories.some((category: any) =>
          item.categories.some(
            (itemCategory: any) => itemCategory._id === category.value,
          ),
        ),
      );
    }
    if (selectedCountries.length > 0) {
      filtered = filtered.filter(item =>
        selectedCountries.some((country: any) =>
          item.countries.includes(country.value),
        ),
      );
    }

    if (years.min || years.max) {
      const beforeRegex = /^before\s+(\d{1,4})$/i;
      const afterRegex = /^after\s+(\d{1,4})$/i;

      filtered = filtered.filter(item => {
        const itemYears = item.years || []; // Fallback to an empty array if item.years is null or undefined

        // Exclude items without years
        if (itemYears.length === 0) return false;

        return itemYears.some(itemYear => {
          if (!itemYear) return false; // Skip null or undefined years

          let isValid = false;

          if (typeof itemYear !== "string") {
            itemYear = itemYear.toString(); // Ensure itemYear is a string
          }

          // Handle year ranges (e.g., "1900-1905")
          if (itemYear.includes("-")) {
            const [startYear, endYear] = itemYear.split("-").map(Number);
            const isInRange =
              (!years.min || startYear >= parseInt(years.min.toString(), 10)) &&
              (!years.max || endYear <= parseInt(years.max.toString(), 10));
            isValid = isInRange;
          }

          // Handle "Before (year)"
          const beforeMatch = itemYear.match(beforeRegex);
          if (beforeMatch) {
            const beforeYear = parseInt(beforeMatch[1], 10);
            isValid =
              (!years.min ||
                beforeYear >= parseInt(years.min.toString(), 10)) &&
              (!years.max || beforeYear < parseInt(years.max.toString(), 10));
          }

          // Handle "After (year)"
          const afterMatch = itemYear.match(afterRegex);
          if (afterMatch) {
            const afterYear = parseInt(afterMatch[1], 10);
            isValid =
              (!years.min || afterYear > parseInt(years.min.toString(), 10)) &&
              (!years.max || afterYear <= parseInt(years.max.toString(), 10));
          }

          // Handle exact years (e.g., "1952")
          const exactYear = parseInt(itemYear, 10);
          if (!isNaN(exactYear)) {
            const isExactMatch =
              (!years.min || exactYear >= parseInt(years.min.toString(), 10)) &&
              (!years.max || exactYear <= parseInt(years.max.toString(), 10));
            isValid = isValid || isExactMatch;
          }

          return isValid;
        });
      });
    }

    if (prices.min || prices.max) {
      filtered = filtered.filter(item => {
        const convertedPrice = convert(item.price, item.currency, userCurrency);
        const isWithinMinBound = prices.min
          ? convertedPrice >= Number(prices.min)
          : true;
        const isWithinMaxBound = prices.max
          ? convertedPrice <= Number(prices.max)
          : true;
        return isWithinMinBound && isWithinMaxBound;
      });
    }

    if (!isSellerItems && selectedLocations && selectedLocations.length > 0) {
      filtered = filtered.filter(item =>
        selectedLocations.includes(item.uid?.country),
      );
    }
    const perfectMatchItems = filtered.filter(item => item.perfectMatch);

    isRestored ? setItemsOffset(0) : setIsRestored(true);

    setFilteredItems(filtered);
  };

  useEffect(() => {
    if (filteredItems?.length > 0) {
      getAvailableCategories();
    }
  }, [filteredItems]);

  return (
    <div className="mb-10 mt-4 max-w-[1260px]">
      <div className="md:flex gap-3 lg:flex-row max-lg:flex-wrap  items-end">
        <Search
          value={searchQuery}
          onChange={e => handleSearchInputChange(e.target.value)}
          onKeyDown={handleSearchKeyPress}
          onSearchIconClick={handleSearch}
          onClear={onSearchClear}
        />
        <div
          style={{
            // Directly apply background color
            zIndex: 10000, // Apply zIndex here
          }}
          ref={categoryRef}
          onClick={() => handleMenuOpen(categoryRef)}
          onFocus={() => handleMenuOpen(categoryRef)}
          className="flex items-center gap-3 w-full sm:w-full"
        >
          <Select
            title="Category"
            selected={selectedCategories}
            setSelected={setSelectedCategories}
            options={translatedCategories}
          />
          <Select
            title="Country"
            selected={selectedCountries}
            setSelected={setSelectedCountries}
            options={translatedCountries}
          />
        </div>
        <div className="flex items-center gap-3 sm:w-full ">
          <Year
            value={years}
            setValue={setYears}
          />
          <Price
            value={prices}
            setValue={setPrices}
            selectedCurrency={userCurrency}
          />
        </div>
      </div>
      <div className="mt-2 text-primary">
        {t("Items")}: {isRestored ? filteredItems?.length : "-"}
      </div>
      <div className="mt-4 flex gap-3 items-center flex-wrap">
        {selectedCategories.map((itm, idx) => (
          <div
            key={idx}
            className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1"
          >
            <span className="text-sm text-primary">{itm.label}</span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() =>
                setSelectedCategories(prev =>
                  prev?.filter(item => item.value !== itm.value),
                )
              }
            />
          </div>
        ))}
        {selectedCountries.map((itm, idx) => (
          <div
            key={idx}
            className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1"
          >
            <span className="text-sm text-primary">{itm.label}</span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() =>
                setSelectedCountries(prev =>
                  prev?.filter(item => item.value !== itm.value),
                )
              }
            />
          </div>
        ))}
        {(years?.max || years?.min) && (
          <div className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1">
            <span className="text-sm text-primary">
              {years.min} - {years.max}
            </span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() => setYears({ min: "", max: "" })}
            />
          </div>
        )}
        {(prices?.max || prices?.min) && (
          <div className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1">
            <span className="text-sm text-primary">
              {prices.min} - {prices.max}
            </span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() => setPrices({ min: "", max: "" })}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const CustomSearchInput = (props: any) => {
  return (
    <input
      {...props}
      autoFocus={false}
    />
  );
};
const Select = ({
  title,
  options,
  selected,
  setSelected,
}: {
  title: string;
  options: ISelectOption[];
  selected: any;
  setSelected: React.Dispatch<any>;
}) => {
  const { t } = useTranslation();

  return (
    <div
      dir="ltr"
      className="grid place-items-center   w-full sm:max-w-[152px] mt-3 md:max-w-[200px]"
    >
      <span className="text-primary font-semibold">{t(title)}</span>

      <MultiSelect
        value={selected}
        onChange={setSelected}
        hasSelectAll={false}
        options={options}
        labelledBy={title}
        closeOnChangedValue
        overrideStrings={{
          selectSomeItems: t("Select..."), // Custom placeholder
          allItemsAreSelected: t("All selected"),
          selectAll: t("Select All"),
          search: t("Search"),
        }}
        className="multi_select blog-content-scrollbar scrollable w-full max-md:max-w-[152px]"
        // disableSearch // This disables the search input field
      />
    </div>
  );
};
const Year: React.FC<{
  value: { min: string | number; max: string | number };
  setValue: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
}> = ({ value, setValue }) => {
  const { t } = useTranslation();
  const [localMin, setLocalMin] = useState(value.min);
  const [localMax, setLocalMax] = useState(value.max);
  useEffect(() => {
    setLocalMin(value.min);
    setLocalMax(value.max);
  }, [value]);
  // Handle min value change on input
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMin(e.target.value);
  };

  // Handle max value change on input
  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMax(e.target.value);
  };

  // Apply the min filter or clear if the field is empty
  const applyMinValue = () => {
    if (!localMin) {
      // Clear the min value if empty
      setLocalMin("");
      setValue(prev => ({
        ...prev,
        min: "",
      }));
    } else if (!localMax || Number(localMin) <= Number(localMax)) {
      // Apply the min value if it's valid
      setValue(prev => ({
        ...prev,
        min: localMin,
      }));
    }
  };

  // Apply the max filter or clear if the field is empty
  const applyMaxValue = () => {
    if (!localMax) {
      // Clear the max value if empty
      setLocalMax("");
      setValue(prev => ({
        ...prev,
        max: "",
      }));
    } else if (!localMin || Number(localMax) >= Number(localMin)) {
      // Apply the max value if it's valid
      setValue(prev => ({
        ...prev,
        max: localMax,
      }));
    }
  };

  return (
    <div className="grid place-items-center   w-full sm:max-w-[150px] mt-3 md:max-w-[200px]">
      <span className="text-primary font-semibold">{t("Year")}</span>
      <div className="flex items-center justify-center gap-1.5 md:gap-3 w-full rounded-md bg-white border border-primary px-2 md:px-3 py-2 text-sm">
        <input
          type="number"
          value={localMin}
          placeholder={t("Min")}
          min="0"
          onChange={handleMinChange}
          onBlur={applyMinValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMinValue()} // Apply the filter on enter
          className="bg-[#F5F5F5] text-center w-[50px] font-semibold  md:text-base no-spinner md:w-[50px] px-0 outline-none rounded border border-primary"
        />
        <span className="font-semibold">-</span>
        <input
          type="number"
          value={localMax}
          placeholder={t("Max")}
          min={Number(localMin) || 0}
          onChange={handleMaxChange}
          onBlur={applyMaxValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMaxValue()} // Apply the filter on enter
          className="bg-[#F5F5F5] text-center w-[50px] font-semibold  md:text-base no-spinner md:w-[50px] px-0 outline-none rounded border border-primary"
        />
      </div>
    </div>
  );
};
const Price: React.FC<{
  value: { min: string | number; max: string | number };
  setValue: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  selectedCurrency?: string;
}> = ({ value, setValue, selectedCurrency }) => {
  const { t } = useTranslation();
  const currencySymbol =
    selectedCurrency === "USD" ? "$" : selectedCurrency === "EUR" ? "€" : "₪";
  // Local states for price min and max
  const [localMin, setLocalMin] = useState(value.min);
  const [localMax, setLocalMax] = useState(value.max);
  useEffect(() => {
    setLocalMin(value.min);
    setLocalMax(value.max);
  }, [value]);
  // Handle min value change on input
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMin(e.target.value);
  };

  // Handle max value change on input
  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMax(e.target.value);
  };

  // Apply the min filter or clear if the field is empty
  const applyMinValue = () => {
    if (!localMin) {
      // Clear the min value if empty
      setLocalMin("");
      setValue(prev => ({ ...prev, min: "" }));
    } else if (!localMax || Number(localMin) <= Number(localMax)) {
      // Apply the min value if it's valid
      setValue(prev => ({ ...prev, min: localMin }));
    }
  };

  // Apply the max filter or clear if the field is empty
  const applyMaxValue = () => {
    if (!localMax) {
      // Clear the max value if empty
      setLocalMax("");
      setValue(prev => ({ ...prev, max: "" }));
    } else if (!localMin || Number(localMax) >= Number(localMin)) {
      // Apply the max value if it's valid
      setValue(prev => ({ ...prev, max: localMax }));
    }
  };
  return (
    <div className="grid place-items-center w-full sm:max-w-[100px] mt-3 md:max-w-[200px] ">
      <span className="text-primary font-semibold">{t("Price")}</span>
      <div className="flex items-center md:text-base  justify-center gap-1.5 md:gap-3 w-full rounded-md bg-white border border-primary px-2 md:px-3 py-2 text-sm">
        <span>{currencySymbol}</span>
        <input
          type="number"
          placeholder={t("Min")}
          value={localMin}
          min={0}
          style={{ MozAppearance: "textfield", WebkitAppearance: "none" }}
          onChange={handleMinChange}
          onBlur={applyMinValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMinValue()} // Apply the filter on enter
          className="bg-[#F5F5F5] md:text-base text-center font-semibold no-spinner w-[50px] md:w-[60px] px-1 outline-none rounded border border-primary"
        />
        <span className="font-semibold">-</span>
        <input
          type="number"
          placeholder={t("Max")}
          value={localMax}
          min={Number(localMin) || 0} // Ensure max is always >= min
          style={{ MozAppearance: "textfield", WebkitAppearance: "none" }}
          onChange={handleMaxChange}
          onBlur={applyMaxValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMaxValue()} // Apply the filter on enter
          className="bg-[#F5F5F5]  md:text-base text-center font-semibold no-spinner w-[50px] md:w-[60px] px-1 outline-none rounded border border-primary"
        />
      </div>
    </div>
  );
};
export default Filters;
