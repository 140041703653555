import React, { useEffect, useState } from "react";
import { FaCartPlus, FaShoppingBasket } from "react-icons/fa";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";

interface AddBasketButtonProps {
  // Accept either a string (seller ID) or an object with an _id property.
  itemId?: string;
  /** Initial basket state (true if already added) from the backend */
  isAdded?: boolean;
  seller?: string | { _id: string };
}

const LoadingDots: React.FC = () => (
  <span className="loading-dots">
    <span className="dot">.</span>
    <span className="dot">.</span>
    <span className="dot">.</span>
  </span>
);

const AddBasketButton: React.FC<AddBasketButtonProps> = ({
  itemId,
  isAdded: initialIsAdded = false,
  seller,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  // Maintain local state for the added status.
  const [added, setAdded] = useState<boolean>(initialIsAdded);
  const [loading, setLoading] = useState<boolean>(false);

  // Sync local state if the prop changes (e.g. after a refresh/fetch)
  useEffect(() => {
    setAdded(initialIsAdded);
  }, [initialIsAdded, itemId]);

  // Extract sellerId as a string.
  const sellerId: string | undefined =
    typeof seller === "object" ? seller?._id : seller;

  // Do not render if the current user is the seller.
  if (sellerId && user && user._id === sellerId) return null;

  const handleToggleBasket = async () => {
    if (loading || !itemId) return;
    setLoading(true);
    try {
      if (!added) {
        const res = await Api.addItemToBasket({ itemId, seller: sellerId });
        if (res.status === 200) {
          toast.success(t("Item added to basket"));
          setAdded(true);
        } else {
          toast.error(res.message || t("Error adding item to basket"));
        }
      } else {
        const res = await Api.removeItemFromBasket(itemId, {
          seller: sellerId,
        });
        if (res.status === 200) {
          toast.success(t("Item removed from basket"));
          setAdded(false);
        } else {
          toast.error(res.message || t("Error removing item from basket"));
        }
      }
    } catch (error: any) {
      console.error("Error toggling basket:", error);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = loading || !itemId;

  return (
    <button
      onClick={handleToggleBasket}
      disabled={isDisabled}
      className={`flex items-center gap-2 px-2 py-1 rounded transition-colors ${
        added
          ? "bg-red-500 text-white hover:bg-red-600"
          : "text-primary hover:bg-gray-100"
      } ${!itemId ? "opacity-50 cursor-not-allowed" : ""}`}
      aria-label={
        !itemId
          ? t("Item unavailable")
          : added
            ? t("Remove from Basket")
            : t("Add to Basket")
      }
    >
      {loading ? (
        <LoadingDots />
      ) : added ? (
        <FaShoppingBasket fill="var(--primary)" />
      ) : (
        <FaCartPlus fill="var(--primary)" />
      )}
      {!itemId
        ? t("Unavailable")
        : added
          ? t("Remove from Basket")
          : t("Add to Basket")}
    </button>
  );
};

export default AddBasketButton;
