// LocalizedLink.tsx
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../context/language";

const LocalizedLink: React.FC<LinkProps> = ({ to, ...rest }) => {
  const { language } = useContext(LanguageContext);

  let localizedTo = to;

  if (typeof to === "string") {
    // If the path doesn't start with "/en" or "/he", prepend the current language.
    if (!to.startsWith("/en") && !to.startsWith("/he")) {
      localizedTo = `/${language}${to.startsWith("/") ? "" : "/"}${to}`;
    }
  } else if (typeof to === "object" && to.pathname) {
    if (!to.pathname.startsWith("/en") && !to.pathname.startsWith("/he")) {
      localizedTo = {
        ...to,
        pathname: `/${language}${to.pathname.startsWith("/") ? "" : "/"}${to.pathname}`,
      };
    }
  }

  return (
    <Link
      to={localizedTo}
      {...rest}
    />
  );
};

export default LocalizedLink;
