import dayjs from "dayjs";
import "dayjs/locale/en"; // Default locale
import "dayjs/locale/he"; // Arabic locale
import advancedFormat from "dayjs/plugin/advancedFormat"; // For using abbreviated month format
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
import Api from "../../api/api";
import {
  default as avatar,
  default as deletedAvatar,
} from "../../assets/avatar.jpg";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { useAuth } from "../../context/auth";

// Extend dayjs with the necessary plugins
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

interface Message {
  _id: string;
  text: string;
  sender: { _id: string; fname: string; lname: string; avatar?: string };
  receiver: { _id: string; fname: string; lname: string };
  createdAt: string;
  itemId?: string;
  auctionId?: string;
  itemData?: any;
  auctionData?: any;
}

interface Conversation {
  _id: string;
  participants: {
    _id: string;
    fname: string;
    lname: string;
    avatar?: string;
  }[];
  lastMessage: string;
  updatedAt: string;
  messages: Message[];
}

const MessagesPage: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user: isUser } = useAuth();
  const { t, i18n } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const maxHeight = 140;
  const [localeKey, setLocaleKey] = useState(0);
  // Determine if the selected conversation is with a deleted user
  const isDeletedUser = selectedConversation?.participants.length === 1;
  const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile ? true : false);
  // Function to adjust the height of the textarea dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // Reset the height to auto so it can shrink or grow
      textareaRef.current.style.height = "auto";

      // Calculate the scrollHeight
      const scrollHeight = textareaRef.current.scrollHeight;

      // Set the height based on the scrollHeight but limit it to the maxHeight
      if (scrollHeight > maxHeight) {
        textareaRef.current.style.height = `${maxHeight}px`; // Limit height
        textareaRef.current.style.overflowY = "auto"; // Enable scroll when max height is reached
      } else {
        textareaRef.current.style.height = `${scrollHeight}px`; // Dynamic height
        textareaRef.current.style.overflowY = "hidden"; // Keep overflow hidden until maxHeight is reached
      }
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // Adjust textarea height when newMessage changes
  }, [newMessage]);

  useEffect(() => {
    // Update Day.js locale
    dayjs.locale(i18n.language || "en");
    // Force a component remount
    setLocaleKey(prevKey => prevKey + 1);
  }, [i18n.language]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight; // Scrolls only the messages container
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedConversation, conversations]);
  useEffect(() => {
    if (!isLoading && selectedConversation) {
      // Small delay ensures DOM is updated before scrolling
      setTimeout(scrollToBottom, 0);
    }
  }, [isLoading, selectedConversation, conversations]);
  const fetchAllConversationsAndMessages = async () => {
    setIsLoading(true);
    try {
      const res = await Api.getAllMessages();
      if (res.status === 200 && res.data.data.conversations) {
        const sortedConversations = [...res.data.data.conversations].sort(
          (a, b) => {
            return (
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
            );
          },
        );

        setConversations(sortedConversations);
        if (!selectedConversation && sortedConversations.length > 0) {
          const firstConversation = sortedConversations[0];
          setSelectedConversation(sortedConversations[0]);
          try {
            await Api.markConversationAsRead(firstConversation._id);
          } catch (error) {
            console.error(
              "Error marking the first conversation as read:",
              error,
            );
          }
        }
      } else {
        setConversations([]);
      }
    } catch (error) {
      console.error("Error fetching conversations and messages:", error);
      setConversations([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllConversationsAndMessages();
  }, []);

  const isToday = (dateString: string) => {
    return dayjs(dateString).isSame(dayjs(), "day");
  };

  // Function to check if the date is from the same year
  const isThisYear = (dateString: string) => {
    return dayjs(dateString).isSame(dayjs(), "year");
  };

  // Function to format the date or time
  // Function to format the date or time
  const formatDateOrTime = (dateString: string) => {
    const date = dayjs(dateString);
    const currentDate = dayjs();

    // If it's today, show the time (24-hour format)
    if (date.isSame(currentDate, "day")) {
      return date.format("HH:mm");
    }

    // If it's within this year, show abbreviated month and day (e.g., Oct 17)
    if (date.isSame(currentDate, "year")) {
      return date.format("MMM D");
    }

    // Otherwise, calculate the difference in years
    const yearsDifference = currentDate.diff(date, "year");

    // If the diff is 0 (or negative, in edge cases), fall back to a full date
    if (yearsDifference <= 0) {
      return date.format("MMM D, YYYY");
    }

    // Handle singular vs. plural years
    return yearsDifference === 1 ? "1y" : `${yearsDifference}y`;
  };

  const handleDeleteMessage = async (messageId: string) => {
    try {
      const res = await Api.deleteMessage(messageId);
      if (res.status === 200) {
        setConversations(prevConversations =>
          prevConversations.map(conv => ({
            ...conv,
            messages: conv.messages.filter(
              message => message._id !== messageId,
            ),
          })),
        );
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedConversation) {
      const receiver = selectedConversation.participants.find(
        participant => participant._id !== isUser?._id,
      );

      if (!receiver) {
        console.error("Receiver not found in the conversation");
        return;
      }

      // Optimistic message
      const sentMessage: Message = {
        _id: Date.now().toString(), // Temporary ID
        text: newMessage,
        sender: {
          _id: isUser?._id || "unknown",
          fname: isUser?.fname || "Unknown",
          lname: isUser?.lname || "Unknown",
          avatar: isUser?.avatar || avatar, // Use the correct avatar
        },
        receiver: {
          _id: receiver._id,
          fname: receiver.fname,
          lname: receiver.lname,
        },
        createdAt: new Date().toISOString(),
      };

      // Optimistically update the selectedConversation and conversations
      setSelectedConversation(prev => {
        if (prev) {
          return {
            ...prev,
            messages: [...prev.messages, sentMessage],
          };
        }
        return prev;
      });

      setConversations(prevConversations =>
        prevConversations.map(conv => {
          if (conv._id === selectedConversation._id) {
            return {
              ...conv,
              messages: [...conv.messages, sentMessage],
              lastMessage: sentMessage.text,
              updatedAt: sentMessage.createdAt,
            };
          }
          return conv;
        }),
      );

      setNewMessage(""); // Clear the message input
      scrollToBottom(); // Scroll to the bottom of the messages container

      try {
        // Send the message to the server
        const res = await Api.sendMessage({
          receiverId: receiver._id,
          text: newMessage,
        });

        if (res.status === 200 || res.status === 201) {
          const serverMessage: Message = res.data.data;

          // Replace the optimistic message with the server response
          setSelectedConversation(prev => {
            if (prev) {
              return {
                ...prev,
                messages: prev.messages.map(msg =>
                  msg._id === sentMessage._id ? serverMessage : msg,
                ),
              };
            }
            return prev;
          });

          setConversations(prevConversations =>
            prevConversations.map(conv => {
              if (conv._id === selectedConversation._id) {
                return {
                  ...conv,
                  messages: conv.messages.map(msg =>
                    msg._id === sentMessage._id ? serverMessage : msg,
                  ),
                  lastMessage: serverMessage.text,
                  updatedAt: serverMessage.createdAt,
                };
              }
              return conv;
            }),
          );
        }
      } catch (error) {
        console.error("Error sending message:", error);

        // Optionally revert optimistic update if the message fails
      }
    }
  };

  const handleConversationClick = async (conversation: Conversation) => {
    setSelectedConversation(conversation);

    scrollToBottom();
    setIsSidebarOpen(false);
    try {
      const res = await Api.markConversationAsRead(conversation._id);

      if (res.status === 200) {
        // Optionally, update the local state to reflect that the conversation is read
      }
    } catch (error) {
      console.error("Error marking conversation as read:", error);
    }
  };

  // Helper functions to determine avatar and alt text
  const isDeletedParticipant = (participant: any) => {
    return (
      participant &&
      participant.fname === "Deleted" &&
      participant.lname === "User"
    );
  };

  const getParticipantAvatar = (participant: any) => {
    if (!participant) return deletedAvatar; // No participant, use deleted avatar
    return isDeletedParticipant(participant)
      ? deletedAvatar
      : participant.avatar || avatar;
  };

  const getParticipantAlt = (participant: any) => {
    if (!participant) return t("Deleted User");
    return isDeletedParticipant(participant)
      ? t("Deleted User")
      : `${participant.fname} ${participant.lname}`;
  };
  const isRtl = i18n.language === "he";
  // Conditional rendering based on isLoading
  if (isLoading) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center min-h-screen">
          <FaSpinner
            className="animate-spin text-4xl text-primary"
            aria-label={t("Loading")}
            role="status"
          />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <div key={localeKey}>
        <Header />
        {isMobile && (
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="m-2 px-4 py-2 bg-blue-500 text-white rounded-md md:hidden "
          >
            {isSidebarOpen ? t("Messages") : t("Conversations")}
          </button>
        )}
        <div className="min-h-screen flex bg-gray-100">
          {/* Conversations Sidebar */}
          <div
            className={`border-gray-200 bg-white shadow-md
          ${
            isMobile
              ? `
            absolute top-[13.5%] bottom-0 transition-transform duration-300 ease-in-out
            ${
              isRtl
                ? isSidebarOpen
                  ? "translate-x-0 right-0"
                  : "translate-x-full right-0"
                : isSidebarOpen
                  ? "translate-x-0"
                  : "-translate-x-full"
            }
            w-[100vw]
          `
              : `
            static w-1/3
          `
          }
        `}
            style={{ zIndex: 15 }}
          >
            {" "}
            <div className="p-1">
              <h2 className="md:text-sm max-md:text-md max-md:mb-[-0.2rem] max-md:mt-[-0.1rem] max-md:mr-2 max-md:ml-2md:rtl:mr-[-0.2rem] md:ltr:ml-[-0.2rem] lg:p-4 md:text-lg font-semibold mb-4">
                {t("Conversations")}
              </h2>
              <div
                className="overflow-y-auto"
                style={{
                  maxHeight: "calc(100vh - 150px)", // Adjust height as needed based on your layout
                }}
              >
                {conversations.length > 0 ? (
                  conversations.map(conversation => {
                    const otherParticipant = conversation.participants.find(
                      participant => participant._id !== isUser?._id,
                    );

                    return (
                      <div
                        key={conversation._id}
                        className={`p-4 mb-2 sm:p-3 md:p-4 relative cursor-pointer border-b border-gray-200 ${
                          selectedConversation?._id === conversation._id
                            ? "bg-blue-100"
                            : "bg-white"
                        } hover:bg-blue-50`}
                        onClick={() => handleConversationClick(conversation)}
                      >
                        <div className="flex items-center sm:gap-1 md:gap-3">
                          {/* Avatar */}
                          <img
                            src={getParticipantAvatar(otherParticipant)}
                            alt={getParticipantAlt(otherParticipant)}
                            className="sm:w-7 sm:h-7 ltr:ml-[-0.6rem] rtl:mr-[-0.6rem] md:w-8 md:h-8 rounded-full object-cover"
                          />

                          {/* Name */}
                          <p className="font-bold ltr:ml-[-0.2rem] ltr:mr-[-0.5rem] rtl:mr-[-0.2rem] rtl:ml-[-0.5rem] sm:text-xs md:text-lg text-gray-800 line-clamp-2">
                            {getParticipantAlt(otherParticipant)}
                          </p>

                          {/* Timestamp */}
                          <p className="text-sm text-gray-500 max-md:text-xs absolute bottom-1 ltr:right-1 rtl:left-1 truncate">
                            {formatDateOrTime(conversation.updatedAt)}
                          </p>
                        </div>

                        {/* Last Message */}
                        <p className="text-sm text-gray-500 truncate">
                          {conversation.lastMessage}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className="p-4 text-center text-gray-500">
                    {t("No conversations found.")}.
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Messages Area */}
          <div
            className={`flex-1 p-4 sm:p-1 md:pr-4 md:pl-4 md:pb-0 md:pt-4 
          ${!isMobile ? "w-2/3" : ""}
          ${isMobile && isSidebarOpen ? "hidden" : "block"}
        `}
          >
            {" "}
            {selectedConversation ? (
              <>
                <div
                  ref={messagesEndRef}
                  className="flex-1 overflow-y-auto mb-4 max-md:max-h-[65vh] max-md:min-h-[65vh] max-h-[80vh]"
                >
                  {selectedConversation.messages.length > 0 ? (
                    selectedConversation.messages.map(message => {
                      // Identify which ID to use
                      const contentId = message.itemId || message.auctionId;

                      // Retrieve from message's own data
                      const contentData = contentId
                        ? message.itemData || message.auctionData
                        : null;

                      return (
                        <div
                          key={message._id}
                          className={`p-3 mb-3 sm:p-1 md:p-2 max-w-lg relative ${
                            message.sender._id === isUser?._id
                              ? "bg-blue-500 text-white self-end"
                              : "bg-gray-200 self-start"
                          } rounded-lg`}
                        >
                          {/* Standard message content (e.g., sender avatar, text, etc.) */}
                          <div className="flex items-center gap-2">
                            <img
                              src={getParticipantAvatar(message.sender)}
                              alt={getParticipantAlt(message.sender)}
                              className="w-8 h-8 rounded-full"
                            />
                            <p
                              dir="auto"
                              className="sm:text-sm md:text-base"
                              style={{
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                                maxWidth: isMobile ? "80%" : "90%",
                              }}
                            >
                              {message.text}
                            </p>
                          </div>

                          {/* Conditionally render item or auction details */}
                          {contentId && contentData && (
                            <div
                              dir="auto"
                              className="bg-gray-100 p-3 mt-3 flex rounded-lg"
                            >
                              <img
                                src={
                                  contentData.photos?.[0]?.url ||
                                  "https://via.placeholder.com/150"
                                }
                                alt={contentData.name || contentData.title}
                                className="w-16 h-16 object-cover rounded"
                              />

                              {/* Text, aligned in the middle of the image */}
                              <div className="ml-2 mr-2">
                                <p className="whitespace-pre-wrap break-words font-semibold leading-snug">
                                  {contentData.name || contentData.title}
                                </p>
                              </div>
                            </div>
                          )}

                          <p className="text-xs mt-1 text-gray-1000">
                            {dayjs(message.createdAt).format(
                              "MMM D, YYYY HH:mm", // Updated to 24-hour format
                            )}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center text-gray-500">
                      {t("No messages in this conversation")}.
                    </p>
                  )}
                </div>
                {!isDeletedUser && (
                  <div className="p-1 border-t max-w-[80%] border-gray-300">
                    <div
                      dir="auto"
                      className="flex items-end"
                    >
                      <textarea
                        ref={textareaRef}
                        value={newMessage}
                        onChange={e => setNewMessage(e.target.value)}
                        className="flex-1 sm:max-w-[70%] lg:max-w-[40%] border border-gray-300 p-2 ltr:rounded-l-lg rtl:rounded-r-lg resize-none overflow-hidden"
                        placeholder={t("Type a message") + "..."}
                        rows={1}
                        style={{
                          maxHeight: isMobile ? "100px" : "140px",
                          minHeight: "40px",
                        }}
                      />
                      <button
                        onClick={handleSendMessage}
                        className="bg-blue-500 text-white px-4 ltr:ml-2 rtl:mr-2 rounded-lg h-[40px]"
                      >
                        {t("Send")}
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <p className="text-center text-gray-500">
                {t("Select a conversation to view messages.")}.
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MessagesPage;
