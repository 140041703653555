import React, { createContext, useContext, useState } from "react";

interface FullScreenContextProps {
  isFullScreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FullScreenContext = createContext<FullScreenContextProps | undefined>(
  undefined,
);

export const useFullScreen = () => {
  const context = useContext(FullScreenContext);
  if (!context) {
    throw new Error("useFullScreen must be used within a FullScreenProvider.");
  }
  return context;
};

export const FullScreenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <FullScreenContext.Provider value={{ isFullScreen, setIsFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  );
};
