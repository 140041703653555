import React, { useState } from "react";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";
import Modal from "react-modal";
import toast from "react-hot-toast";
import Api from "../../api/api";
import { isMobile } from "react-device-detect";

interface SendSummaryActionsProps {
  profileId: string;
  profileName: string;
}

const SendSummaryActions: React.FC<SendSummaryActionsProps> = ({
  profileId,
  profileName,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summaryPDF, setSummaryPDF] = useState<Blob | null>(null);
  const [sendingSummary, setSendingSummary] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const downloadSummaryPDF = () => {
    if (summaryPDF) {
      const url = URL.createObjectURL(summaryPDF);
      const link = document.createElement("a");
      link.href = url;
      link.download = "summary.pdf";
      link.click();
      URL.revokeObjectURL(url);
      toast.success(t("Summary downloaded successfully!"));
      closeModal();
    } else {
      toast.error(t("No summary available to download."));
    }
  };

  async function sendSummaryViaEmail() {
    setSendingSummary(true);
    try {
      const response = await Api.sendSummaryForBasketToBuyerInEmail(profileId);
      if (response.status === 200) {
        toast.success(t("Summary sent via email successfully!"));
      } else {
        toast.error(t("Failed to send email."));
      }
    } catch (error) {
      console.error("Error sending summary email:", error);
      toast.error(t("Unable to send summary via email."));
    } finally {
      setSendingSummary(false);
      closeModal();
    }
  }

  async function confirmSendSummary() {
    setSendingSummary(true);
    try {
      const response = await Api.sendSummaryForBasketToBuyer(profileId);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        setSummaryPDF(blob);
        if (!isMobile) {
          // Desktop: open modal with options
          setIsModalOpen(true);
        } else {
          // Mobile: attempt to share via Web Share API
          const canShareFile =
            navigator.canShare &&
            navigator.canShare({
              files: [
                new File([blob], "summary.pdf", { type: "application/pdf" }),
              ],
            });
          if (canShareFile) {
            try {
              const file = new File([blob], "summary.pdf", {
                type: "application/pdf",
              });
              await navigator.share({
                title: "Summary",
                text: `${t("Summary of items for")} ${profileName}`,
                files: [file],
              });
              toast.success(t("Summary shared successfully!"));
            } catch (error: any) {
              if (error.name === "NotAllowedError") {
                setIsModalOpen(true);
              } else {
                console.error("Error sharing summary:", error);
                toast.error(t("Unable to share summary."));
              }
            }
          } else {
            setIsModalOpen(true);
          }
        }
      } else {
        toast.error(t("Failed to generate summary."));
      }
    } catch (error: any) {
      console.error("Error fetching summary PDF:", error);
      toast.error(t(error.message || "Failed to generate summary."));
    } finally {
      setSendingSummary(false);
    }
  }

  return (
    <>
      <Button
        onClick={confirmSendSummary}
        disabled={sendingSummary}
        className="!bg-orange-500 !hover:bg-orange-600 active:bg-orange-700 !text-white !px-3 !py-2 !text-sm flex items-center gap-2 !whitespace-nowrap"
      >
        <FaEnvelope className="inline-block" />
        {t("Send summary")}
      </Button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Send Summary Confirmation"
        className="max-w-md mx-auto mt-24 p-6 bg-white rounded-lg shadow-xl outline-none"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50"
      >
        <h2 className="text-xl font-bold mb-4 text-gray-800">
          {t("Confirm Send Summary")}
        </h2>
        <p className="mb-6 text-gray-600">
          {t("Are you sure you want to send a summary to")}{" "}
          <strong className="text-gray-800">{profileName}</strong>?
        </p>
        <div className="flex justify-between gap-4">
          <Button
            onClick={closeModal}
            className="flex-1 bg-red-500 hover:bg-red-600 text-white font-medium py-2 rounded-lg transition-all"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={sendSummaryViaEmail}
            disabled={sendingSummary}
            className={`flex-1 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 rounded-lg transition-all ${
              sendingSummary ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {t("Send via Email")}
          </Button>
          <Button
            onClick={downloadSummaryPDF}
            disabled={!summaryPDF}
            className={`flex-1 bg-green-500 hover:bg-green-600 text-white font-medium py-2 rounded-lg transition-all ${
              !summaryPDF ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {t("Download PDF")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SendSummaryActions;
