// src/components/HomeHelmet.tsx

import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

interface HomeHelmetProps {
  // You can pass additional props if needed, such as dynamic content
}

const HomeHelmet: React.FC<HomeHelmetProps> = () => {
  const { i18n } = useTranslation();
  const language = i18n.language; // "he" for Hebrew, "en" for English
  const isHebrew = language === "he";

  // Helper functions to retrieve localized strings
  const getTitle = () => {
    return isHebrew
      ? "נומיסנסט - בית למכרזים, מטבעות, שטרות ואוספים בישראל | מכירות פומביות אונליין החל מ-1 ש״ח"
      : "Numisnest - Home for Auctions, Coins, Banknotes & Collectibles in Israel | Online Auctions Starting from 1 ILS";
  };

  const getDescription = () => {
    return isHebrew
      ? "ברוכים הבאים לנומיסנסט - האתר המוביל בישראל למכרזים, מטבעות, שטרות ואספנות. קנו ומכרו פריטים נדירים דרך מכירות פומביות אונליין החל מ-1 ש״ח!"
      : "Welcome to Numisnest - Israel's premier platform for auctions, coins, banknotes & collectibles. Buy and sell rare items through online auctions starting from 1 ILS!";
  };

  const getKeywords = () => {
    return isHebrew
      ? "נומיסנסט, מכירות פומביות, מכירות אונליין, מכרזים בישראל, מטבעות, שטרות, אספנות, אוספים, מטבעות נדירים, שטרות נדירים"
      : "Numisnest, auctions, online auctions, auctions in Israel, coins, banknotes, collectibles, rare coins, rare banknotes";
  };

  const getAuthor = () => {
    return isHebrew
      ? "נומיסנסט - בית למכרזים, מטבעות, שטרות ואוספים בישראל"
      : "Numisnest - Home for Auctions, Coins, Banknotes & Collectibles in Israel";
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: isHebrew ? "he" : "en",
      }}
    >
      {/* =====================================
           Dynamic Page Title
      ===================================== */}
      <title>{getTitle()}</title>

      {/* =====================================
           Meta Description
      ===================================== */}
      <meta
        name="description"
        content={getDescription()}
      />

      {/* =====================================
           Open Graph (OG) Tags for Social Sharing
      ===================================== */}
      <meta
        property="og:title"
        content={getTitle()}
      />
      <meta
        property="og:description"
        content={getDescription()}
      />
      <meta
        property="og:image"
        content={
          isHebrew
            ? "https://numisnest.com/logo2.png" // Hebrew-specific image
            : "https://numisnest.com/logo2.png" // English-specific image
        }
      />
      <meta
        property="og:url"
        content={window.location.href}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:locale"
        content={isHebrew ? "he_IL" : "en_US"}
      />
      <meta
        property="og:locale:alternate"
        content={isHebrew ? "en_US" : "he_IL"}
      />

      {/* =====================================
           Twitter Card Tags
      ===================================== */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content={getTitle()}
      />
      <meta
        name="twitter:description"
        content={getDescription()}
      />
      <meta
        name="twitter:image"
        content={
          isHebrew
            ? "https://numisnest.com/logo2.png" // Hebrew-specific image
            : "https://numisnest.com/logo2.png" // English-specific image
        }
      />

      {/* =====================================
           Additional SEO Enhancements
      ===================================== */}
      <meta
        name="keywords"
        content={getKeywords()}
      />
      <meta
        name="author"
        content={getAuthor()}
      />
      <link
        rel="canonical"
        href={window.location.href}
      />

      {/* =====================================
           Robots Meta Tag
      ===================================== */}
      <meta
        name="robots"
        content="index, follow"
      />

      {/* =====================================
           Hreflang Tags for Multilingual SEO
      ===================================== */}

      {/* Fallback for all languages */}
      <link
        rel="alternate"
        hrefLang="x-default"
        href="https://numisnest.com"
      />

      {/* =====================================
           Structured Data (JSON-LD) for SEO
      ===================================== */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: isHebrew ? "נומיסנסט" : "Numisnest",
          url: "https://numisnest.com",
          potentialAction: {
            "@type": "SearchAction",
            target: "https://numisnest.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string",
          },
          description: getDescription(),
          inLanguage: isHebrew ? "he-IL" : "en-US",
          publisher: {
            "@type": "Organization",
            name: isHebrew ? "נומיסנסט" : "Numisnest",
            logo: {
              "@type": "ImageObject",
              url: "https://numisnest.com/assets/logo.png", // Replace with actual logo URL
            },
          },
          image: isHebrew
            ? "https://numisnest.com/logo2.png" // Hebrew-specific image
            : "https://numisnest.com/logo2.png", // English-specific image
          breadcrumb: {
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: isHebrew ? "עמוד הבית" : "Home",
                item: "https://numisnest.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: isHebrew ? "כל המכרזים" : "All Auctions",
                item: "https://numisnest.com/auctions",
              },
            ],
          },
        })}
      </script>
    </Helmet>
  );
};

export default HomeHelmet;
