// src/pages/AllBasketsPage.tsx
import React, { useEffect, useState } from "react";
import SellerGroupTiles, {
  ISellerGroup,
  IGenericItem,
  ISeller,
} from "../components/SellerGroupTiles";
import { useAuth } from "../context/auth";
import { useTranslation } from "react-i18next";
import Api from "../api/api";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

interface IBasketItem extends IGenericItem {
  price?: number;
  currency?: string;
  paid?: boolean;
  deletedBySeller?: boolean;
}

interface IBasket {
  _id: string;
  seller: ISeller;
  buyer: ISeller;
  items: IBasketItem[];
  sentAt?: Date | null;
}

const AllBasketsPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, authLoading } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [basketsEmpty, setBasketsEmpty] = useState(false);
  const [sellerGroups, setSellerGroups] = useState<ISellerGroup<IBasketItem>[]>(
    [],
  );

  useEffect(() => {
    if (!authLoading && user) {
      fetchAllBaskets();
    }
  }, [authLoading, user]);

  async function fetchAllBaskets() {
    setLoading(true);
    setError(null);
    setBasketsEmpty(false);

    try {
      const response = await Api.getAllBasketsForUser();
      const data: IBasket[] = response?.data?.data;

      if (!data || data.length === 0) {
        setBasketsEmpty(true);
        return;
      }

      const grouped: Record<string, { seller: ISeller; items: IBasketItem[] }> =
        {};
      data.forEach(basket => {
        const { seller, items } = basket;
        if (!seller || !seller._id) return;
        // Filter items: include only unpaid items that were not deleted by the seller.
        const validItems = items.filter(
          item => !item.paid && !item.deletedBySeller,
        );
        if (validItems.length === 0) return;
        if (!grouped[seller._id]) {
          grouped[seller._id] = { seller, items: [] };
        }
        grouped[seller._id].items.push(...validItems);
      });

      const groups = Object.values(grouped);
      groups.length === 0 ? setBasketsEmpty(true) : setSellerGroups(groups);
    } catch (err: any) {
      setError(err.message || "Error loading baskets");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header />
      <main className="max-w-[1260px] mx-auto p-4">
        {authLoading ? (
          <div className="flex justify-center items-center h-screen">
            <FaSpinner className="animate-spin text-3xl text-primary" />
          </div>
        ) : !user ? (
          <div className="flex justify-center items-center h-screen">
            <p className="text-xl text-gray-600">
              {t("User not authenticated")}
            </p>
          </div>
        ) : loading ? (
          <div className="flex justify-center items-center h-screen">
            <FaSpinner className="animate-spin text-3xl text-primary" />
          </div>
        ) : error ? (
          <div className="p-4 text-center text-red-500">{error}</div>
        ) : basketsEmpty ? (
          <div className="p-4 text-center">{t("No baskets found")}</div>
        ) : (
          <>
            <h1 className="text-2xl font-bold mb-4">{t("My baskets")}</h1>
            <SellerGroupTiles
              sellerGroups={sellerGroups}
              onComponentClick={() => {}}
              onViewClick={(sellerId: string) =>
                history.push(`/basket/${sellerId}`)
              }
              getViewLink={seller => `/basket/${seller._id}`}
            />
          </>
        )}
      </main>
      <Footer />
    </>
  );
};

export default AllBasketsPage;
